//TextField
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const styles = (theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: 500,
  },
  input: {
    //padding: '5px 14px !important',
    fontSize: "16px",
    fontWeight: 500,
    backgroundColor: "#f4f2f26b !important",
    borderRadius: 5,
    marginBottom: 0,
  },
  labelColor: {
    // color: '#b6b5b5'
    color: "gray",
  },
});

class MyDateTimePicker extends Component {
  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          fullWidth
          label={this.props.label}
          name={this.props.name}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          value={this.props.value}
          variant="inline"
          size="small"
          inputVariant="outlined"
          format={"dd MMM yyyy h:mm a"}
          error={this.props.error}
          helperText={this.props.helperText}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(MyDateTimePicker);
