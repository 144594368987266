import React, { useState, useEffect, useRef } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { history } from "../../../../../../../history";
import { styles } from "../../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../../components/analytics/elearning/ReportTableWrapper";
import { newsUpdateServices } from "../../../../../../News/NewsUpdate/modules/services";
import axios from "axios";
import { analyticsNewsReportsServices } from "../../../../modules/services";
import { downloadCsvData } from "../../../../../../../utils/csvDownloadUtils";

const LocationRoleBasedUsersReport = ({ classes }) => {
  const { lid, rid } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get("startDate");
  const endDate = queryParams.get("endDate");
  const location_name = queryParams.get("location_name");
  const role_name = queryParams.get("role_name");

  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("user_name");

  const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  useEffect(() => {
    setUsersData([]);
    fetchUsersData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchUsersData = (sortBy = orderBy, sortOrder = order) => {
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      lid,
      rid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    analyticsNewsReportsServices
      .fetchNewsUserReports(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success) {
          setUsersData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleDownloadClick = () => {
    const requestData = {
      page,
      limit: 10,
      lid,
      rid,
      ...(startDate && endDate ? { startDate, endDate } : {}),
      sort: order === "asc" ? orderBy : `-${orderBy}`,
    };

    const headers = [
      // { label: "User ID", key: "_id" },
      { label: "User Name", key: "user_name" },
      { label: "Total News", key: "total_news" },
      {
        label: "News Seen",
        key: checked ? "news_seen_percentage" : "news_seen/total_news",
      },
      {
        label: "News Ack",
        key: checked ? "news_ack_percentage" : "news_ack/total_news",
      },
    ];

    downloadCsvData({
      apiService: analyticsNewsReportsServices.newsUsersReportDownload,
      requestData,
      headers,
      filename: "User_Report.csv",
      setLoading,
    });
  };

  const handleSortRequest = (property) => {
    setUsersData([]);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchUsersData(property, isAsc ? "desc" : "asc");
  };

  const handleRowClick = (row) => {
    const newQueryParams = new URLSearchParams(location.search);
    if (startDate) {
      newQueryParams.set("startDate", startDate);
    }
    if (endDate) {
      newQueryParams.set("endDate", endDate);
    }

    newQueryParams.set("location_name", location_name);
    newQueryParams.set("role_name", role_name);
    newQueryParams.set("user_name", row.user_name);

    console.log("Analytics location id:", row);

    history.push({
      pathname: `/analytics/news/location/${lid}/roles/${rid}/${row._id}`,
      search: newQueryParams.toString(),
    });
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const headers = [
    { id: "user_name", label: "Username" },
    { id: "total_news", label: "News" },
    { id: checked ? "news_seen_percentage" : "news_seen", label: "Seen" },
    { id: checked ? "news_ack_percentage" : "news_ack", label: "Acknowledged" },
  ];

  const rows = usersData.map((row) => ({
    _id: row._id,
    user_name: row.user_name,
    Username: row?.user_name || "N/A",
    News: row?.total_news,
    Seen: checked
      ? `${row.news_seen_percentage}%`
      : `${row.news_seen} / ${row.total_news}`,
    Acknowledged: checked
      ? `${row.news_ack_percentage}%`
      : `${row.news_ack} / ${row.total_news}`,
    ...row,
  }));

  const arrowColumnCalculations = (row, columnId) => {
    let difference;

    switch (columnId) {
      case "Seen":
        difference = checked
          ? row.news_seen_percentage_difference
          : row.news_seen_difference;
        break;
      case "Acknowledged":
        difference = checked
          ? row.news_ack_percentage_difference
          : row.news_ack_difference;
        break;
      default:
        return null;
    }

    return difference !== 0 ? difference > 0 : null;
  };

  const breadcrumb_items = [
    {
      name: location_name,
      link: `/analytics/news/location/${lid}/roles?location_name=${location_name}${
        startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ""
      }`,
    },
    {
      name: role_name,
      link: ``,
    },
  ];

  return (
    <>
      <Grid className={classes.main}>
        <ReportTableWrapper
          classes={classes}
          data={rows}
          headers={headers}
          loading={loading}
          page={page}
          totalPages={totalPages}
          onPageChange={setPage}
          onRowClick={handleRowClick}
          handleDownloadClick={handleDownloadClick}
          onSortRequest={handleSortRequest}
          order={order}
          orderBy={orderBy}
          handleCountPercentage={handleCountPercentage}
          checked={checked}
          breadcrumbItems={breadcrumb_items}
          arrowColumnCalculations={arrowColumnCalculations}
        />
      </Grid>
    </>
  );
};

const ConnectWith = connect(null, null)(LocationRoleBasedUsersReport);

export default withStyles(styles)(ConnectWith);
