//TextField
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextareaAutosize } from "@mui/base";
import { TextField } from "@material-ui/core";

const styles = (theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: 500,
  },
  input: {
    //padding: '5px 14px !important',
    fontSize: "14px",
    fontWeight: 500,
    // backgroundColor: '#f4f2f26b !important',
    borderRadius: 5,
    marginBottom: 0,
  },
  labelColor: {
    // color: '#b6b5b5'
    color: "gray",
  },
  addroles: {
    width: "250%",
    marginTop: "4%",
    backgroundColor: "white",
  },
  add: {
    width: "100%",
    marginTop: "2%",
  },
});

class MyTextField extends Component {
  render() {
    const { classes, styledemo } = this.props;

    return (
      <div>
        {/* <Typography
                                        gutterBottom
                                        className={classes.label}
                                >
                                        {this.props.label}
                                </Typography> */}

        <TextareaAutosize
          multiline
          disabled={this.props.disabled ? true : false}
          minRows={this.props.rows}
          required={this.props.required ? this.props.required : false}
          label={this.props.label}
          name={this.props.name}
          onChange={this.props.onChange}
          onFocus={this.props.onFocus}
          value={this.props.value}
          type={this.props.type}
          error={this.props.error}
          helperText={this.props.helperText}
          placeholder={this.props.placeholder}
          variant="outlined"
          style={{
            width: "100%",
            borderRadius: this.props.borderRadius
              ? this.props.borderRadius
              : "10px",
            padding: "9px",
            borderColor: this.props.borderColor
              ? this.props.borderColor
              : "#00000066",
            fontSize: "14px",
          }}
          className={
            styledemo == 1
              ? classes.addroles
              : styledemo == 2
              ? classes.add
              : ""
          }
          inputlabelprops={{ classes: { root: classes.labelColor } }}
          inputProps={{
            // disableUnderline: true,
            className: classes.input,
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(MyTextField);
