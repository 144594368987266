import styled from "styled-components";
import { schedulerColorHrV5 } from "../../../../../config";

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 750px;
  aspect-ratio: 1.6/1;
  background: white;
  border: none;
  border-radius: 7px;
  //   overflow: auto;
  margin: 10px 0px 10px 0px;

  &:focus,
  &:active {
    outline: none;
  }

  @media (max-width: 768px) {
    aspect-ratio: 1.8/1;
    width: 90%;
  }
`;

export const StyledHeader = styled.div`
  background: ${schedulerColorHrV5.main};
  border-radius: 7px 7px 0 0;
  width: 100%;
  height: 70px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderHeading = styled.h3`
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 40px;
  gap: 15px;
  overflow: auto;
  height: 100%;
  min-height: 500px;

  @media (max-width: 600px) {
    padding: 40px 6px 20px 6px;
  }
`;

export const StyledScheduleInputContainer = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
