import { saveAs } from "file-saver";

export const downloadCsvData = async ({
  apiService,
  requestData,
  headers,
  filename,
  setLoading,
}) => {
  try {
    const response = await apiService(requestData);

    if (response.data.success) {
      const results = response.data.result;

      const headerRow = headers.map((header) => header.label).join(",") + "\n";

      const csvRows = results
        .map((row) =>
          headers
            .map((header) => {
              if (header.key.includes("/")) {
                const [numeratorKey, denominatorKey] = header.key.split("/");
                const numerator = row[numeratorKey] ?? 0;
                const denominator = row[denominatorKey] ?? 0;
                return `="${numerator} / ${denominator}"`;
              }
              return row[header.key] != null ? row[header.key] : "null";
            })
            .join(",")
        )
        .join("\n");

      const csvContent = headerRow + csvRows;

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      saveAs(blob, filename);
    }
  } catch (error) {
    console.error("Error downloading report:", error);
  }
};
