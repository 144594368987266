import React, { useState, useEffect, useRef } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { dashboardServices } from "../../../../../../eLearning/Dashboard/modules/services";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { history } from "../../../../../../../history";
import { styles } from "../../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../../components/analytics/elearning/ReportTableWrapper";
import axios from "axios";
import { analyticsElearningReportsServices } from "../../../../modules/services";
import { downloadCsvData } from "../../../../../../../utils/csvDownloadUtils";

const LessonRoleBasedLocationsReport = ({ classes }) => {
  const { lesson_id, rid } = useParams();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get("startDate");
  const endDate = queryParams.get("endDate");

  const role_name = queryParams.get("role_name");
  const lesson_name = queryParams.get("lesson_name");

  const [locationsData, setLocationsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("location_name");

  const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  useEffect(() => {
    fetchLocationsData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchLocationsData = (sortBy = orderBy, sortOrder = order) => {
    setLocationsData([]);
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      lesson_id,
      rid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    analyticsElearningReportsServices
      .fetchElearningLocationReports(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success) {
          setLocationsData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleDownloadClick = () => {
    const requestData = {
      page,
      limit: 10,
      lesson_id,
      rid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
      sort: order === "asc" ? orderBy : `-${orderBy}`,
    };

    const headers = [
      // { label: "Location ID", key: "_id" },
      { label: "Location Name", key: "location_name" },
      { label: "Users", key: "location_user_count" },
      {
        label: "In Lesson Quiz",
        key: checked
          ? "inLessonQuizPer"
          : "completed_question_count/question_count",
      },
      {
        label: "Manager Quiz",
        key: checked
          ? "managerQuizPer"
          : "completed_manager_quiz_count/total_manager_quiz_count",
      },
      {
        label: "Video Quiz",
        key: checked
          ? "videoQuizPer"
          : "completed_video_quiz_count/total_video_quiz_count",
      },
    ];

    downloadCsvData({
      apiService:
        analyticsElearningReportsServices.elearningLocationsReportDownload,
      requestData,
      headers,
      filename: "Location_Report.csv",
      setLoading,
    });
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchLocationsData(property, isAsc ? "desc" : "asc");
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const handleRowClick = (row) => {
    const newQueryParams = new URLSearchParams(location.search);
    if (startDate) {
      newQueryParams.set("startDate", startDate);
    }
    if (endDate) {
      newQueryParams.set("endDate", endDate);
    }

    newQueryParams.set("role_name", role_name);
    newQueryParams.set("lesson_name", lesson_name);
    newQueryParams.set("location_name", row.location_name);

    history.push({
      pathname: `/analytics/elearning/lesson/${lesson_id}/roles/${rid}/${row._id}`,
      search: newQueryParams.toString(),
    });
  };

  const headCells = [
    { id: "location_name", label: "Location Name" },
    { id: "location_user_count", label: "Users" },
    {
      id: checked ? "inLessonQuizPer" : "completed_question_count",
      label: "In Lesson Quiz",
    },
    {
      id: checked ? "managerQuizPer" : "completed_manager_quiz_count",
      label: "Manager Quiz",
    },
    {
      id: checked ? "videoQuizPer" : "completed_video_quiz_count",
      label: "Video Quiz",
    },
  ];

  const data = locationsData?.length
    ? locationsData?.map((row) => ({
        _id: row._id,
        location_name: row?.location_name,
        "Location Name":
          row?.location_name?.length > 30
            ? row?.location_name.substring(0, 30) + "..."
            : row?.location_name,
        Users: row?.location_user_count,
        "In Lesson Quiz": checked
          ? `${row?.inLessonQuizPer}%`
          : `${row?.completed_question_count} / ${row?.question_count}`,
        "Manager Quiz": checked
          ? `${row?.managerQuizPer}%`
          : `${row?.completed_manager_quiz_count} / ${row?.total_manager_quiz_count}`,
        "Video Quiz": checked
          ? `${row?.videoQuizPer}%`
          : `${row?.completed_video_quiz_count} / ${row?.total_video_quiz_count}`,
        ...row,
      }))
    : [];

  const arrowColumnCalculations = (row, columnId, checked) => {
    let difference;

    switch (columnId) {
      case "Users":
        difference = row.location_user_count_difference;
        break;
      case "In Lesson Quiz":
        difference = checked
          ? row.inLessonQuizPer_difference
          : row.completed_question_count_difference;
        break;
      case "Manager Quiz":
        difference = checked
          ? row.managerQuizPer_difference
          : row.completed_manager_quiz_count_difference;
        break;
      case "Video Quiz":
        difference = checked
          ? row.videoQuizPer_difference
          : row.completed_video_quiz_count_difference;
        break;
      default:
        return null;
    }

    return difference !== 0 ? difference > 0 : null;
  };

  const breadcrumb_items = [
    {
      name: lesson_name,
      link: `/analytics/elearning/lesson/${lesson_id}/roles?lesson_name=${lesson_name}${
        startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ""
      }`,
    },
    {
      name: role_name,
      link: "",
    },
  ];

  return (
    <>
      <Grid className={classes.main}>
        <ReportTableWrapper
          classes={classes}
          // title="Lesson and Role-Based Locations Report"
          data={data}
          headers={headCells}
          loading={loading}
          page={page}
          totalPages={totalPages}
          onPageChange={setPage}
          onRowClick={handleRowClick}
          onSortRequest={handleSortRequest}
          order={order}
          orderBy={orderBy}
          handleCountPercentage={handleCountPercentage}
          checked={checked}
          breadcrumbItems={breadcrumb_items}
          arrowColumnCalculations={arrowColumnCalculations}
          handleDownloadClick={handleDownloadClick}
        />
      </Grid>
    </>
  );
};

const ConnectWith = connect(null, null)(LessonRoleBasedLocationsReport);

export default withStyles(styles)(ConnectWith);
