// MyTextField.js

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import { ovHrV5Color } from "../config";

const styles = (theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: 500,
    color: "green", //
  },
  input: {
    fontSize: "16px",
    fontWeight: 500,
    borderRadius: 5,
    marginBottom: 0,
    backgroundColor: "white",
  },
  labelColor: {
    color: "gray",
  },
  textField: {
    cursor: "pointer",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: ovHrV5Color.main, // Change to your desired focus color
      },
    },
    "& .MuiFormLabel-root": {
      color: ovHrV5Color.main, // Change this to your desired label color
    },
  },
});

const MyTextField = React.memo((props) => {
  const { classes } = props;

  return (
    <div style={{ width:props.width || "100%" }}>
      <TextField
        disabled={props.disabled || false}
        required={props.required || false}
        label={props.label}
        name={props.name}
        onChange={props.onChange}
        onClick={props.onClick}
        onFocus={props.onFocus}
        value={props.value}
       
        type={props.type}
        className={props?.product === "HR" ? classes.textField : ""}
        error={props.error}
        helperText={props.helperText}
        placeholder={props.placeholder}
        variant="outlined"
        size={props.size === undefined ? "small" : props.size}
        fullWidth
        sx={{
          "& .MuiOutlinedInput-root": {
            height: "60px",
            "& input": {
              height: "100%",
              padding: "16px 14px",
            },
            "& fieldset": {
              borderColor: "#00000066",
            },
            "&:hover fieldset": {
              borderColor: "#00000066",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#007E63", 
            },
          },
        }}
        InputProps={{
          className: classes.input,
          endAdornment: props.image ? (
            <InputAdornment position="end" style={{ marginRight: "9px" }}>
              <img
                src={props.image}
                alt="Your Alt Text"
                style={{ width: "20px", height: "20px" }}
              />
            </InputAdornment>
          ) : (
            ""
          ),
        }}
        inputlabelprops={{ classes: { root: classes.label } }}
        style={props.style}
      />
    </div>
  );
});

export default withStyles(styles)(MyTextField);
