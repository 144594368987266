import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import "react-loading-skeleton/dist/skeleton.css";
import { TableSortLabel, styled } from "@material-ui/core";
import TableRecordNotFound from "../../TableRecordNotFound";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";

const useStyles = makeStyles((theme) => ({
  analyticsCustomTable: {
    marginTop: "10px",
    width: "100%",
    background: "white",
  },
  analyticsCustomTrHead: {
    borderBottom: "1px solid #00000033",
  },
  analyticsCustomTH: {
    fontSize: "14px",
    padding: "10px",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  analyticsCustomTD: {
    fontSize: "14px",
    color: "#000000CC",
    padding: "10px",
    borderBottom: "1px solid #00000033",
  },
  analyticsCustomSkeleton: {
    borderRadius: "0.5rem",
    height: "40px",
  },
  analyticsCustomHeadCell: {
    padding: "0.5rem 0rem",
    color: "#000000 !important",
    fontWeight: 700,
    textTransform: "capitalize",
    "& .MuiTableSortLabel-icon": {
      color: "black !important",
    },
  },
  analyticsCustomLastRow: {
    "& td": {
      borderBottom: "none",
    },
  },
  analyticsCustomTableBodyRow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const SortLabelWrapper = styled("div")(({ theme }) => ({
  "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
    {
      color: "black !important",
    },
  "& .MuiTableSortLabel-root .MuiTableSortLabel-icon": {
    color: "black !important",
  },
}));

const CustomTable = ({
  headers,
  data,
  loading,
  onRowClick,
  handleSortRequest,
  order,
  orderBy,
  arrowColumnCalculations,
}) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table
        className={classes.analyticsCustomTable}
        sx={{ background: "white" }}
      >
        <TableHead>
          <TableRow className={classes.analyticsCustomTrHead}>
            {headers.map((header, index) => (
              <TableCell
                key={header.id}
                className={classes.analyticsCustomTH}
                onClick={() => handleSortRequest(header.id)}
              >
                <SortLabelWrapper>
                  <TableSortLabel
                    className={`${classes.analyticsCustomHeadCell} ${classes.customSortLabel}`}
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : "asc"}
                  >
                    {header.label}
                  </TableSortLabel>
                </SortLabelWrapper>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, rowIndex) => {
            return (
              <TableRow
                hover
                key={rowIndex}
                onClick={onRowClick ? () => onRowClick(row) : null}
                className={`${
                  rowIndex === data.length - 1
                    ? classes.analyticsCustomLastRow
                    : ""
                } ${onRowClick ? classes.analyticsCustomTableBodyRow : ""}`}
              >
                {headers.map((header, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    className={classes.analyticsCustomTD}
                  >
                    {row[header.label] !== undefined ? (
                      <>
                        {row[header.label]}
                        {arrowColumnCalculations(row, header.label) !== null &&
                          (arrowColumnCalculations(row, header.label) ? (
                            <NorthIcon
                              sx={{
                                color: "#48742c",
                                fontSize: "15px",
                                stroke: "#48742c",
                                strokeWidth: 0.6,
                              }}
                            />
                          ) : (
                            <SouthIcon
                              sx={{
                                color: "#bb271a",
                                fontSize: "15px",
                                stroke: "#bb271a",
                                strokeWidth: 0.6,
                              }}
                            />
                          ))}
                      </>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
          {loading &&
            [...Array(5)].map((_, index) => (
              <TableRow
                key={index}
                className={index === 4 ? classes.analyticsCustomLastRow : ""}
              >
                {headers.map((_, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    className={classes.analyticsCustomTD}
                  >
                    <Skeleton
                      className={classes.analyticsCustomSkeleton}
                      sx={{ maxWidth: "140px" }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}

          {!loading && data && data.length === 0 ? (
            <TableRecordNotFound colSpan={8} label="No Data Found.." />
          ) : (
            ""
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CustomTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
};

CustomTable.defaultProps = {
  loading: false,
};

export default CustomTable;
