import React, { useState, useEffect, useRef } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { dashboardServices } from "../../../../../../eLearning/Dashboard/modules/services";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import DownIcon from "../../../../../../../assets/images/Down.png";
import { styles } from "../../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../../components/analytics/elearning/ReportTableWrapper";
import axios from "axios";
import { analyticsElearningReportsServices } from "../../../../modules/services";
import { downloadCsvData } from "../../../../../../../utils/csvDownloadUtils";

const RoleUserBasedLessonsReport = ({ classes }) => {
  const { rid, uid } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get("startDate");
  const endDate = queryParams.get("endDate");

  const role_name = queryParams.get("role_name");
  const user_name = queryParams.get("user_name");

  const [lessonsData, setLessonsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("lesson_name");

  const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  useEffect(() => {
    setLessonsData([]);
    fetchLessonsData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchLessonsData = (sortBy = orderBy, sortOrder = order) => {
    setLessonsData([]);
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      rid,
      uid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    analyticsElearningReportsServices
      .fetchElearningLessonReports(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success) {
          setLessonsData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleDownloadClick = () => {
    const requestData = {
      page,
      limit: 10,
      rid,
      uid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
      sort: order === "asc" ? orderBy : `-${orderBy}`,
    };

    const headers = [
      // { label: "Lesson ID", key: "_id" },
      { label: "Lesson Name", key: "lesson_name" },
      {
        label: "In Lesson Quiz",
        key: checked
          ? "inLessonQuizPer"
          : "completed_question_count/question_count",
      },
      { label: "Manager Quiz", key: "manager_quiz_status" },
      { label: "Video Quiz", key: "video_quiz_status" },
    ];

    downloadCsvData({
      apiService:
        analyticsElearningReportsServices.elearningLessonsReportDownload,
      requestData,
      headers,
      filename: "Lesson_Report.csv",
      setLoading,
    });
  };

  const handleSortRequest = (property) => {
    setLessonsData([]);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchLessonsData(property, isAsc ? "desc" : "asc");
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const headers = [
    {
      id: "lesson_name",
      label: "Lesson Name",
    },
    {
      id: checked ? "inLessonQuizPer" : "completed_question_count",
      label: "In Lesson Quiz",
    },
    {
      id: "manager_quiz_status",
      label: "Manager Quiz",
    },
    {
      id: "video_quiz_status",
      label: "Video Quiz",
    },
  ];

  const tableData = lessonsData?.length
    ? lessonsData?.map((row) => ({
        "Lesson Name":
          row?.lesson_name?.length > 35
            ? row?.lesson_name?.substring(0, 35) + "..."
            : row?.lesson_name,
        Users: (
          <>
            {row?.lesson_user_count}{" "}
            {row?.lesson_user_count > 0 && (
              <img
                src={DownIcon}
                alt="Down Arrow"
                className={classes.upDownIcon}
              />
            )}
          </>
        ),
        "In Lesson Quiz": checked
          ? `${row?.inLessonQuizPer}%`
          : `${row?.completed_question_count} / ${row?.question_count}`,
        "Manager Quiz": (
          <div
            className={`${
              row.manager_quiz_status === "Pass"
                ? classes.textSuccess
                : row.manager_quiz_status === "Reassigned"
                ? classes.textFail
                : ""
            }`}
          >
            {row?.manager_quiz_status}
          </div>
        ),
        "Video Quiz": (
          <div
            className={`${
              row.video_quiz_status === "Pass"
                ? classes.textSuccess
                : row.video_quiz_status === "Reassigned"
                ? classes.textFail
                : ""
            }`}
          >
            {row?.video_quiz_status}
          </div>
        ),
      }))
    : [];

  const arrowColumnCalculations = (row, columnId) => {
    let difference;

    switch (columnId) {
      case "In Lesson Quiz":
        difference = checked
          ? row.inLessonQuizPer_difference
          : row.completed_question_count_difference;
        break;
      // case "Manager Quiz":
      //   difference = checked
      //     ? row.managerQuizPer_difference
      //     : row.completed_manager_quiz_count_difference;
      //   break;
      // case "Video Quiz":
      //   difference = checked
      //     ? row.videoQuizPer_difference
      //     : row.completed_video_quiz_count_difference;
      //   break;
      default:
        return null;
    }

    return difference !== 0 ? difference > 0 : null;
  };

  const breadcrumb_items = [
    {
      name: role_name,
      link: `/analytics/elearning/role/${rid}/users?role_name=${role_name}${
        startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ""
      }`,
    },
    {
      name: user_name,
      link: "",
    },
  ];

  return (
    <>
      <Grid className={classes.main}>
        <ReportTableWrapper
          classes={classes}
          title=""
          data={tableData}
          headers={headers}
          loading={loading}
          page={page}
          totalPages={totalPages}
          onPageChange={setPage}
          onSortRequest={handleSortRequest}
          order={order}
          orderBy={orderBy}
          handleCountPercentage={handleCountPercentage}
          checked={checked}
          breadcrumbItems={breadcrumb_items}
          arrowColumnCalculations={arrowColumnCalculations}
          handleDownloadClick={handleDownloadClick}
        />
      </Grid>
    </>
  );
};

const ConnectWith = connect(null, null)(RoleUserBasedLessonsReport);

export default withStyles(styles)(ConnectWith);
