import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, Grid, CardContent } from "@material-ui/core";
import {
  MyDatePicker,
  MySelectField,
  MyTextfield,
} from "../../../../../components";
import { ovHrV5Color } from "../../../../../config";

const useStyles = makeStyles((theme) => ({
  hrUserDetailsLabel: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    fontFamily: "Roboto, sans-serif !important",
    color: `${ovHrV5Color.main} !important`,
  },

  root: {
    padding: "1rem !important",
  },

  hrUserDetailsCardText: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    fontFamily: "Roboto, sans-serif !important",
    color: "black !important",
    display: "inline",
    float: "left",
  },

  hrUserDetailsCardTextBold: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    fontFamily: "Roboto, sans-serif !important",
    color: "grey !important",
    display: "inline",
    float: "right",
  },

}))

const UserDetails = ({
  userDetails,
  rolesData,
  handleChange,
  handleChangeDate,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item sm={8}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>Name</Typography>
            <MyTextfield
              name="user_name"
              product={"HR"}
              onChange={handleChange}
              value={userDetails.user_name}
              type="text"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>
              Email
            </Typography>
            <MyTextfield
              name="user_email"
              product={"HR"}
              onChange={handleChange}
              value={userDetails.user_email}
              type="text"
              disabled={true}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>
              Roles
            </Typography>
            <MySelectField
              name="user_role"
              product={"HR"}
              onChange={handleChange}
              value={userDetails.user_role}
              placeholder="Please Select role"
            >
              {rolesData?.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.role_name}
                </option>
              ))}
            </MySelectField>
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>
              Status
            </Typography>
            <MySelectField
              name="user_status"
              product={"HR"}
              onChange={handleChange}
              value={userDetails.user_status}
              placeholder="Please Select role"
            >
              <option key={"2"} value={"2"}>
                Deactivate
              </option>
              <option key={"1"} value={"1"}>
                Activate
              </option>
            </MySelectField>
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>
              Hours per week
            </Typography>
            <MyTextfield
              name="hours_per_week"
              product={"HR"}
              onChange={handleChange}
              value={userDetails.hours_per_week}
              type="text"
            />
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>
              Location Pin
            </Typography>
            <MyTextfield
              name="location_pin"
              product={"HR"}
              onChange={handleChange}
              value={userDetails.location_pin}
              type="text"
            />
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>
              Joining Date
            </Typography>
            <MyDatePicker
              name="joining_date"
              product={"HR"}
              onChange={(date) => handleChangeDate(date, "joining_date")}
              value={userDetails.joining_date}
              placeholder="Enter joining date"
            />
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>
              Date of Birth
            </Typography>
            <MyDatePicker
              name="dob"
              product={"HR"}
              onChange={(date) => handleChangeDate(date, "dob")}
              value={userDetails.dob}
              placeholder="Enter birth date"
            />
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>
              Address 1
            </Typography>
            <MyTextfield
              name="user_address1"
              product={"HR"}
              onChange={handleChange}
              value={userDetails.user_address1}
              type="text"
            />
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>
              Address 2
            </Typography>
            <MyTextfield
              name="user_address2"
              product={"HR"}
              onChange={handleChange}
              value={userDetails.user_address2}
              type="text"
            />
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>
              Mobile No
            </Typography>
            <MyTextfield
              name="user_mobile_no"
              product={"HR"}
              onChange={handleChange}
              value={userDetails.user_mobile_no}
              type="text"
            />
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>
              Zip Code
            </Typography>
            <MyTextfield
              name="zip_code"
              product={"HR"}
              onChange={handleChange}
              value={userDetails.zip_code}
              type="text"
            />
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>
              Emergency Contact Name
            </Typography>
            <MyTextfield
              name="emergency_contact_name"
              product={"HR"}
              onChange={handleChange}
              value={userDetails.emergency_contact_name}
              type="text"
            />
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.hrUserDetailsLabel}>
              Emergency Contact Mobile
            </Typography>
            <MyTextfield
              name="emergency_contact_mobile"
              product={"HR"}
              onChange={handleChange}
              value={userDetails.emergency_contact_mobile}
              type="text"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={4}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <Typography color="textSecondary" variant="h4" gutterBottom>
                  ATTENDANCE
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.hrUserDetailsCardText}>
                      Total Shifts Worked:
                    </Typography>
                    <Typography className={classes.hrUserDetailsCardTextBold}>
                      {userDetails.total_shifts_worked}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.hrUserDetailsCardText}>
                      Late For Shift:
                    </Typography>
                    <Typography className={classes.hrUserDetailsCardTextBold}>
                      {userDetails.total_late_clockin}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.hrUserDetailsCardText}>
                      Missed Breaks:
                    </Typography>
                    <Typography className={classes.hrUserDetailsCardTextBold}>
                      {userDetails.total_missed_breaks}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.hrUserDetailsCardText}>
                      Missed Clock Outs:
                    </Typography>
                    <Typography className={classes.hrUserDetailsCardTextBold}>
                      {userDetails.total_missed_clockout}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.hrUserDetailsCardText}>
                      No Shows:
                    </Typography>
                    <Typography className={classes.hrUserDetailsCardTextBold}>
                      {userDetails.total_no_show}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <Typography color="textSecondary" variant="h4" gutterBottom>
                  AVG HOURS PER WEEK
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.hrUserDetailsCardText}>
                      Total Shifts Worked:
                    </Typography>
                    <Typography className={classes.hrUserDetailsCardTextBold}>
                      {userDetails.total_shifts_worked}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <Typography color="textSecondary" variant="h4" gutterBottom>
                  EXPERIENCE BY ROLE
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.hrUserDetailsCardText}>
                      Total Shifts Worked:
                    </Typography>
                    <Typography className={classes.hrUserDetailsCardTextBold}>
                      {userDetails.total_shifts_worked}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <Typography color="textSecondary" variant="h4" gutterBottom>
                  AVG SHIFT FEEDBACK
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.hrUserDetailsCardText}>
                      Total Shifts Worked:
                    </Typography>
                    <Typography className={classes.hrUserDetailsCardTextBold}>
                      {userDetails.total_shifts_worked}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserDetails;
