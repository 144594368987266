import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { documentStyles } from "../Styles";
import { fileUploadOne } from "../../../../../icons";
import { usersServices } from "../../../UsersList/modules/services";
import axios from "axios";
import { MY_TOKEN } from "../../../../../config";
import { RemoveRedEye } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  hrDocumentsContainer: {
    padding: "1rem !important",
  },
}));

const Documents = ({ userDetails, onDocumentChange }) => {
  const [fileNames, setFileNames] = useState({
    photoId: "",
    nationalId: "",
    offerLetter: "",
    terminationLetter: "",
    uid: userDetails._id,
  });
  const classes = useStyles();

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await usersServices.fetchUserDocuments(
          userDetails._id
        );
        if (response.data.success) {
          const { photoId, nationalId, offerLetter, terminationLetter } =
            response.data.result[0] || {};
          setFileNames({
            photoId,
            nationalId,
            offerLetter,
            terminationLetter,
            uid: userDetails._id,
          });
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchDocuments();
  }, [userDetails]);


  const handleFileUpload = async (field, event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    let data = new FormData();
    data.append("myfile", selectedFile);
    data.append("product_name", "HR");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/upload`,
        data,
        {
          headers: {
            Authorization: MY_TOKEN,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success === 1) {
        const fileUrl = response.data.result.startsWith("https")
          ? response.data.result
          : `https://${response.data.result.replace(/^http:/, "")}`;

        const updatedFileNames = {
          ...fileNames,
          [field]: fileUrl,
        };

        setFileNames(updatedFileNames);
        onDocumentChange(updatedFileNames);
        console.log(`${field} uploaded successfully:`, fileUrl);
      }
    } catch (error) {
      console.error(`Error uploading ${field}:`, error);
    }
  };

  return (
    <Grid container className={classes.hrDocumentsContainer}>
      <div style={{ margin: "0px 12px", width: "100%" }}>
        <p style={{ fontWeight: 500, color: "#000000B2" }}>Photo ID</p>
        <Grid item sm={6} xs={6} style={{ marginTop: "-10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <div style={{ width: fileNames.photoId ? "93%" : "100%" }}>
              <input
                style={{ display: "none" }}
                type="file"
                id="gallery-file-docs"
                accept=".jpg,.jpeg,.png"
                multiple
                onChange={(e) => handleFileUpload("photoId", e)}
              />

              <label
                htmlFor="gallery-file-docs"
                className="w-100"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #ccc",
                  padding: "8px 16px",
                  borderRadius: "4px",
                }}
              >
                <span style={{ color: "#999" }}>
                  {fileNames.photoId.split("/").pop() || "Upload Image"}
                </span>

                <img
                  src={fileUploadOne}
                  style={{ width: "20px", height: "20px" }}
                />
              </label>
            </div>
            {fileNames.photoId && (
              <a
                href={fileNames.photoId}
                target="_blank"
                rel="noopener noreferrer"
              >
                <RemoveRedEye style={{ color: "#007E63" }} />
              </a>
            )}
          </div>
        </Grid>
      </div>
      <div style={{ margin: "0px 12px", width: "100%" }}>
        <p style={{ fontWeight: 500, color: "#000000B2" }}>
          National ID (SIN/SSN)
        </p>
        <Grid item sm={6} xs={6} style={{ marginTop: "-10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <div
              style={{
                width: fileNames.nationalId ? "93%" : "100%",
              }}
            >
              <input
                style={{ display: "none" }}
                type="file"
                id="national-id-upload"
                accept=".pdf,.doc,.docx"
                multiple
                onChange={(e) => handleFileUpload("nationalId", e)}
              />

              <label
                htmlFor="national-id-upload"
                className="w-100"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #ccc",
                  padding: "8px 16px",
                  borderRadius: "4px",
                }}
              >
                <span style={{ color: "#999" }}>
                  {fileNames.nationalId.split("/").pop() || "Upload document"}
                </span>

                <img
                  src={fileUploadOne}
                  style={{ width: "20px", height: "20px" }}
                />
              </label>
            </div>

            {fileNames.nationalId && (
              <a
                href={fileNames.nationalId}
                target="_blank"
                rel="noopener noreferrer"
              >
                <RemoveRedEye style={{ color: "#007E63" }} />
              </a>
            )}
          </div>
        </Grid>
      </div>
      <div style={{ margin: "0px 12px", width: "100%" }}>
        <p style={{ fontWeight: 500, color: "#000000B2" }}>Offer Letter</p>
        <Grid item sm={6} xs={6} style={{ marginTop: "-10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <div
              style={{
                width: fileNames.offerLetter ? "93%" : "100%",
              }}
            >
              <input
                style={{ display: "none" }}
                type="file"
                id="offer-letter-uploa"
                accept=".pdf,.doc,.docx"
                onChange={(e) => handleFileUpload("offerLetter", e)}
              />

              <label
                htmlFor="offer-letter-uploa"
                className="w-100"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #ccc",
                  padding: "8px 16px",
                  borderRadius: "4px",
                }}
              >
                <span style={{ color: "#999" }}>
                  {fileNames.offerLetter.split("/").pop() || "Upload document"}
                </span>

                <img
                  src={fileUploadOne}
                  style={{ width: "20px", height: "20px" }}
                />
              </label>
            </div>
            {fileNames.offerLetter && (
              <a
                href={fileNames.offerLetter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <RemoveRedEye style={{ color: "#007E63" }} />
              </a>
            )}
          </div>
        </Grid>
      </div>
      <div style={{ margin: "0px 12px", width: "100%" }}>
        <p style={{ fontWeight: 500, color: "#000000B2" }}>
          Termination Letter
        </p>
        <Grid item sm={6} xs={6} style={{ marginTop: "-10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <div
              style={{
                width: fileNames.terminationLetter ? "93%" : "100%",
              }}
            >
              <input
                style={{ display: "none" }}
                type="file"
                id="termination-letter-upload"
                accept=".pdf,.doc,.docx"
                onChange={(e) => handleFileUpload("terminationLetter", e)}
              />

              <label
                htmlFor="termination-letter-upload"
                className="w-100"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #ccc",
                  padding: "8px 16px",
                  borderRadius: "4px",
                }}
              >
                <span style={{ color: "#999" }}>
                  {fileNames.terminationLetter.split("/").pop() || "Upload document"}
                </span>

                <img
                  src={fileUploadOne}
                  style={{ width: "20px", height: "20px" }}
                />
              </label>
            </div>
            {fileNames.terminationLetter && (
              <a
                href={fileNames.terminationLetter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <RemoveRedEye style={{ color: "#007E63" }} />
              </a>
            )}
          </div>
        </Grid>
      </div>
    </Grid>
  );
};

export default Documents;
