import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  scheduler_Schedule_Data,
  scheduler_MyAvailability_Data,
  scheduler_TeamAvailability_Data,
  scheduler_TimeOffRequests_Data,
  scheduler_TradeShifts_Data,
  scheduler_CoverShifts_Data,
  scheduler_payroll_run_Data,
  scheduler_correction_request_Data,
  scheduler_Timesheet_Data,
  scheduler_TimecardReport_Data,
  scheduler_Payroll_Data,
  scheduler_Payroll_Summary_Data,
  scheduler_Roles_Data,
} from "../constants";
import { makeStyles } from "@material-ui/core/styles";
import { ovHrV5Color } from "../../../../../config";

const useStyles = makeStyles((theme) => ({
  hrCheckboxRoot: {
    padding: "30px 20px",
    color: `${ovHrV5Color.main} !important`,
    "&.Mui-checked": {
      color: `${ovHrV5Color.main} !important`,
    },
    "&:hover": {
      color: `${ovHrV5Color.main} !important`,
    },
  },
  hrTextField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: `${ovHrV5Color.main} !important`,
      },
    },
  },
  select: {
    "MuiSelect-outlined.MuiSelect-outlined": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    "& .MuiInputBase-input": {
      "&:focus": {
        borderRadius: 6,
        borderColor: `${ovHrV5Color.main} !important`,
      },
    },
    "&:before": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    "&:after": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
  },
  icon: {
    fill: ovHrV5Color.main,
  },
  headCell: {
    color: "#ffffff !important",
    fontWeight: "500 !important",
    textTransform: "capitalize",
  },
  rootToolbar: {
    minHeight: "58px !important",
    padding: "2px !important",
  },
  title: {
    flex: "1 1 100% !important",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  hrExpansionPanelMain: {
    "&:last-child": {
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    "&:first-child": {
      borderTopLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
    },
  },
  hrExpansionPanelSummary: {
    backgroundColor: `${ovHrV5Color.main} !important`,
  },
  hrHeading: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    color: "#ffffff !important",
  },
  headingReports: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    color: "black !important",
  },
  hrHeadingIcon: {
    color: "#ffffff !important",
  },
  hrExpansionPanelDetailsSecond: {
    borderLeft: `5px solid  ${ovHrV5Color.main} !important`,
    borderRight: `5px solid  ${ovHrV5Color.main} !important`,
    borderBottom: `5px solid ${ovHrV5Color.main} !important`,
    padding: "20px !important !important",
    display: "flex !important",
    flexDirection: "column !important",
  },
  hrExpansionPanel: {
    "&:last-child": {
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    "&:first-child": {
      borderTopLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
    },
  },
  hrExpansionPanelSummaryThird: {
    backgroundColor: "#f6f7f754 !important",
    padding: "0px 10px !important",
    height: "30px !important",
  },
  hrExpansionPanelDetailsThird: {
    padding: "10px 20px !important",
  },
  ExpansionPanelDetailsThirdCategory: {
    padding: "10px 20px !important",
    height: "20px !important",
  },
  ExpansionPanelDetailsThirdRole: {
    padding: "10px 20px !important",
    marginLeft: "32px !important",
    display: "flex !important",
    flexDirection: "column !important",
  },
  ExpansionPanelDetailsThirdBase: {
    padding: "10px 20px !important",
    marginLeft: "32px !important",
  },
  hrFormControlLabelSecond: {
    padding: "6px !important",
  },
  hrPageTitle: {
    lineHeight: "40px !important",
    color: "black !important",
  },
  pageHeading: {
    fontSize: "40px !important",
    color: ovHrV5Color.main,
    fontWeight: "700 !important",
  },
  viewLogsBtn: {
    border: `1px solid ${ovHrV5Color.main} !important`,
    borderRadius: "5px !important",
    color: `${ovHrV5Color.main} !important`,
    display: "inline-block !important",
    fontSize: "13px !important",
    height: "100% !important",
    width: "auto !important",
    padding: "8px 15px !important",
    "&:hover": {
      color: `${ovHrV5Color.main} !important`,
      textDecoration: "none !important",
    },
  },
}));

const SchedulerContent = ({ onPermissionsUpdate, checkboxesData }) => {
  const styles = useStyles();

  const initializeAccessRights = () => ({
    scheduler_dashboard: checkboxesData.scheduler_dashboard === 1,
    scheduler_settings: checkboxesData.scheduler_settings === 1,
    receive_manager_alert: checkboxesData.receive_manager_alert === 1,
    scheduler_Schedule_Data: scheduler_Schedule_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    scheduler_MyAvailability_Data: scheduler_MyAvailability_Data.map(
      (item) => ({
        ...item,
        checked: checkboxesData[item.Name] === 1,
      })
    ),
    scheduler_TeamAvailability_Data: scheduler_TeamAvailability_Data.map(
      (item) => ({
        ...item,
        checked: checkboxesData[item.Name] === 1,
      })
    ),
    scheduler_TimeOffRequests_Data: scheduler_TimeOffRequests_Data.map(
      (item) => ({
        ...item,
        checked: checkboxesData[item.Name] === 1,
      })
    ),
    scheduler_TradeShifts_Data: scheduler_TradeShifts_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    scheduler_CoverShifts_Data: scheduler_CoverShifts_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    scheduler_payroll_run_Data: scheduler_payroll_run_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    scheduler_correction_request_Data: scheduler_correction_request_Data.map(
      (item) => ({
        ...item,
        checked: checkboxesData[item.Name] === 1,
      })
    ),
    scheduler_Timesheet_Data: scheduler_Timesheet_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    scheduler_TimecardReport_Data: scheduler_TimecardReport_Data.map(
      (item) => ({
        ...item,
        checked: checkboxesData[item.Name] === 1,
      })
    ),
    scheduler_Payroll_Data: scheduler_Payroll_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    scheduler_Payroll_Summary_Data: scheduler_Payroll_Summary_Data.map(
      (item) => ({
        ...item,
        checked: checkboxesData[item.Name] === 1,
      })
    ),
    scheduler_Roles_Data: scheduler_Roles_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
  });

  const [accessRights, setAccessRights] = useState(initializeAccessRights());

  useEffect(() => {
    setAccessRights(initializeAccessRights());
  }, [checkboxesData]);

  const handleCheckboxChange = (group, index = null) => {
    setAccessRights((prevState) => {
      if (Array.isArray(prevState[group])) {
        let updatedGroup;
        if (index !== null) {
          updatedGroup = [...prevState[group]];
          updatedGroup[index].checked = !updatedGroup[index].checked;
        } else {
          const allChecked = prevState[group].every((item) => item.checked);
          updatedGroup = prevState[group].map((item) => ({
            ...item,
            checked: !allChecked,
          }));
        }

        const newState = { ...prevState, [group]: updatedGroup };
        onPermissionsUpdate(getPermissionsValues(newState));
        return newState;
      } else {
        const newState = { ...prevState, [group]: !prevState[group] };
        onPermissionsUpdate(getPermissionsValues(newState));
        return newState;
      }
    });
  };

  const getPermissionsValues = (state) => {
    const permissions = {};
    Object.entries(state).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          permissions[item.Name] = item.checked ? 1 : 0;
        });
      } else {
        permissions[key] = value ? 1 : 0;
      }
    });
    return permissions;
  };

  const renderAccessRightPanel = (title, groupKey, data = []) => (
    <ExpansionPanel className={styles.hrExpansionPanel}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        className={styles.hrExpansionPanelSummaryThird}
      >
        <Checkbox
          color="primary"
          checked={data.every((item) => item.checked)}
          indeterminate={
            data.some((item) => item.checked) &&
            !data.every((item) => item.checked)
          }
          onChange={() => handleCheckboxChange(groupKey)}
          className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
        />

        <Typography className={styles.hrPageTitle} variant="h6" color="primary">
          {title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={styles.hrExpansionPanelDetailsThird}>
        <Grid container spacing={2}>
          {data.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={item.Name}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={item.checked}
                    className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                    onChange={() => handleCheckboxChange(groupKey, index)}
                  />
                }
                label={item.Label}
              />
            </Grid>
          ))}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );

  return (
    <Grid item md={12} xs={12}>
      <ExpansionPanel className={styles.hrExpansionPanelMain}>
        <ExpansionPanelSummary
          className={styles.hrExpansionPanelSummary}
          expandIcon={<ExpandMoreIcon className={styles.hrHeadingIcon} />}
          aria-controls="scheduler-content"
          id="scheduler-header"
        >
          <Typography className={styles.hrHeading}>Scheduler</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={styles.hrExpansionPanelDetailsSecond}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "3px",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <Checkbox
                  color="primary"
                  checked={accessRights.scheduler_dashboard}
                  onChange={() => handleCheckboxChange("scheduler_dashboard")}
                  className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                  style={{ marginLeft: "0.5rem" }}
                />
                <Typography
                  className={styles.hrPageTitle}
                  variant="h6"
                  color="primary"
                >
                  Scheduler Dashboard
                </Typography>
              </div>
            </Grid>

            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Schedule",
                "scheduler_Schedule_Data",
                accessRights.scheduler_Schedule_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "My Availability",
                "scheduler_MyAvailability_Data",
                accessRights.scheduler_MyAvailability_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Team Availability",
                "scheduler_TeamAvailability_Data",
                accessRights.scheduler_TeamAvailability_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Time-Off Requests",
                "scheduler_TimeOffRequests_Data",
                accessRights.scheduler_TimeOffRequests_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Trade Shifts",
                "scheduler_TradeShifts_Data",
                accessRights.scheduler_TradeShifts_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Cover Shifts",
                "scheduler_CoverShifts_Data",
                accessRights.scheduler_CoverShifts_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Payroll Run",
                "scheduler_payroll_run_Data",
                accessRights.scheduler_payroll_run_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Correction Request",
                "scheduler_correction_request_Data",
                accessRights.scheduler_correction_request_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Timesheet",
                "scheduler_Timesheet_Data",
                accessRights.scheduler_Timesheet_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Timecard Report",
                "scheduler_TimecardReport_Data",
                accessRights.scheduler_TimecardReport_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Payroll",
                "scheduler_Payroll_Data",
                accessRights.scheduler_Payroll_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Payroll Summary",
                "scheduler_Payroll_Summary_Data",
                accessRights.scheduler_Payroll_Summary_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              {renderAccessRightPanel(
                "Roles",
                "scheduler_Roles_Data",
                accessRights.scheduler_Roles_Data
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "3px",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <Checkbox
                  color="primary"
                  checked={accessRights.receive_manager_alert}
                  onChange={() => handleCheckboxChange("receive_manager_alert")}
                  className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                  style={{ marginLeft: "0.5rem" }}
                />
                <Typography
                  className={styles.hrPageTitle}
                  variant="h6"
                  color="primary"
                >
                  Receive Manager Alerts
                </Typography>
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "3px",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <Checkbox
                  color="primary"
                  checked={accessRights.scheduler_settings}
                  onChange={() => handleCheckboxChange("scheduler_settings")}
                  className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                  style={{ marginLeft: "0.5rem" }}
                />
                <Typography
                  className={styles.hrPageTitle}
                  variant="h6"
                  color="primary"
                >
                  Scheduler Settings
                </Typography>
              </div>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  );
};

export default SchedulerContent;
