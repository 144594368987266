import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Modal,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  HeaderHeading,
  StyledContent,
  StyledHeader,
  StyledModal,
} from "../../../v5/HR/LetterTemplate/List/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  LoadingData,
  MyDatePicker,
  MySelectField,
  MyTextfield,
} from "../../../../components";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { MuiTelInput } from "mui-tel-input";
import { hrNewUserObjectValues, themeColor } from "../View/constants";
import moment from "moment";
import { alert, utcToLocal } from "../../../../utilities";
import { FilledButton, OutlinedButton } from "../../../../utilities/v5/common";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PropTypes from "prop-types";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import { ovHrV5Color } from "../../../../config";
import { makeStyles } from "@material-ui/core/styles";
import { alpha, styled } from "@mui/material/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const steps = ["User Details", "Location and Payroll"];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg, #007E63 0%, #007E6340 50%, #007E63 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg, #007E63 0%, #007E6340 50%, #007E63 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...theme.applyStyles("dark", {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundImage:
          "linear-gradient( 136deg, #007E6340 0%, #007E63 20%, #007E6340 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundImage:
          "linear-gradient( 136deg, #007E6340 0%, #007E63 20%, #007E6340 100%)",
      },
    },
  ],
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <GroupAddIcon style={{ fontSize: "20px" }} />,
    2: <EditLocationAltIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const RedSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#007E63",
  },
  "& .MuiSwitch-switchBase.Mui-checked:hover": {
    backgroundColor: alpha("#007E63", theme.palette.action.hoverOpacity),
    color: "#007E63",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#007E63",
  },
}));

const useStyles = makeStyles((theme) => ({
  hrLocationContainer: {
    padding: "1rem !important",
  },

  root: {
    color: "#007E63 !important",
    "&.Mui-checked": {
      color: "#007E63 !important",
    },
    "&:hover": {
      color: "#007E63 !important",
    },
  },

  FormControlLabelSecond: {
    padding: "6px !important",
  },

  switch: {
    color: "#007E63 !important",
  },

  locationField: {
    backgroundColor: "white !important",
  },

  hrLocationLabel: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    fontFamily: "Roboto, sans-serif !important",
    color: `${ovHrV5Color.main} !important`,
    marginBottom: "0.5rem !important",
  },

  firstStepContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem !important",
    marginBottom: "80px",
  },
}));

function AddUserModal({
  locationsData: initialLocationsData,
  rolesData,
  onOpenClick,
  onCloseClick,
  onSubmit,
}) {
  const classes = useStyles();
  const [addModalValues, setAddModalValues] = useState(hrNewUserObjectValues);
  const [errors, setErrors] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [locationsData, setLocationsData] = useState(initialLocationsData);

  const handleSwitch = (index) => {
    setLocationsData((prevLocations) => {
      const updatedLocations = [...prevLocations];
      updatedLocations[index].status = updatedLocations[index].status ? 0 : 1;
      return updatedLocations;
    });
  };

  useEffect(() => {
    if (initialLocationsData) {
      setLocationsData(initialLocationsData);
      console.log("initialLocationsData in Users", initialLocationsData);
    }
  }, [initialLocationsData]);

  const toggleCheckbox = (e, index, name) => {
    const checked = e.target.checked ? 1 : 0;
    setLocationsData((prevLocations) => {
      const updatedLocations = [...prevLocations];
      updatedLocations[index][name][0] = checked;
      return updatedLocations;
    });
  };

  const handleNext = () => {
    if (activeStep === 0 && validateForm()) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const validateField = (name, value) => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      setErrors((prev) => ({ ...prev, [name]: "This field is required." }));
    } else {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const validateForm = () => {
    const requiredFields = [
      "user_name",
      "user_email",
      "user_address1",
      "user_mobile_no",
      "joining_date",
      "date_of_birth",
      "user_role",
    ];
    let isValid = true;

    requiredFields.forEach((field) => {
      if (!addModalValues[field]) {
        isValid = false;
        setErrors((prev) => ({ ...prev, [field]: "This field is required." }));
      }
    });
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddModalValues((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const handleChangeDate = (date, field) => {
    setAddModalValues((prev) => ({ ...prev, [field]: date }));
    validateField(field, date);
  };

  const handleAddModalSubmission = () => {
    let isValid = true;
    const newErrors = {};

    if (!validateForm()) {
      isValid = false;
    }

    const selectedLocations = locationsData.filter(
      (location) => location.status
    );
    if (activeStep === 1 && selectedLocations.length === 0) {
      newErrors.locations = "Please select at least one location.";
      isValid = false;
    }

    selectedLocations.forEach((location, index) => {
      if (!location.payroll_id) {
        newErrors[`payroll_${index}`] =
          "Please select a payroll template for this location.";
        isValid = false;
      }
    });

    setErrors(newErrors);
    if (isValid) {
      const step1Payload = {
        user_name: addModalValues.user_name,
        user_email: addModalValues.user_email,
        user_address1: addModalValues.user_address1,
        user_mobile_no: addModalValues.user_mobile_no,
        user_role: addModalValues.user_role,
        show_location_schedule: addModalValues.show_location_schedule,
        exclude_location_alert: addModalValues.exclude_location_alert,
        exclude_timeclock_errors: addModalValues.exclude_timeclock_errors,
        eligible_open_shifts: addModalValues.eligible_open_shifts,
        joining_date: addModalValues.joining_date,
        date_of_birth: addModalValues.date_of_birth,
        locations: locationsData
          .filter((loc) => loc.status)
          .map((loc) => loc.lid),
      };

      const step2Payload = locationsData
        .filter((loc) => loc.status)
        .map((loc) => ({
          lid: loc.lid,
          payroll_id: loc.payroll_id,
          show_location_schedule: loc.show_location_schedule[0] ? 1 : 0,
          exclude_location_alert: loc.exclude_location_alert[0] ? 1 : 0,
          exclude_timeclock_errors: loc.exclude_timeclock_errors[0] ? 1 : 0,
          eligible_open_shifts: loc.eligible_open_shifts[0] ? 1 : 0,
          status: loc.status,
          location_name: loc.location_name,
        }));

      console.log("Step 1 Payload:", step1Payload);
      console.log("Step 2 Payload:", step2Payload);
      onSubmit(step1Payload, step2Payload);
      setAddModalValues(hrNewUserObjectValues);
      setLocationsData(initialLocationsData);
      setActiveStep(0);
      onCloseClick();
    }
  };

  const handleMobileChange = (value) => {
    setAddModalValues((prev) => ({ ...prev, user_mobile_no: value }));
    validateMobileNumber(value);
  };

  const validateMobileNumber = (value) => {
    const isValid = value && value.length >= 10 && value.length <= 15;
    if (!isValid) {
      setErrors((prev) => ({
        ...prev,
        user_mobile_no: "Please enter a valid mobile number.",
      }));
    } else {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors.user_mobile_no;
        return newErrors;
      });
    }
  };

  const handleChangePayroll = (e, index) => {
    setLocationsData((prevLocations) => {
      const updatedLocations = [...prevLocations];
      updatedLocations[index].payroll_id = e.target.value;
      return updatedLocations;
    });
  };

  return (
    <Modal
      open={onOpenClick}
      onClose={onCloseClick}
      onSubmit={handleAddModalSubmission}
    >
      <StyledModal>
        <StyledHeader>
          <ArrowBackIosIcon
            style={{
              fontSize: "24px",
              color: "white",
              cursor: "pointer",
              position: "absolute",
              left: "15px",
              display: activeStep === 0 ? "none" : "block",
            }}
            onClick={handleBack}
          />
          <HeaderHeading>Add User</HeaderHeading>
          <CloseIcon
            style={{
              position: "absolute",
              fontSize: "24px",
              color: "white",
              right: "15px",
              cursor: "pointer",
            }}
            onClick={onCloseClick}
          />
        </StyledHeader>
        <StyledContent>
          <div style={{ width: "100%" }}>
            <Stepper
              activeStep={activeStep}
              color="primary"
              alternativeLabel
              style={{ width: "100%" }}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Roboto, sans-serif",
                        fontSize: "12px",
                      }}
                    >
                      {label}
                    </span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {activeStep === 0 && (
            <div className={classes.firstStepContainer}>
              <Grid item xs={12}>
                <MyTextfield
                  name="user_name"
                  label="Name"
                  product={"HR"}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter user name"
                  value={addModalValues.user_name}
                  error={!!errors.user_name}
                  helperText={errors.user_name}
                />
              </Grid>

              <Grid item xs={12}>
                <MyTextfield
                  name="user_email"
                  label="Email"
                  product={"HR"}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter user email"
                  value={addModalValues.user_email}
                  error={!!errors.user_email}
                  helperText={errors.user_email}
                />
              </Grid>

              <Grid item xs={12}>
                <MyTextfield
                  name="user_address1"
                  label="Address"
                  product={"HR"}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter user address"
                  value={addModalValues.user_address1}
                  error={!!errors.user_address1}
                  helperText={errors.user_address1}
                />
              </Grid>

              <Grid item xs={12}>
                <MuiTelInput
                  label="Mobile No"
                  value={addModalValues.user_mobile_no || ""}
                  onChange={handleMobileChange}
                  error={!!errors.user_mobile_no}
                  helperText={errors.user_mobile_no}
                  fullWidth
                  required
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    name="joining_date"
                    label="Joining Date"
                    product={"HR"}
                    onChange={(date) => handleChangeDate(date, "joining_date")}
                    placeholder="Enter joining date"
                    value={addModalValues.joining_date}
                    size="small"
                    sx={{ width: "100%" }}
                    required
                    disableFuture={true}
                    slotProps={{ textField: { size: "small" } }}
                    error={!!errors.joining_date}
                    helperText={errors.joining_date}
                  />
                  {errors.joining_date && (
                    <FormHelperText
                      style={{
                        color: "red",
                        marginTop: "7px",
                        marginLeft: "16px",
                      }}
                    >
                      {errors.joining_date}
                    </FormHelperText>
                  )}
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    product={"HR"}
                    name="date_of_birth"
                    label="Date of Birth"
                    onChange={(date) => handleChangeDate(date, "date_of_birth")}
                    placeholder="Enter birth date"
                    value={addModalValues.date_of_birth}
                    disableFuture={true}
                    required
                    maxDate={dayjs().subtract(18, "year")}
                    slotProps={{ textField: { size: "small" } }}
                    error={!!errors.date_of_birth}
                    helperText={errors.date_of_birth}
                    sx={{ width: "100%" }}
                  />
                  {errors.date_of_birth && (
                    <FormHelperText
                      style={{
                        color: "red",
                        marginTop: "7px",
                        marginLeft: "16px",
                      }}
                    >
                      {errors.date_of_birth}
                    </FormHelperText>
                  )}
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <MySelectField
                  name="user_role"
                  product={"HR"}
                  onChange={handleChange}
                  placeholder="Please Select role"
                  value={addModalValues.user_role}
                  error={!!errors.user_role}
                  helperText={errors.user_role}
                >
                  <option value={0}>Select role</option>
                  {rolesData?.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.role_name}
                    </option>
                  ))}
                </MySelectField>
              </Grid>
            </div>
          )}
          {activeStep === 1 && (
            <>
              <Grid container className={classes.hrLocationContainer}>
                {locationsData?.length !== 0 ? (
                  <Grid container>
                    {locationsData.map((data, index) => (
                      <Grid item key={index} md={6} xs={12}>
                        <Grid item md={12} xs={12}>
                          <FormControlLabel
                            control={
                              <RedSwitch
                                checked={data.status ? true : false}
                                onChange={() => handleSwitch(index)}
                              />
                            }
                            label={data.location_name}
                          />
                        </Grid>

                        <Grid item md={2} xs={2}>
                          <Typography className={classes.hrLocationLabel}>
                            Payroll:{" "}
                          </Typography>
                          <MySelectField
                            className={classes.locationField}
                            label=""
                            name="id"
                            onChange={(e) => handleChangePayroll(e, index)}
                            value={data.payroll_id}
                            placeholder="Please Select Payroll"
                            error={!!errors[`payroll_${index}`]}
                            helperText={errors[`payroll_${index}`]}
                          >
                            <option key={0} value={0}>
                              Select Payroll
                            </option>
                            {data.locationsPayroll.map((option) => (
                              <option value={option._id} key={option._id}>
                                {option.template_name}
                              </option>
                            ))}
                          </MySelectField>
                        </Grid>
                        {errors[`payroll_${index}`] && (
                          <Typography color="error" variant="body2">
                            {errors[`payroll_${index}`]}
                          </Typography>
                        )}
                        <Grid item xs={10} md={10}></Grid>

                        <div
                          style={{
                            width: "400px",
                            height: "110px",
                            marginBottom: "1.5rem",
                            marginLeft: "-2rem",
                          }}
                        >
                          <ul style={{ listStyleType: "none" }}>
                            <li>
                              <FormControlLabel
                                label={`Show in location schedule`}
                                control={
                                  <Checkbox
                                    className={`${classes.root} ${classes.FormControlLabelSecond}`}
                                    name="show_location_schedule"
                                    value={data.show_location_schedule[0]}
                                    checked={
                                      data.show_location_schedule[0]
                                        ? true
                                        : false
                                    }
                                    size="medium"
                                    onClick={(e) =>
                                      toggleCheckbox(
                                        e,
                                        index,
                                        "show_location_schedule"
                                      )
                                    }
                                  />
                                }
                              />
                            </li>
                            <li>
                              <FormControlLabel
                                label={`Eligible for open shifts`}
                                control={
                                  <Checkbox
                                    name="eligible_open_shifts"
                                    value={data.eligible_open_shifts[0]}
                                    className={`${classes.root} ${classes.FormControlLabelSecond}`}
                                    checked={
                                      data.eligible_open_shifts[0]
                                        ? true
                                        : false
                                    }
                                    size="medium"
                                    onClick={(e) =>
                                      toggleCheckbox(
                                        e,
                                        index,
                                        "eligible_open_shifts"
                                      )
                                    }
                                  />
                                }
                              />
                            </li>
                          </ul>
                        </div>
                        {errors.locations && (
                          <Typography
                            color="error"
                            variant="body2"
                            style={{ marginTop: "10px" }}
                          >
                            {errors.locations}
                          </Typography>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid
                    container
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Grid item xs={12} style={{ padding: "5rem" }}>
                      <LoadingData />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          )}

          <div
            style={{
              position: "fixed",
              bottom: "0",
              left: "0",
              width: "100%",
              height: "5rem",
              backgroundColor: "white",
              zIndex: "100",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
                height: "100%",
                gap: "10px",
                alignItemss: "center",
                paddingRight: "1rem",
                marginTop: "1rem",
              }}
            >
              {activeStep === 1 ? (
                <>
                  <OutlinedButton
                    text="Previous"
                    size={{ width: "130px", height: "40px" }}
                    onClick={handleBack}
                    textColor={themeColor}
                    textSize={"12px"}
                  />
                  <FilledButton
                    bgColor={themeColor}
                    textColor={"#ffffff"}
                    textSize={"12px"}
                    size={{ width: "130px", height: "40px" }}
                    text="Save"
                    onClick={handleAddModalSubmission}
                  />
                </>
              ) : (
                <>
                  <FilledButton
                    bgColor={themeColor}
                    onClick={handleNext}
                    size={{ width: "130px", height: "40px" }}
                    text={"Next"}
                    textColor={"#ffffff"}
                    textSize={"16px"}
                  />
                </>
              )}
            </div>
          </div>
        </StyledContent>
      </StyledModal>
    </Modal>
  );
}

export default AddUserModal;
