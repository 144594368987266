import React, { useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import _ from "lodash";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import BusinessIcon from "@material-ui/icons/Business";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Avatar from "@material-ui/core/Avatar";
import DeleteModal from "./DeleteModal";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ovHrV5Color } from "../../../config";
import { history } from "../../../history";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDrop } from "react-dnd";
import withDragDropContext from "./withDnDContext";
import Popover from "@material-ui/core/Popover";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";
import { rolesServices } from "../../common/Roles/modules/services";
import { TextField, Grid } from "@material-ui/core";

import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {
  fetchHeirarachyChartData,
  fetchRoleData,
  addHeirarachyChart,
  deleteHeirarachyChart,
} from "./modules/action";
import { hierarachyServices } from "./modules/services";
import Typography from "@material-ui/core/Typography";
import { alert, invalidToken } from "../../../utilities";
// import RoleFormContent from "../../";

import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import {
  LoadingDataHR,
  NoPermission,
  MyLeft50Modal,
} from "../../../components";
import LoadingHR from "../../../components/LoaderHR";
import RoleFormContent from "../../common/Roles/AddOrEditRoles/AddOrEditRoles";
import { permissionsObjectForAddRole } from "../../common/Roles/View/constants";

const useStyles = makeStyles((theme) => ({
  // '@global': {
  //   '*::-webkit-scrollbar': {
  //     display: "none"
  //   },
  // },
  root: {
    background: "white",
    display: "inline-block",
    borderRadius: 16,
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "green", // Change to your desired focus color
      },
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginTop: -10,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.short,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },

  avatar: {
    backgroundColor: "#ECECF4",
  },
}));
function Organization({
  org,
  fetchHeirarachyChartData,
  fetchRoleData,
  setLoading,
  permissionsData,
  onCollapse,
  collapsed,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [roleName, setRoleName] = React.useState("");
  const [roleId, setRoleId] = React.useState("");
  const [roleModal, setRoleModal] = React.useState(false);
  const [rolesDetailsById, setRolesDetailsById] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [isEdit, setIsEdit] = React.useState(false);
  const [assignusers, setAssignUsersRole] = React.useState([]);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [assignModal, setAssignModal] = React.useState(null);
  const [singleRoleData, setSingleRoleData] = React.useState({});
  const [totalRolesCount, setTotalRolesCount] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [formData, setFormData] = React.useState({}); // Default state as an empty object, not a string
  const [orderBy, setOrderBy] = React.useState("");
  const [orderDirection, setOrderDirection] = React.useState("asc");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showAddEditModal, setShowAddEditModal] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseassignUsermodal = () => {
    setAssignModal(null);
  };

  // const handleClickrole = (value, id) => {
  //   console.log(id, value,'paranet')
  //   // console.log(id,'paranet')
  //   setRoleName(value)
  //   setRoleId(id)
  //   setRoleModal(true);
  // };

  const handleCloserole = () => {
    setRoleModal(false);
  };

  const handleClickrole = (value, id) => {
    console.log(id, value, "paranet");
    setIsEdit(false);
    setFormData((prev) => ({
      ...prev,
      role_parentId: value, // Set the `role_parentId` from the `id` argument
      parent_role_name: id, // Set the `parent_role_name` from the `value` argument
    }));

    console.log(formData, "hamza");
    // setSingleRoleData(permissionsObjectForAddRole);
    setRoleModal(true);
  };
  // React.useEffect(() => {
  //   console.log(formData, "updated form data");
  // }, [formData]);
  useEffect(() => {
    if (formData?.role_parentId && formData?.parent_role_name) {
      setSingleRoleData({
        ...formData, // Pass the updated formData
        permissions: permissionsObjectForAddRole, // Add the permissions object as part of the data
      });
    }
    console.log(formData?.parent_role_name, "updated form data");
  }, [formData]);
  const setAssignUsers = (event, _id) => {
    setAssignModal(event.currentTarget);
    rolesServices.fetchUsersByRole(_id).then((response) => {
      if (response.data.success === 2) {
        // invalidToken(response.data.message)
      } else {
        setAssignUsersRole(response.data.assignUsers);
        // this.setState({
        //   assignUsers: response.data.assignUsers,
        //   assignUsersLoading: false,
        // });
      }
    });
  };

  // const handleClickAddRole = (value, id) => {
  //   setLoading(true)
  //   var data = {
  //     role_id: id,
  //     level: value?.level + 1,
  //     parentId: value?._id,
  //   };
  //   hierarachyServices.addHeirarachyChart(data).then((response) => {
  //     if (response.data.success === 2) {
  //       invalidToken(response.data.message);
  //     } else {
  //       alert.success(response.data.message);
  //       fetchHeirarachyChartData();
  //       setLoading(false)
  //     }
  //   });
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "account",
    drop: () => ({ name: org?.roleData?.role_name }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = canDrop && isOver;
  let backgroundColor = "white";
  if (isActive) {
    backgroundColor = "#ddffd2";
  } else if (canDrop) {
    backgroundColor = "#ffeedc";
  }

  const open = Boolean(assignModal);
  const id = open ? "simple-popover" : undefined;

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleDeleteRole = () => {
    setDeleteModal(true);
  };

  // const handleEditRole = (id,name) => {
  //     console.log(name,id,"handleClickrole")
  //   // setLoading(true)
  //   hierarachyServices.fetchRoleData(id).then((response) => {
  //     if (response.data.success === 2) {
  //       invalidToken(response.data.message);
  //     } else {
  //       setRoleName(true)
  //       setRolesDetailsById(response.data.result)
  //       // alert.success(response.data.message);
  //       setIsEdit(true)
  //       // setLoading(false)
  //       setRoleModal(true);
  //     }
  //   });
  // }

  const handleEditRole = async (id, name, discritpion) => {
    // setLoading(true); // Loading state for UI feedback
    try {
      const response = await rolesServices.fetchRolePermissions(id); // Fetch role permissions
      console.log("Role Permissions Response:", response);

      if (response.status === 200 && response.data) {
        setSingleRoleData(response.data.result);
        console.log(response.data.result, "response.data.result");
        // Set fetched data to state
        setIsEdit(true); // Enable edit mode
        setRoleModal(true); // Open role modal
      } else {
        alert.error("Failed to fetch role details.");
      }
    } catch (error) {
      console.error("Error fetching role details:", error);
      alert.error("An error occurred while fetching role details.");
    }
  };

  //   const handleEditRole = async (id) => {
  //     // setLoading(true)
  //     try {
  //       const response = await rolesServices.fetchRolePermissions(id);
  //       console.log("Roles Permissions: ", response);
  //       if (response.status === 200 && response.data) {
  //         setSingleRoleData(response.data.result);
  //   setRoleModal(true);
  // } else {
  //         alert.error("Failed to fetch role details.");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching role details:", error);
  //       alert.error("An error occurred while fetching role details.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  const onHandleDeleteClick = (id) => {
    setLoading(true);
    hierarachyServices.deleteHeirarachyChart(id).then((response) => {
      if (response.data.success === 2) {
        setLoading(false);
        invalidToken(response.data.message);
      } else if (response.data.success == 0) {
        setLoading(false);
        alert.error(response.data.message);
      } else {
        alert.success(response.data.message);
        fetchHeirarachyChartData();
        setLoading(false);
      }
    });
  };

  const handleCloseDeleteRole = () => {
    setDeleteModal(false);
  };

  const filteredUsers = assignusers?.filter((role) =>
    role.user_name.toLowerCase().includes(searchValue.toLowerCase())
  );
  const fetchRoles = async () => {
    setLoading(true);
    try {
      const data = {
        pageNumber: page + 1,
        name: searchTerm,
        sortBy: orderBy,
        sortDirection: orderDirection,
      };
      const response = await rolesServices.fetchData(data);
      setRoleName(response.data.result || []);
      setTotalRolesCount(response.data.totalCount || 0);
    } catch (error) {
      console.error("Error fetching roles:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddOrEditModalSubmission = async () => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    try {
      if (isEdit) {
        const response = await rolesServices.editData(singleRoleData);
        if (response.data.success !== 2) {
          setRoleModal(false);
          alert.success(response.data.message);
          fetchHeirarachyChartData();
          fetchRoles();
          setRoleName([]);
        } else {
          alert.error(response?.data?.message || "Failed to update the role.");
        }
      } else {
        const response = await rolesServices.addData(singleRoleData);
        if (response.data.success !== 2) {
          alert.success(response.data.message);
          setRoleModal(false);
          fetchHeirarachyChartData();
          fetchRoles();
          setRoleName([]);
        } else {
          alert.error(response?.data?.message || "Failed to add the role.");
        }
      }
    } catch (error) {
      console.error("Error submitting role data:", error);

      if (error.response) {
        alert.error(
          error.response.data?.message || "An error occurred on the server."
        );
      } else if (error.request) {
        alert.error("No response from the server. Please try again later.");
      } else {
        alert.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <div>
      {/* <ThemeProvider theme={theme}> */}
      <Card
        variant="outlined"
        className={classes.root}
        ref={drop}
        aria-describedby={id}
        // style={{ backgroundColor }}
        style={{
          background: "white",
          display: "inline-block",
          borderRadius: 16,
        }}
      >
        <CardHeader
          title={
            <Typography variant="h6" style={{ fontSize: "15px" }}>
              {org?.roleData?.role_name}
            </Typography>
          }
          action={
            <div style={{ position: "relative" }}>
              <IconButton
                size="small"
                onClick={handleClick}
                style={{ position: "absolute", top: -4, right: -5 }}
              >
                <MoreVertIcon
                  style={{ color: ovHrV5Color.main, fontSize: "20px" }}
                />
              </IconButton>
              <IconButton
                disabled={org?.totalUsers === 0 ? true : false}
                style={{ bottom: -22, right: 0 }}
                onClick={(e) => setAssignUsers(e, org?.roleData?._id)}
              >
                <PermIdentityIcon
                  style={{ color: ovHrV5Color.main, fontSize: "20px" }}
                />
              </IconButton>
              <span
                disabled={org?.totalUsers === 0 ? true : false}
                onClick={(e) => setAssignUsers(e, org?.roleData?._id)}
                style={{
                  position: "absolute",
                  bottom: -12,
                  cursor: "pointer",
                  right: 0,
                  color: ovHrV5Color.main,
                  fontSize: "14px",
                }}
              >
                {org?.totalUsers}
              </span>
            </div>
          }
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          // onClick={handleClickrole}
          // onClick={()=>handleAddRoleClick(org?.roleData?._id)}
          // onClick={()=>handleAddRoleClick(org?.roleData?._id)}
          onClick={() =>
            handleClickrole(org?.roleData?._id, org?.roleData?.role_name)
          }
        >
          <IconButton
            style={{ position: "absolute", marginTop: "-23px", zIndex: "20" }}
          >
            <AddCircleIcon style={{ color: ovHrV5Color.main }} />
          </IconButton>
        </div>
        <DeleteModal
          bgColor={ovHrV5Color.main}
          desc="Are you sure you want to delete?"
          open={deleteModal}
          onClose={handleCloseDeleteRole}
          handleDelete={() =>
            onHandleDeleteClick(org?.roleData?._id, org?.roleData?.role_name)
          }
        />

        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          PaperProps={{
            style: {
              backgroundColor: "white",
            },
          }}
          onClose={handleClose}
        >
          <MenuItem
            disabled={
              org?.roleData?._id === "626f86ea361fc13610d3a503" ? true : false
            }
            // onClick={() => handleEditRole(org?.roleData?._id, org?.roleData?.role_name, org?.roleData?.role_description)}>
            onClick={() =>
              handleEditRole(org?.roleData?._id, org?.roleData?.role_name)
            }
          >
            <ListItemText primary="Edit role" />
          </MenuItem>
          <MenuItem
            disabled={
              org?.roleData?._id === "626f86ea361fc13610d3a503" ? true : false
            }
            onClick={handleDeleteRole}
          >
            <ListItemText primary="Delete role" />
          </MenuItem>
        </Menu>
      </Card>
      {/* </ThemeProvider> */}

      <MyLeft50Modal
        bgColor={ovHrV5Color.main}
        title={isEdit ? "Edit Roles" : "Add Roles"}
        open={roleModal}
        onClose={handleCloserole}
        handleSubmit={handleAddOrEditModalSubmission}
        isSubmitting={isSubmitting}
      >
        <RoleFormContent
          roleData={singleRoleData}
          isEdit={isEdit}
          onRoleDataChange={(updatedData) => setSingleRoleData(updatedData)}
        />
      </MyLeft50Modal>
      {/* {roleModal &&
        <AddEditRoles
          roleId={roleId}
          permissionsData={permissionsData}
          roleName={roleName}
          roleModal={roleModal}
          roleData={rolesDetailsById}
          fetchHeirarachyChartData={fetchHeirarachyChartData}
          handleCloserole={handleCloserole}
          isEdit={isEdit}
        />} */}

      <Popover
        id={id}
        open={open}
        anchorEl={assignModal}
        onClose={handleCloseassignUsermodal}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            width: "16%",
            maxWidth: "unset",
            maxHeight: "50%",
            height: "max-content",
            background: "white",
          },
        }}
      >
        <div style={{ padding: "10px" }}>
          <TextField
            type="text"
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Search"
            style={{
              borderRadius: "30px",
              padding: "6px",
              borderColor: "ovHrV5Color.main",
              border: `1px solid ${ovHrV5Color.main}`,
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: ovHrV5Color.main }} />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: false, // Ensure that label doesn't shrink
            }}
            fullWidth
          />
          {assignusers.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "90vh",
                marginTop: "-190px",
                backgroundColor: "transparent",
              }}
            >
              <LoadingHR />
            </div>
          ) : (
            filteredUsers?.map((role) => (
              <MenuItem
                key={role._id}
                onClick={() => history.push(`/hr/users?uid=${role._id}`)}
              >
                <ListItemText
                  primary={
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {role.user_name}
                    </div>
                  }
                />
              </MenuItem>
            ))
          )}
        </div>
      </Popover>
    </div>
  );
}

function Node({
  o,
  fetchHeirarachyChartData,
  fetchRoleData,
  permissionsData,
  setLoading,
  parent,
}) {
  const T = parent
    ? TreeNode
    : (props) => (
        <Tree
          {...props}
          lineWidth={"3px"}
          lineColor={"#007E63"}
          lineBorderRadius={"4px"}
        >
          {props.children}
        </Tree>
      );

  return (
    <T
      label={
        <Organization
          org={o}
          permissionsData={permissionsData}
          setLoading={setLoading}
          fetchRoleData={fetchRoleData}
          fetchHeirarachyChartData={fetchHeirarachyChartData}
          // No need to pass onCollapse and collapsed props
        />
      }
    >
      {/* You may need to uncomment and adjust this part if needed */}

      {/* {_.map(o.account, (a) => (
        <TreeNode key={a.id} label={<Account a={a} />}>
          <TreeNode label={<Product p={a.product} />} />
        </TreeNode>
      ))} */}
      {_.map(o?.roleData_Children, (c) => (
        <Node
          setLoading={setLoading}
          permissionsData={permissionsData}
          fetchRoleData={fetchRoleData}
          fetchHeirarachyChartData={fetchHeirarachyChartData}
          key={c?.id}
          o={c}
          parent={o}
        />
      ))}
    </T>
  );
}

const theme = createMuiTheme({
  palette: {
    background: "#ECECF4",
  },
  fontFamily: "proxima_nova_rgregular",
});

function App(props) {
  const {
    fetchHeirarachyChartData,
    fetchRoleData,
    heirarachyData,
    permissionsData,
    isLoading,
  } = props;
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (permissionsData.hr_view_hierarchy === 1) {
      fetchHeirarachyChartData();
    }
  }, [permissionsData.hr_view_hierarchy === 1]);

  if (permissionsData.hr_view_hierarchy) {
    if (isLoading || loading) {
      return <LoadingDataHR />;
    }
  }

  return permissionsData.hr_view_hierarchy ? (
    // <ThemeProvider theme={theme}>
    <Box
      padding={4}
      style={{
        overflowX: "scroll",
        overflowY: "scroll",
        position: "relative",
        minHeight: "93vh",
      }}
    >
      {/* <DndProvider backend={HTML5Backend}> */}
      <Node
        o={heirarachyData ? heirarachyData[0] : ""}
        fetchRoleData={fetchRoleData}
        permissionsData={permissionsData}
        setLoading={setLoading}
        fetchHeirarachyChartData={fetchHeirarachyChartData}
      />
      {/* </DndProvider> */}
    </Box>
  ) : (
    //  </ThemeProvider>
    <Grid container spacing={2}>
      <Grid item md={12}>
        <NoPermission />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  heirarachyData: state.HierarachyChart.heirarachyData,
  isLoading: state.HierarachyChart.isLoading,
});

const ConnectWith = connect(mapStateToProps, {
  fetchHeirarachyChartData,
  fetchRoleData,
  addHeirarachyChart,
  deleteHeirarachyChart,
})(App);
export default withDragDropContext(ConnectWith);
