export const tabsLists = [
  {
    title: "Information",
  },
  {
    title: "Locations",
  },
  {
    title: "Documents",
  },
  // {
  //   title: "Auto-Schedule Rules",
  // },
  // {
  //   title: "Time Off",
  // },
  // {
  //   title: "Notes",
  // },
  // {
  //   title: "Notifications",
  // },
];

export const managerNotificationsList = [
  {
    title: "Late employees clockin",
    buttons: [
      {
        isEmail: true,
        isDisabled: false,
        name: "late_emp_email",
      },
      {
        isEmail: false,
        isDisabled: false,
        name: "late_emp_push",
      },
    ],
  },
  {
    title: "Overtime notifications",
    buttons: [
      {
        isEmail: true,
        isDisabled: false,
        name: "overtime_email",
      },
      {
        isEmail: false,
        isDisabled: false,
        name: "overtime_push",
      },
    ],
  },
  {
    title: "Employee trade, cover, open shift, and time off requests",
    buttons: [
      {
        isEmail: true,
        isDisabled: false,
        name: "trade_cover_open_time_email",
      },
      {
        isEmail: false,
        isDisabled: false,
        name: "trade_cover_open_time_push",
      },
    ],
  },

  {
    title: "Employee availability requests",
    buttons: [
      {
        isEmail: true,
        isDisabled: false,
        name: "emp_availability_req_email",
      },
      {
        isEmail: false,
        isDisabled: false,
        name: "emp_availability_req_push",
      },
    ],
  },

  {
    title: "Unpublished shift reminders",
    buttons: [
      {
        isEmail: true,
        isDisabled: false,
        name: "unpub_shift_rem_email",
      },
      {
        isEmail: false,
        isDisabled: false,
        name: "unpub_shift_rem_push",
      },
    ],
  },

  {
    title: "Daily Email summaries",
    buttons: [
      {
        isEmail: true,
        isDisabled: false,
        name: "daily_summary_email",
      },
      {
        isEmail: false,
        isDisabled: true,
        name: "",
      },
    ],
  },
  {
    title: "Weekly Email summaries",
    buttons: [
      {
        isEmail: true,
        isDisabled: false,
        name: "weekly_summary_email",
      },
      {
        isEmail: false,
        isDisabled: true,
        name: "",
      },
    ],
  },
];

export const notificationsList = [
  {
    title: "Schedule publication and updates",
    buttons: [
      {
        isEmail: true,
        isDisabled: false,
        name: "schedule_publish_email",
      },
      {
        isEmail: false,
        isDisabled: false,
        name: "schedule_publish_push",
      },
    ],
  },
  {
    title: "Shift trade and cover request updates",
    buttons: [
      {
        isEmail: true,
        isDisabled: false,
        name: "trade_cover_email",
      },
      {
        isEmail: false,
        isDisabled: false,
        name: "trade_cover_push",
      },
    ],
  },
  {
    title: "Time off request responses",
    buttons: [
      {
        isEmail: true,
        isDisabled: false,
        name: "timeoff_email",
      },
      {
        isEmail: false,
        isDisabled: false,
        name: "timeoff_push",
      },
    ],
  },
  {
    title: "Open shift claim responses",
    buttons: [
      {
        isEmail: true,
        isDisabled: false,
        name: "open_shift_email",
      },
      {
        isEmail: false,
        isDisabled: false,
        name: "open_shift_push",
      },
    ],
  },
  {
    title: "Send me a reminder before my shift start",
    buttons: [
      {
        isEmail: true,
        isDisabled: false,
        name: "my_shift_start_email",
      },
      {
        isEmail: false,
        isDisabled: false,
        name: "my_shift_start_push",
      },
    ],
  },
  {
    title: "Clock in/out confirmation",
    buttons: [
      {
        isEmail: true,
        isDisabled: true,
        name: "",
      },
      {
        isEmail: false,
        isDisabled: false,
        name: "clockin_clockout_push",
      },
    ],
  },
  {
    title: "Break end confirmation",
    buttons: [
      {
        isEmail: true,
        isDisabled: true,
        name: "",
      },
      {
        isEmail: false,
        isDisabled: false,
        name: "break_end_push",
      },
    ],
  },
  {
    title: "Auto Clock out confirmation",
    buttons: [
      {
        isEmail: true,
        isDisabled: true,
        name: "",
      },
      {
        isEmail: false,
        isDisabled: false,
        name: "auto_clock_push",
      },
    ],
  },
];
