import React, { useEffect, useState } from "react";
import {
  Avatar,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  EmailOutlined as ResendIcon,
  Check as ActiveIcon,
  Close as DeactiveIcon,
  ExitToApp as LoginAsIcon,
} from "@material-ui/icons";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { usersServices } from "../../UsersList/modules/services";
import { Confirmation, MoreMenu, MySelectField } from "../../../../components";
import { Dropdown, OutlinedButton } from "../../../../utilities/v5/common";
import { ovHrV5Color } from "../../../../config";
import { MdOutlineAddCircle } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import {
  headCells,
  hrNewUserObjectValues,
  hrUsersMoreMenuList,
  themeColor,
} from "./constants";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { alert, utcToLocal } from "../../../../utilities";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import ExportCsv from "../../UsersList/ExportCsv";
import Cookies from "universal-cookie";
import { loginAsUserAction } from "../../UsersList/modules/actions";
import { history } from "../../../../history";
import { useLocation } from "react-router-dom";
import EditUser from "../EditUser/EditUser";
import "./index.css";
import { makeStyles } from "@material-ui/core/styles";
import AddUserModal from "../AddUserModal";
import { editUserServices } from "../EditUser/modules/services";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    height: 48,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const useStyles = makeStyles((theme) => ({
  hrUsersContainer: {
    display: "flex !important",
    flexDirection: "column !important",
    height: "100% !important",
    width: "100% !important",
    padding: "2rem !important",
    gap: "2rem !important",
    maxWidth: "100% !important",
  },

  hrUsersHeading: {
    fontFamily: "Roboto, sans-serif !important",
    fontSize: "36px !important",
    fontWeight: "700 !important",
    color: ovHrV5Color.main,
    lineHeight: "46.25px !important",
  },

  hrUserFiltersRow: {
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "space-between !important",
    gap: "1rem !important",
  },

  hrUsersSelectField: {
    width: "100% !important",
  },

  hrUserField: {
    width: "20% !important",
  },
  hrUserFields: {
    width: "50% !important",
  },

  hrUserButtonRow: {
    height: "5rem !important",
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    padding: "0 2rem 0 2rem !important",
  },

  hrUsersButtonsRow: {
    display: "flex !important",
    flexDirection: "row !important",
    gap: "1rem !important",
  },

  hrUserTableHeading: {
    fontSize: "26px !important",
    fontWeight: "700 !important",
    fontFamily: "Roboto, sans-serif !important",
    colro: "black !important",
  },

  hrUserTableContainer: {
    height: "100% !important",
    width: "100% !important",
  },

  hrUserTableHead: {
    backgroundColor: ovHrV5Color.main,
  },

  hrUserTableHeadText: {
    color: "white !important",
    fontSize: "16px !important",
  },

  hrUserViewLogIcon: {
    color: ovHrV5Color.main,
    cursor: "pointer !important",
    width: "25px !important",
    height: "25px !important",
  },

  hrUserNameCell: {
    display: "flex !important",
    flexDirection: "row !important",
    alignItems: "center !important",
    gap: "1rem !important",
    // width:"90px !important"
  },

  hrUserAvatar: {
    float: "left !important",
    textTransform: "uppercase !important",
    height: "40px !important",
    width: "40px !important",
    fontWeight: "600 !important",
    fontSize: "15px !important",
    backgroundColor: ovHrV5Color.main,
    color: "white !important",
  },

  hrUserNameContainer: {
    display: "flex !important",
    flexDirection: "column !important",
  },

  hrUserName: {
    maxWidth: "170px !important",
    lineHeight: "15px !important",
    display: "-webkit-box !important",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "600 !important",
    fontSize: "14px !important",
  },

  hrUserRoleAndEmail: {
    maxWidth: "170px !important",
    lineHeight: "15px !important",
    display: "-webkit-box !important",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
  },

  input: {
    fontSize: "16px",
    fontWeight: 500,
    backgroundColor: "#ffffff !important",
    borderRadius: 5,
    marginBottom: 0,
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "lightgray !important",
      },
    },
  },
  labelColor: {
    color: "gray",
  },
}));

const cookies = new Cookies();
var ovUid = cookies.get("ovUid");

const Users = ({ permissionsData }) => {
  const classes = useStyles();
  const [locationData, setLocationData] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [rolesData, setRolesData] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [userFilter, setUserFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("user_name");
  const [searchQuery, setSearchQuery] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [showLogInModal, setShowLogInModal] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [isOnEditClick, setIsOnEditClick] = useState(false);
  const [clickedUserId, setClickedUserId] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [resendData, setResendData] = useState({});
  const [resendModal, setResendModal] = useState(false);
  const [deactiveData, setDeactiveData] = useState({});
  const [deactiveModal, setDeactiveModal] = useState(false);
  const [addedUserId, setAddedUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [addModalValues, setAddModalValues] = useState(hrNewUserObjectValues);
  const [locationDataWithPayroll, setLocationDataWithPayroll] = useState([]);
  const options = [
    { value: "all", label: "All Users" },
    { value: "1", label: "Enable Users" },
    { value: "0", label: "Disabled Users" },
  ];
  const location = useLocation();
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [locationComponentValue, setLocationComponentValue] =
    useState("select");
  const [changeStatusUserId, setChangeStatusUserId] = useState("");
  const [changeStatusUserName, setChangeStatusUserName] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const handleFetchUsers = (searchQuery = "") => {
    console.log("User Status Filter Value: ", userFilter);
    setLoading(true);
    const requestData = {
      role_id: roleId,
      lid: locationId,
      user_status: userFilter === "all" ? "" : userFilter,
      order: orderDirection,
      sortBy: orderBy,
      name: searchQuery.trim(),
    };
    if (searchQuery.trim() === "") {
      requestData.pageNumber = page + 1;
      requestData.nPerPage = rowsPerPage;
    }

    usersServices
      .fetchData(requestData)
      .then((response) => {
        setUsers(response.data.result || []);
        setTotalUsers(response.data.totalCount || 0);
        console.log("Users Data:", response);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (debouncedSearchQuery.trim() === "") {
      handleFetchUsers();
    } else {
      handleFetchUsers(debouncedSearchQuery);
    }
  }, [
    page,
    rowsPerPage,
    roleId,
    locationId,
    userFilter,
    orderDirection,
    orderBy,
    debouncedSearchQuery,
  ]);

  useEffect(() => {
    fetchRolesAndLocations();
    console.log("permissionsData: ", permissionsData);
  }, []);

  useEffect(() => {
    getPageDetails();
  }, [location]);

  const fetchRolesAndLocations = () => {
    usersServices.fetchDropDownRolesData().then((response) => {
      setRolesData(response.data.result || []);
    });
    usersServices.fetchDropDownLocationsData().then((response) => {
      setLocationData(response.data.result || []);
    });
  };

  const fetchLocationsWithPayrolls = () => {
    usersServices.fetchLocationsWithPayrolls().then((response) => {
      console.log("Locations Data with Payrolls: ", response.data.result);
      setLocationDataWithPayroll(response.data.result || []);
    });
  };

  useEffect(() => {
    fetchLocationsWithPayrolls();
    console.log("locationDataWithPayroll: ", locationDataWithPayroll);
  }, []);

  console.log(roleId, "roleId");

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const loginAsUserConfirmation = (user) => {
    const userName = user.user_name;
    const loginData = {
      user_email: user.user_email,
    };
    setUserName(userName);
    setLoginData(loginData);
    setShowLogInModal(true);
  };

  const handleLoginAsSubmission = () => {
    loginAsUserAction(loginData);
  };

  const handleAddUserModal = () => {
    setAddModalValues(hrNewUserObjectValues);
    setShowAddModal(true);
  };

  const handleChangePayroll = (e, index) => {
    locationDataWithPayroll[index].locationsPayroll = locationDataWithPayroll[
      index
    ].locationsPayroll
      ? locationDataWithPayroll[index].locationsPayroll
      : "";
    locationDataWithPayroll[index].payroll_id = e.target.value;
    setLocationComponentValue(e.target.value);
  };

  const handleAddModalSubmission = async (formData, locationsPayload) => {
    await usersServices.addData(formData).then((response) => {
      console.log("Add User Response: ", response);
      if (response.data.status === false) {
        alert.error(response.data.message);
        console.log("Error adding user:", response);
      } else if (response.data.success === 0) {
        alert.error(response.data.message);
        console.log("Error adding user:", response);
      } else {
        console.log("User added successfully: ", response);
        setAddedUserId(response.data.result._id);
        console.log("Added User ID: ", response.data.result._id);
        var datawithoutPayrollId = [];
        locationsPayload.map((dxd) => {
          if (dxd.payroll_id === "" && dxd.status === 1) {
            datawithoutPayrollId.push(dxd);
          }
        });
        if (datawithoutPayrollId.length > 0) {
          alert.error(
            `please select Payroll rule for ${datawithoutPayrollId[0].location_name}`
          );
          setLoading(false);
        } else {
          let data = {
            uid: response.data.result._id,
            locationsData: locationsPayload,
          };
          editUserServices.updateLocationsData(data).then((response) => {
            if (response.data.success === 2) {
            } else {
              alert.success(response.data.message);
              setLoading(false);
            }
          });
        }
        alert.success(response.data.message);
        handleFetchUsers();
      }
    });
  };

  const handleResendModalOpen = (user) => {
    setResendData((prevObject) => ({
      ...prevObject,
      _id: user._id,
      user_name: user.user_name,
      user_email: user.user_email,
    }));
    setResendModal(true);
  };

  const handleDeactiveUserModalOpen = (row) => {
    setChangeStatusUserId(row._id);
    setChangeStatusUserName(row.user_name);
    setDeactiveData((prevObject) => ({
      ...prevObject,
      user_status: row.user_status === 1 ? 2 : 1,
    }));
    setDeactiveModal(true);
  };

  const handleResendInvitationSubmission = () => {
    usersServices.resendInvitation(resendData).then((response) => {
      if (response.data.success === 2) {
        alert.error(
          "Error in sending Invitation to the User: ",
          response.data.message
        );
        console.log(
          "Error in sending Invitation to the User: ",
          response.data.message
        );
      } else {
        alert.success(response.data.message);
      }
    });
    setResendModal(false);
  };

  const handleToggleActiveDeactiveUser = async () => {
    await usersServices
      .updateUserStatus(deactiveData, changeStatusUserId)
      .then((response) => {
        if (response.data.success === 2) {
          alert.error(response.data.message);
        } else {
          alert.success(response.data.message);
          setDeactiveModal(false);
          handleFetchUsers();
        }
      });
  };

  const handleClickMenuItem = (action, id) => {
    if (action === "edit") {
      setIsOnEditClick(true);
      history.push(`/hr/users?uid=` + id + ``);
    }
  };

  const getPageDetails = () => {
    const query = new URLSearchParams(location.search);
    console.log("Query: ", query);
    const userId = query.get("uid");
    console.log("userId: ", userId);
    setClickedUserId(userId);
    setIsOnEditClick(true);
  };

  const handleTeamRoasterBack = () => {
    setIsOnEditClick(false);
    history.push(`/hr/users`);
  };

  const handleNavigationToUserDetails = (id) => {
    window.open(`/hr/users?uid=` + id + ``);
  };

  
  return isOnEditClick && clickedUserId ? (
    <EditUser
      permissionsData={permissionsData}
      pageNo={page}
      rowsPerPage={rowsPerPage}
      backToUsers={handleTeamRoasterBack}
      userId={clickedUserId}
    />
  ) : (
    <div className="hr-users-container">
      <Typography className={classes.hrUsersHeading}>Users</Typography>

      <Paper>
        <TableContainer>
          <div className={classes.hrUserButtonRow}>
            <Typography className={classes.hrUserTableHeading}>
              {Math.min((page + 1) * rowsPerPage, totalUsers)} / {totalUsers}{" "}
              Users
            </Typography>

            <div className={classes.hrUsersButtonsRow}>
              <ExportCsv />
              <OutlinedButton
                disabled={!permissionsData.hr_add_user}
                text={"Add New"}
                onClick={handleAddUserModal}
                borderColor={ovHrV5Color.main}
                textColor={ovHrV5Color.main}
                icon={
                  <MdOutlineAddCircle className={classes.hrUserViewLogIcon} />
                }
              />
            </div>
          </div>
          <Table className={classes.hrUserTableContainer} size="medium">
            <TableHead className={classes.hrUserTableHead}>
              <TableRow>
                {headCells.map((headCell) => (
                  <StyledTableCell align={headCell.align} key={headCell.id}>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={
                        orderBy === headCell.id ? orderDirection : "asc"
                      }
                      onClick={() => handleSortRequest(headCell.id)}
                    >
                      <Typography className={classes.hrUserTableHeadText}>
                        {headCell.label}
                      </Typography>
                    </TableSortLabel>
                    {headCell.id === "user_name" && (
                      <div>
                        <TextField
                          fullWidth
                          className={classes.hrTextFieldRole}
                          variant="outlined"
                          placeholder="Search name or email"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          inputProps={{
                            style: {
                              height: "40px",
                              padding: "0 14px",
                              border: "none",
                              backgroundColor: "#fff",
                              borderRadius: "3px !important",
                            },
                          }}
                          sx={{
                            width: "100% !important",
                            backgroundColor: "#fff !important",
                            borderRadius: "6px !important",
                          }}
                        />
                      </div>
                    )}

                    {headCell.id === "role_name" && (
                      <div>
                        <TextField
                          select
                          fullWidth
                          label=""
                          name="select_role"
                          onChange={(e) => setRoleId(e.target.value)}
                          value={roleId}
                          placeholder="Select role"
                          className={classes.textField}
                          inputProps={{
                            className: classes.input,
                          }}
                          variant="outlined"
                          size="small"
                        >
                          <MenuItem value="" style={{ cursor: "pointer" }}>
                            All Roles
                          </MenuItem>
                          {rolesData.map((item) => (
                            <MenuItem
                              key={item._id}
                              value={item._id}
                              style={{ cursor: "pointer" }}
                            >
                              {item.role_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    )}

                    {headCell.id === "user_location" && (
                      <div>
                        <TextField
                          select
                          fullWidth
                          label=""
                          name="select_location"
                          onChange={(e) => setLocationId(e.target.value)}
                          value={locationId}
                          placeholder="Select location"
                          inputProps={{
                            className: classes.input,
                          }}
                          variant="outlined"
                          size="small"
                        >
                          <MenuItem value="" style={{ cursor: "pointer" }}>
                            All Locations
                          </MenuItem>
                          {locationData.map((item) => (
                            <MenuItem
                              key={item._id}
                              value={item._id}
                              style={{ cursor: "pointer" }}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    )}

                    {headCell.id === "user_status" && (
                      <div className={classes.hrUserField}>
                        <TextField
                          select
                          fullWidth
                          label=""
                          name="disableStatus"
                          onChange={(e) => setUserFilter(e.target.value)}
                          value={userFilter}
                          placeholder="Select status"
                          inputProps={{
                            className: classes.input,
                          }}
                          variant="outlined"
                          size="small"
                          // sx={{ width: "25% !important" }}
                        >
                          <MenuItem value="all">All Users</MenuItem>
                          <MenuItem value="1">Enable Users</MenuItem>
                          <MenuItem value="2">Disabled Users</MenuItem>
                        </TextField>
                      </div>
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array?.from({ length: 10 }).map((item, index) => (
                    <TableRow key={index}>
                      {headCells.map((cell) => (
                        <TableCell key={cell.id}>
                          <Skeleton height={12} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : users.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          pointerEvents: permissionsData.hr_edit_user
                            ? "initial"
                            : "none",
                          cursor: "pointer",
                        }}
                        onClick={() => handleNavigationToUserDetails(user._id)}
                      >
                        <div className={classes.hrUserNameCell}>
                          {user.user_image ? (
                            <Avatar
                              src={user.user_image}
                              className={classes.hrUserAvatar}
                            />
                          ) : (
                            <Avatar className={classes.hrUserAvatar}>
                              {user.user_name.charAt(0)}
                            </Avatar>
                          )}
                          <div className={classes.hrUserNameContainer}>
                            <Typography className={classes.hrUserName}>
                              {user.user_name}
                            </Typography>

                            <Typography className={classes.hrUserRoleAndEmail}>
                              {user.user_email}
                            </Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>{user.role_name}</TableCell>
                      <TableCell>{user.assignLocations_count}</TableCell>
                      <TableCell>
                        {user.user_device_ios === 1
                          ? user.appversion === ""
                            ? ""
                            : user.appversion + " (Android) "
                          : user.appversion === ""
                          ? " "
                          : user.appversion + " (ios) "}
                      </TableCell>
                      {/* <TableCell>
                      {moment(
                        utcToLocal(user.last_login_date).locale("en-US")
                      ).format("DD MMM YYYY,hh:mm A")}
                    </TableCell> */}
                      <TableCell>
                        {user.last_login_date
                          ? moment(
                              utcToLocal(user.last_login_date).locale("en-US")
                            ).format("DD MMM YYYY,hh:mm A")
                          : "N/A"}
                      </TableCell>

                      <TableCell>
                        {permissionsData?.hr_edit_user &&
                        user.user_role !== "626f86ea361fc13610d3a503" ? (
                          user.user_status === 0 ? (
                            <Tooltip
                              title={
                                "Last invitation date  " +
                                moment(utcToLocal(user.invitation_date))
                                  .locale("en-US")
                                  .format("DD MMM,hh:mm A")
                              }
                              arrow
                            >
                              <IconButton
                                onClick={() => handleResendModalOpen(user)}
                              >
                                <ResendIcon
                                  style={{ color: ovHrV5Color.main }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={
                                user.user_status === 1
                                  ? "Active User"
                                  : user.user_status === 2
                                  ? "Deactive User"
                                  : "Not Verified"
                              }
                              arrow
                            >
                              <IconButton
                                disabled={
                                  permissionsData.common_users_active
                                    ? false
                                    : true
                                }
                                onClick={() => {
                                  handleDeactiveUserModalOpen(user);
                                }}
                              >
                                {user.user_status === 1 ? (
                                  <ActiveIcon style={{ color: "green" }} />
                                ) : (
                                  user.user_status === 2 && (
                                    <DeactiveIcon style={{ color: "red" }} />
                                  )
                                )}
                              </IconButton>
                            </Tooltip>
                          )
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Login as" arrow>
                          <IconButton
                            // disabled={
                            //   ovUid !== user._id.toString() &&
                            //     permissionsData.common_users_loginas
                            //     ? false
                            //     : true
                            // }
                            onClick={() => loginAsUserConfirmation(user)}
                          >
                            <LoginAsIcon style={{ color: ovHrV5Color.main }} />
                          </IconButton>
                        </Tooltip>

                        <MoreMenu
                          product={"HR"}
                          moreMenuClick={() => {}}
                          moreData={hrUsersMoreMenuList(
                            permissionsData,
                            user._id
                          ).map((item) => ({
                            ...item,
                            onClick: () =>
                              permissionsData.hr_edit_user
                                ? handleClickMenuItem(
                                    item.sortName,
                                    user._id,
                                    user
                                  )
                                : null, // Prevent click if no permission
                            disabled: !permissionsData.hr_edit_user, // Disable the button if no permission
                          }))}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && users.length > 0 && (
          <TablePagination
            component="div"
            count={totalUsers}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}
      </Paper>

      <AddUserModal
        locationsData={locationDataWithPayroll}
        rolesData={rolesData}
        onOpenClick={showAddModal}
        onCloseClick={() => setShowAddModal(false)}
        onSubmit={handleAddModalSubmission}
        handleChangePayroll={handleChangePayroll}
      />

      <Confirmation
        bgColor={ovHrV5Color.main}
        desc={`Are you sure you want to login as "${userName}" ?`}
        open={showLogInModal}
        onClose={() => setShowLogInModal(false)}
        handleSubmit={handleLoginAsSubmission}
      />

      <Confirmation
        bgColor={ovHrV5Color.main}
        desc={
          `Are you sure you want to ${
            deactiveData?.user_status === 0 || deactiveData?.user_status === 2
              ? "Deactivate"
              : "Activate"
          }` +
          ` "` +
          changeStatusUserName +
          `" ?`
        }
        open={deactiveModal}
        onClose={() => setDeactiveModal(false)}
        handleSubmit={handleToggleActiveDeactiveUser}
      />

      <Confirmation
        bgColor={ovHrV5Color.main}
        desc={
          `Are you sure you want to resend invitation to "` +
          resendData?.user_name +
          `" ?`
        }
        open={resendModal}
        onClose={() => setResendModal(false)}
        handleSubmit={handleResendInvitationSubmission}
      />
    </div>
  );
};

export default Users;
