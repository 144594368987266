//TextField
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TextField from "@mui/material/TextField";
import { ovHrV5Color } from "../config";

const styles = (theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: 500,
  },

  toolbar: {
    backgroundColor: `${ovHrV5Color} !important`, // Change to your desired color
  },
  input: {
    fontSize: "15px",
    fontWeight: 500,
    borderRadius: 5,
    marginBottom: 0,
    padding: "10px 15px",
  },
  labelColor: {
    color: "gray",
  },
  addroles: {
    width: "65%",
    marginTop: "2%",
    marginLeft: "6%",
  },
  addtwo: {
    width: "65%",
    marginTop: "2%",
    marginLeft: "6%",
  },
  addthree: {
    width: "65%",
    marginTop: "2%",
    marginLeft: "6%",
  },
  textField: {
    "&. Mui-focused": {
      borderColor: "#007E63 !important",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused": {
        borderColor: "#007E63 !important", // Change to your desired focus color
      },
    },
    "& .MuiFormLabel-root": {
      color: ovHrV5Color.main, // Change this to your desired label color
    },
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: ovHrV5Color.main,
    },
  },
});

class MyDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.setState({ open: false }); // Close the date picker dropdown on scroll
  };

  handleDatePickerOpen = () => {
    this.setState({ open: true }); // Open the date picker dropdown when it's clicked
  };

  handleDatePickerClose = () => {
    this.setState({ open: false }); // Close the date picker dropdown when it's closed
  };

  render() {
    const { classes, styledemo } = this.props;
    const combinedClassName = `${classes.textField} ${
      styledemo === 1
        ? classes.addroles
        : styledemo === 2
        ? classes.addtwo
        : styledemo === 3
        ? classes.addthree
        : ""
    }`;
    const { open } = this.state;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          fullWidth
          classes={{ toolbar: classes.toolbar }}
          className={this.props.product === "HR" ? combinedClassName : ""}
          disablePast={
            this.props.disablePast === undefined
              ? false
              : this.props.disablePast
          }
          disableFuture={
            this.props.disableFuture === undefined
              ? false
              : this.props.disableFuture
          }
          key={`dateTime-${this.props.timezone}`}
          label={this.props.label}
          open={open}
          onOpen={this.handleDatePickerOpen}
          onClose={this.handleDatePickerClose}
          name={this.props.name}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          value={this.props.value || null}
          disabled={this.props.disabled}
          variant="inline"
          inputVariant="outlined"
          autoOk={true}
          // components={{
          //   OpenPickerIcon: FilterAltIcon
          // }}
          renderInput={(params) => (
            <TextField className={classes.textField} {...params} />
          )}
          sx={{
            "&.MuiPickersToolbar-toolbar": {
              // color: '#ad1457',
              // borderRadius: 2,
              // borderWidth: 1,
              // borderColor: '#e91e63',
              // border: '1px solid',
              background: "green !important",
            },
          }}
          format={"dd MMM yyyy"}
          inputlabelprops={{ classes: { root: classes.labelColor } }}
          inputProps={{
            className: classes.input,
          }}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(MyDatePicker);
