import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { withStyles } from "@material-ui/core/styles";
import { usersServices } from "./modules/services";
import moment from "moment";
import { utcToLocal } from "../../../utilities";
import { ovHrV5Color } from "../../../config";
import { FaFileExport } from "react-icons/fa6";
import { OutlinedButton } from "../../../utilities/v5/common";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  Download: {
    padding: "7px 30px",
    marginRight: "25px",
    backgroundColor: ovHrV5Color.main,
    color: "white",
    "&:hover": {
      backgroundColor: ovHrV5Color.main,
    },
  },
});

const ExportCsv = (props) => {
  const { classes } = props;
  const [userDataExport, setUserDataExport] = useState([]);

  useEffect(() => {
    ShowExportData();
  }, []);

  const ShowExportData = () => {
    usersServices.fetchExpotedUserData().then((response) => {
      if (response.status === 200) {
        console.log("Export User Data: ", response);
        setUserDataExport(response.data.result);
      }
      else{
        console.log("Export User Data Error: ", response);
      }
    });
  };

  var headData = [
    "UserName",
    "Email",
    "User Role",
    "Location",
    "App Version",
    "Last Login",
    "Status",
  ];

  var allData = [];
  allData.push(headData);

  userDataExport &&
    userDataExport?.map((item) => {
      if (item) {
        var rowData = [];
        rowData.push(item.user_name);
        rowData.push(item.user_email);
        rowData.push(item.role_name);
        rowData.push(item.assignLocations_count);
        rowData.push(
          item.user_device_ios == 1 // Check if the user device is iOS
            ? item.appversion === "" // Check if the app version is empty
              ? ""
              : item.appversion + " (Android) " // If app version is not empty, concatenate it with " (Android)"
            : item.appversion === "" // If the user device is not iOS, check if the app version is empty
            ? " "
            : item.appversion + " (ios) " // If app version is not empty, concatenate it with " (iOS)"
        );
        rowData.push(
          item.last_login_date === null
            ? ""
            : moment(utcToLocal(item.last_login_date)).format("DD-MM-YYYY")
        );
        rowData.push(
          item.user_status === 1
            ? "Active"
            : item.user_status === 0
            ? "Not Verified"
            : "Deactivated"
        );
        allData.push(rowData);
      }
    });

  return (
    <CSVLink
      data={allData}
      filename={"userdata.csv"}
      style={{ textDecoration: "none" }}
    >
      <OutlinedButton
        onClick={() => {}}
        disabled={userDataExport.length <= 0 ? true : false}
        text={userDataExport.length <= 0 ? "Loading" : "Export"}
        icon={<FaFileExport />}
        borderColor={ovHrV5Color.main}
        textColor={ovHrV5Color.main}
      />
      {/* <Button className={classes.Download}>Export</Button> */}
    </CSVLink>
  );
};

export default withStyles(styles)(ExportCsv);
