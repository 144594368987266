import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const styles = (theme) => ({
  input: {
    //padding: '5px 14px !important',
    fontSize: "16px",
    fontWeight: 500,
    backgroundColor: "#f4f2f26b !important",
    // backgroundColor:'white',
    borderRadius: 5,
    marginBottom: 0,
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#007E63", // Change to your desired focus color
      },
    },
  },
  labelColor: {
    // color: '#b6b5b5'
    color: "gray",
  },
});

class MySelectField extends Component {
  render() {
    const { classes, children } = this.props;
    return (
      <div>
        <TextField
          select
          SelectProps={{ native: true }}
          required={this.props.required ? this.props.required : false}
          label={this.props.label}
          name={this.props.name}
          className={this.props?.product === "HR" ? classes.textField : ""}
          onChange={this.props.onChange}
          onFocus={this.props.onFocus}
          value={this.props.value}
          error={this.props.error}
          helperText={this.props.helperText}
          placeholder={this.props.placeholder}
          // disabled={this.props.disabled}
          variant="outlined"
          size="small"
          fullWidth
          //   inputlabelprops={{ classes: { root: classes.labelColor } }}
          inputProps={{
            className: classes.input,
            //     classes: { focused: classes.focusedInput },
          }}
        >
          {children}
        </TextField>
      </div>
    );
  }
}

export default withStyles(styles)(MySelectField);
