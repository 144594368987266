import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  NoPermission,
  MoreMenu,
  TableMyPagination,
  TableRecordNotFound,
  LoadingData,
  DeleteModal,
  MyLeftModal,
  MyTextfield,
  MySelectField,
} from "../../../components";
import { stableSort, getComparator } from "../../../utilities/TableFunctions";
import {
  fetchLocationsData,
  fetchAllData,
  addData,
  editData,
  deleteData,
  getBussinessCategories,
  getBussinessTypes,
} from "./modules/actions";
import { connect } from "react-redux";
import { settingColor, superadminColor, ovHrV5Color } from "../../../config";
import moment from "moment";
import { utcToLocal } from "../../../utilities";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import {
  validationIsEmpty,
  validationEmail,
  validationIsMobilePhone,
} from "../../../utilities";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  TextField,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Grid,
  Tooltip,
  TablePagination,
} from "@material-ui/core";
import { LocationServices } from "./modules/services";
import { OutlinedButton } from "../../../utilities/v5/common";
import { MdOutlineAddCircle } from "react-icons/md";
import Skeleton from "react-loading-skeleton";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  hrLocationTitle: {
    fontFamily: "Roboto, sans-serif !important",
    fontSize: "36px !important",
    fontWeight: "700 !important",
    color: ovHrV5Color.main,
    lineHeight: "46.25px !important",
    margin: "2rem",
    marginLeft: "1rem",
  },
  hrUserTableHeading: {
    fontSize: "26px !important",
    fontWeight: "700 !important",
    fontFamily: "Roboto, sans-serif !important",
    colro: "black !important",
  },
  hrLocationTableBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headCell: {
    padding: "0.5rem 0rem",
    color: "#ffffff",
    fontWeight: 500,
    fontSize: "16px",
    textTransform: "capitalize",
  },
  rootToolbar: {
    height: "5rem",
    padding: theme.spacing(0, 2),
  },
  title: {
    flex: "1 1 100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  hrLocationSearchField: {
    width: "20% !important",
    marginTop: "-2rem",
    marginBottom: "1rem",
  },
});

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moreData: [
        {
          icon: EditIcon,
          title: "Edit Location",
          sortName: "edit",
          onClick: this.modifyData,
          permission: props.permissionsData.common_location_edit,
        },
        {
          icon: DeleteIcon,
          title: "Delete Location",
          sortName: "delete",
          onClick: this.modifyData,
          permission: props.permissionsData.common_location_delete,
        },
      ],
      myItem: {},
      order: "asc",
      orderBy: "_id",
      headCells: [
        {
          id: "name",
          label: "name",
          numeric: false,
          align: "left",
        },
        {
          id: "email",
          label: "Email",
          numeric: false,
          align: "left",
        },
        {
          id: "address",
          label: "Address",
          numeric: false,
          align: "left",
        },
        {
          id: "location_mobile_no",
          label: "Mobile",
          numeric: false,
          align: "left",
        },
        {
          id: "location_user",
          label: "Users",
          numeric: false,
          align: "left",
        },
        {
          id: "created_at",
          label: "Created at",
          numeric: false,
          align: "left",
        },
        {
          id: "updated_at",
          label: "Updated at",
          numeric: false,
          align: "left",
        },
      ],
      errors: {},
      errorsHelper: {},
      businessCategoryId: "",
      bussinessTypeId: "",
      rowsPerPage: 10,
      searchQuery: "",
      page: 0,
      loading: false,
      statusOptions: [
        {
          label: "Activate",
          value: true,
        },
        {
          label: "Deactivate",
          value: false,
        },
      ],
    };
  }

  // handleSearch = (event) => {
  //   const searchQuery = event.target.value;
  //   this.setState({ searchQuery, loading: true }, () => {
  //     const requestData = {
  //       currentPage: this.state.page + 1,
  //       nPerPage: this.state.rowsPerPage,
  //       keyword: searchQuery,
  //     };

  //     this.props.fetchLocationsData(requestData).finally(() => {
  //       this.setState({ loading: false });
  //     });
  //   });
  // };

  handleSearch = (event) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery});

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.setState({ loading: true });
      const requestData = {
        currentPage: this.state.page + 1,
        nPerPage: this.state.rowsPerPage,
        keyword: searchQuery,
      };

      this.props.fetchLocationsData(requestData).finally(() => {
        this.setState({ loading: false });
      });
    }, 500);
  };

  componentWillUnmount() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage, loading: true }, () => {
      const requestData = {
        currentPage: this.state.page + 1,
        nPerPage: this.state.rowsPerPage,
      };
      this.props.fetchLocationsData(requestData).finally(() => {
        this.setState({ loading: false });
      });
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      { rowsPerPage: parseInt(event.target.value, 10), page: 0, loading: true },
      () => {
        const requestData = {
          currentPage: this.state.page + 1,
          nPerPage: this.state.rowsPerPage,
        };
        this.props.fetchLocationsData(requestData).finally(() => {
          this.setState({ loading: false });
        });
      }
    );
  };

  componentDidMount = () => {
    // var isReload = this.props.locationData?.length > 0 ? false : true;
    const requestData = {
      currentPage: this.state.page + 1,
      nPerPage: this.state.rowsPerPage,
    };
    this.setState({ loading: true });
    this.props.fetchLocationsData(requestData).finally(() => {
      this.setState({ loading: false });
    });
    this.props.getBussinessCategories();
    this.props.getBussinessTypes();
  };

  componentDidUpdate(prevProps, prevState) {
    const { page, rowsPerPage } = this.state;

    if (page !== prevState.page || rowsPerPage !== prevState.rowsPerPage) {
      const requestData = {
        currentPage: page + 1,
        nPerPage: rowsPerPage,
      };
      this.setState({ loading: true });
      this.props.fetchLocationsData(requestData);
      this.setState({ loading: false });
    }
  }

  createSortHandler = (property) => (event) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  handleSubmit = async () => {
    var userItem = this.state.myItem;
    var valid_name = await validationIsEmpty(userItem.name);
    var valid_email = await validationEmail(userItem.email);
    var valid_address = await validationIsEmpty(userItem.address);
    var valid_location_mobile_no = await validationIsMobilePhone(
      userItem.location_mobile_no
    );

    if (valid_name) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          name: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          name: "Please enter name",
        },
      }));
    }

    if (valid_email) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          email: "Please enter email",
        },
      }));
    }

    if (valid_address) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          address: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          address: "Please enter address",
        },
      }));
    }

    if (valid_location_mobile_no) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          location_mobile_no: true,
        },
        errorsHelper: {
          ...prevState.errorsHelper,
          location_mobile_no: "Please enter mobile_no",
        },
      }));
    }

    if (
      valid_name ||
      valid_email ||
      valid_address ||
      valid_location_mobile_no
    ) {
      return;
    }

    this.setState({ addEditModal: false });
    if (this.state.isEdit) {
      this.props.editData(userItem);
    } else {
      this.props.addData(userItem);
      this.setState({ loading: true });
      const requestData = {
        currentPage: this.state.page + 1,
        nPerPage: this.state.rowsPerPage,
      };
      this.props.fetchLocationsData(requestData);
      this.setState({ loading: false });
    }
  };

  deleteData = () => {
    this.setState({ deleteModal: false });
    if (this.state.myItem._id) {
      this.setState({ loading: true });
      this.props.deleteData(this.state.myItem._id);
      window.location.reload();
      const requestData = {
        currentPage: this.state.page + 1,
        nPerPage: this.state.rowsPerPage,
      };
      this.props.fetchLocationsData(requestData);
      this.setState({ loading: false });
    }
  };

  deleteModalClose = () => {
    this.setState({
      deleteModal: false,
    });
  };

  addEditModalClose = () => {
    this.setState({
      addEditModal: false,
    });
  };

  moreMenuClick = async (lid, value) => {
    if (lid) {
      await LocationServices.fetchLocationDataById(lid).then((response) => {
        if (response.data.success === 2) {
          console.error("Error fetching location data by id:", response);
        } else {
          console.log("Location data by id:", response);
          // this.setState({
          //   myItem: response.data.result,
          // })
          const fetchedData = response.data.result;
          this.setState({
            myItem: {
              _id: fetchedData._id || "",
              name: fetchedData.name || "",
              email: fetchedData.email || "",
              location_mobile_no: fetchedData.location_mobile_no || "",
              address: fetchedData.address || "",
              country: fetchedData.country || "",
              state: fetchedData.state || "",
              city: fetchedData.city || "",
              location_zipcode: fetchedData.location_zipcode || "",
              location_website: fetchedData.location_website || "",
              // location_business_type:
              //   fetchedData.location_business_type_id || "",
              // location_business_category:
              //   fetchedData.location_business_category_id || "",
              status: fetchedData.status,
            },
          });
          console.log("my item values: ", this.state.myItem);
        }
      });
    }

    if (value === "add") {
      var myItem = {
        name: "",
        email: "",
        location_mobile_no: "",
        address: "",
        country: "",
        state: "",
        city: "",
        location_zipcode: "",
        // location_business_type: "",
        // location_business_category: "",
        location_website: "",
      };
      this.setState({
        myItem,
        errors: {},
        errorsHelper: {},
        isEdit: 0,
        addEditModal: value === "add" ? true : false,
      });
    }
  };

  modifyData = (value) => {
    this.setState({
      isEdit: value === "edit" ? 1 : 0,
      addEditModal: value === "edit" ? true : false,
      deleteModal: value === "delete" ? true : false,
    });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        var myResults = results[0];
        var city = "";
        var state = "";
        var country = "";
        var zip_code = "";

        getLatLng(myResults).then((latLong) => {
          myResults.address_components.map((item) => {
            if (item.types.includes("locality")) {
              city = item.long_name;
            }
            if (item.types.includes("administrative_area_level_1")) {
              state = item.long_name;
            }
            if (item.types.includes("country")) {
              country = item.long_name;
            }
            if (item.types.includes("postal_code")) {
              zip_code = item.long_name;
            }
          });
          this.setState((prevState) => ({
            myItem: {
              ...prevState.myItem,
              address: myResults.formatted_address,
              city: city,
              state: state,
              country: country,
              location_zipcode: zip_code,
            },
          }));
        });
      })
      .catch((error) => console.error("Error", error));
  };

  handleChangeAddress = (data) => {
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        address: data,
      },
    }));
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      myItem: {
        ...prevState.myItem,
        [name]: value,
      },
    }));
  };

  handleFocus = (e) => {
    let state = e.target.name;
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [state]: false,
      },
      errorsHelper: {
        ...prevState.errorsHelper,
        [state]: "",
      },
    }));
  };

  handlePageChange = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleLocationsPerPageChange = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  render() {
    const {
      classes,
      locationData,
      totalCount,
      permissionsData,
      isLoading,
      pageNo,
      rowsPerPage,
      businessCategory,
      businessTypes,
    } = this.props;
    const { searchQuery } = this.state;
    var BusinessType = [];
    businessTypes &&
      businessTypes.map((item) => {
        var object = {
          value: item._id,
          label: item.name,
        };
        BusinessType.push(object);
      });
    var BusinessCategory = [];
    businessCategory &&
      businessCategory.map((item) => {
        var object = {
          value: item._id,
          label: item.name,
        };
        BusinessCategory.push(object);
      });

    if (isLoading) return <LoadingData />;
    return permissionsData.hr_view_locations ? (
      <Grid container spacing={4} className={classes.root}>
        <Typography className={classes.hrLocationTitle}>Locations</Typography>

        <Grid item xs={12}>
          <MyLeftModal
            bgColor={ovHrV5Color.main}
            title={this.state.isEdit ? "Edit Location" : "Add Location"}
            open={this.state.addEditModal}
            onClose={this.addEditModalClose}
            handleSubmit={this.handleSubmit}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MyTextfield
                  name="name"
                  label="Name"
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.myItem.name}
                  type="text"
                  error={this.state.errors.name}
                  helperText={
                    this.state.errors.name ? this.state.errorsHelper.name : ""
                  }
                  placeholder="Enter location name"
                />
              </Grid>

              <Grid item xs={12}>
                <MyTextfield
                  name="email"
                  label="Email"
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.myItem.email}
                  type="text"
                  error={this.state.errors.email}
                  helperText={
                    this.state.errors.email ? this.state.errorsHelper.email : ""
                  }
                  placeholder="Enter location email"
                />
              </Grid>

              <Grid item xs={12}>
                <MyTextfield
                  name="location_mobile_no"
                  label="Mobile no"
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.myItem.location_mobile_no}
                  type="text"
                  error={this.state.errors.location_mobile_no}
                  helperText={
                    this.state.errors.location_mobile_no
                      ? this.state.errorsHelper.location_mobile_no
                      : ""
                  }
                  placeholder="Enter location mobile no"
                />
              </Grid>

              <Grid item xs={12}>
                <PlacesAutocomplete
                  value={
                    this.state.myItem.address ? this.state.myItem.address : ""
                  }
                  onChange={this.handleChangeAddress}
                  onSelect={this.handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <MyTextfield
                        fullWidth
                        variant="outlined"
                        onFocus={this.props.handleFocus}
                        error={this.state.errors.address}
                        helperText={
                          this.state.errors.address
                            ? this.state.errorsHelper.address
                            : ""
                        }
                        size="small"
                        {...getInputProps({
                          placeholder: "Enter company address",
                          className: "location-search-input",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              style={{
                                display: "flex",
                                border: "1px solid #d4d5d9",
                                padding: 14,
                              }}
                            >
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Grid>

              {/* <Grid item xs={12}>
                <MyTextfield
                  name="country"
                  label="Country"
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.myItem.country}
                  type="text"
                  error={this.state.errors.country}
                  helperText={
                    this.state.errors.country
                      ? this.state.errorsHelper.country
                      : ""
                  }
                  placeholder="Enter country name"
                />
              </Grid>

              <Grid item xs={12}>
                <MyTextfield
                  name="state"
                  label="State"
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.myItem.state}
                  type="text"
                  error={this.state.errors.state}
                  helperText={
                    this.state.errors.state ? this.state.errorsHelper.state : ""
                  }
                  placeholder="Enter state name"
                />
              </Grid>

              <Grid item xs={12}>
                <MyTextfield
                  name="city"
                  label="City"
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.myItem.city}
                  type="text"
                  error={this.state.errors.city}
                  helperText={
                    this.state.errors.city ? this.state.errorsHelper.city : ""
                  }
                  placeholder="Enter city name"
                />
              </Grid>
              <Grid item xs={12}>
                <MyTextfield
                  name="location_zipcode"
                  label="Zip / Postal Code"
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.myItem.location_zipcode}
                  type="text"
                  error={this.state.errors.location_zipcode}
                  helperText={
                    this.state.errors.location_zipcode
                      ? this.state.errorsHelper.location_zipcode
                      : ""
                  }
                  placeholder="Enter postal code"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  select
                  size="small"
                  label="Business Type"
                  name="location_business_type"
                  value={this.state.myItem.location_business_type}
                  onChange={this.handleChange}
                  className={classes.dropdownFields}
                >
                  {BusinessType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  select
                  size="small"
                  label="Business Category"
                  name="location_business_category"
                  value={this.state.myItem.location_business_category}
                  onChange={this.handleChange}
                  className={classes.dropdownFields}
                >
                  {BusinessCategory.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}
              <Grid item xs={12}>
                <MyTextfield
                  name="location_website"
                  label="Website"
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  value={this.state.myItem.location_website}
                  type="text"
                  error={this.state.errors.location_website}
                  helperText={
                    this.state.errors.location_website
                      ? this.state.errorsHelper.location_website
                      : ""
                  }
                  placeholder="Enter website"
                />
              </Grid>
              {this.state.isEdit ? (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    select
                    size="small"
                    label="Status"
                    name="status"
                    value={this.state.myItem.status}
                    onChange={this.handleChange}
                    className={classes.dropdownFields}
                  >
                    {this.state.statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </MyLeftModal>
          <div className={classes.hrLocationSearchField}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search Location"
              value={searchQuery}
              onChange={this.handleSearch} // Bind the search handler
              inputProps={{
                style: {
                  height: "40px",
                  padding: "0 14px",
                  border: "none",
                },
              }}
              sx={{ width: "100% !important" }}
            />
          </div>
          <Paper>
            <Toolbar variant="regular" className={classes.rootToolbar}>
              <div className={classes.hrLocationTableBar}>
                <Typography className={classes.hrUserTableHeading}>
                  {Math.min(
                    (this.state.page + 1) * this.state.rowsPerPage,
                    totalCount
                  )}{" "}
                  / {totalCount} Locations
                </Typography>
                <OutlinedButton
                  disabled={permissionsData.hr_add_location ? false : true}
                  text={"Add New"}
                  onClick={() => {
                    this.moreMenuClick(null, "add");
                  }}
                  style={{ float: "right" }}
                  borderColor={ovHrV5Color.main}
                  textColor={ovHrV5Color.main}
                  icon={
                    <MdOutlineAddCircle className={classes.hrViewLogIcon} />
                  }
                />
              </div>
            </Toolbar>

            <TableContainer>
              <Table className={classes.table} size="medium">
                <TableHead style={{ background: ovHrV5Color.main }}>
                  <TableRow>
                    {this.state.headCells.map((headCell) => (
                      <TableCell
                        align={headCell.align}
                        key={headCell.id}
                        // sortDirection={
                        //   this.state.orderBy === headCell.id
                        //     ? this.state.order
                        //     : false
                        // }
                      >
                        <div
                          className={classes.headCell}
                          // active={this.state.orderBy === headCell.id}
                          // direction={
                          //   this.state.orderBy === headCell.id
                          //     ? this.state.order
                          //     : "asc"
                          // }
                          // onClick={this.createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {/* {this.state.orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                              {this.state.order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null} */}
                        </div>
                      </TableCell>
                    ))}
                    <TableCell
                      style={{ paddingRight: "20px" }}
                      className={classes.headCell}
                      align="right"
                      colSpan={2}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.loading
                    ? Array?.from({ length: 10 }).map((item, index) => (
                        <TableRow key={index}>
                          {this.state.headCells.map((cell) => (
                            <TableCell key={cell.id}>
                              <Skeleton height={12} />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    : stableSort(
                        locationData,
                        getComparator(this.state.order, this.state.orderBy)
                      ).map((row, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={row?._id}>
                            <TableCell className={classes.TableCell}>
                              {row?.name}
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              {row?.email}
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              {row?.address}
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              {row?.location_mobile_no}
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              {row?.locationUsersCount}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.TableCell}
                            >
                              {utcToLocal(row?.createdAt)
                                .locale("en-US")
                                .format("DD MMM YYYY,hh:mm A")}
                            </TableCell>

                            <TableCell
                              align="left"
                              className={classes.TableCell}
                            >
                              {row.updatedAt
                                ? moment(utcToLocal(row?.updatedAt))
                                    .locale("en-US")
                                    .format("DD MMM YYYY,hh:mm A")
                                : ""}
                            </TableCell>

                            <TableCell
                              align="right"
                              className={classes.TableCell}
                            >
                              <MoreMenu
                                product={"HR"}
                                moreMenuClick={() => {
                                  this.moreMenuClick(row._id, "edit");
                                }}
                                moreData={this.state.moreData}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}

                  {locationData && locationData?.length < 1 ? (
                    <TableRecordNotFound colSpan={8} label="No Data Found.." />
                  ) : (
                    ""
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {locationData && locationData.length > 0 && (
              <TablePagination
                component="div"
                count={totalCount}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={this.handlePageChange}
                onRowsPerPageChange={this.handleRowsPerPageChange}
              />
            )}
          </Paper>

          <DeleteModal
            bgColor={ovHrV5Color.main}
            desc="Are you sure you want to delete?"
            open={this.state.deleteModal}
            onClose={this.deleteModalClose}
            handleDelete={this.deleteData}
          />
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={12} className={classes.buttonMain}>
          <NoPermission />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.settingsLocation.isLoading,
  locationData: state.settingsLocation.locationData || [],
  totalCount: state.settingsLocation.totalCount,
  businessTypes: state.settingsLocation.businessTypes || [],
  businessCategory: state.settingsLocation.businessCategory || [],
});

const ConnectWith = connect(mapStateToProps, {
  fetchAllData,
  addData,
  editData,
  deleteData,
  fetchLocationsData,
  getBussinessCategories,
  getBussinessTypes,
})(Location);
export default withStyles(styles)(ConnectWith);
