import React, { Component } from "react";
// import { Link as DomLink } from "react-router-dom";
import { eLearningColor } from "../../../config";
import { withStyles } from "@material-ui/core/styles";
// import WarningIcon from "@mui/icons-material/WarningRounded";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import Breadcrumbs from "@mui/material/Breadcrumbs";
// import Link from "@mui/material/Link";
// import { stableSort, getComparator } from "../../../utilities/TableFunctions";
import {
  Stack,
  Box,
  Typography,
  // FormControlLabel,
  // FormControl,
  // Select,
  // MenuItem,
  // ListItemText,
  // Checkbox,
  TableContainer,
  // TableSortLabel,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  Table,
  // Button,
  // Toolbar,
  Grid,
  TableHead,
  // Pagination,
  Tooltip,
} from "@mui/material";

import {
  fetchData as fetchGrading,
  fetchAssignLesson,
  fetchlocationData,
  fetchRoleData,
  getLessonNames,
  fetchTeamGradingData,
} from "./modules/actions";
import { connect } from "react-redux";

import {
  getGradingPayload,
  handleGradingRole,
  getUsers,
  getQuizCount,
  // getLessonData,
} from "./modules/actions";

// import jsPDF from "jspdf";
import "jspdf-autotable";
// import { CSVLink } from "react-csv";
import { history } from "../../../history";
// import { gradingServices } from "./modules/services";
import {
  NoPermission,
  // LoadingData,
  MyPlainFullModal,
  // TableRecordNotFound,
} from "../../../components";

import { allCategoriesWithLessons } from "../Lessons/modules/actions";
import Cookies from "universal-cookie";
// import GradingLessons from "./unused/GradingLessons";
// import ExportProgress from "../Progress/ExportProgress";
// import { MdRefresh } from "react-icons/md";

// MUI Custom button
import {
  // DisabledButton,
  FailButton,
  GradientButton,
  PassButton,
  PendingButton,
} from "./GradingStyles";

// import InfiniteScroll from "react-infinite-scroll-component";

// MUI icons
// import { IconButton } from "@mui/material";
// import RefreshIcon from "@mui/icons-material/Refresh";
// import UploadIcon from "@mui/icons-material/Upload";
// import { BiExport } from "react-icons/bi";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { GradingLoading } from "./utils/GradingLoading";
import Progress from "../Progress/Progress";

// skeleton Loader
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { MobileGrading } from "../../../mobileView/eLearning/pages";
import { withWindowSize } from "../../../mobileView/hoc/withWindowSize";
import CustomSearchDropdown from "../../../components/ELearning/Dropdown/CustomSearchDropdown";
import { gradingServices } from "./modules/services";
// import { Button } from "@material-ui/core";
import { LoadMore } from "../../../components/ELearning/Button/LoadMore";

// import DropDown from "../../../utilities/DropDown";

const cookies = new Cookies();
var ovUid = cookies.get("ovUid");

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: "20px!important",
  },
  tableinline: {
    margin: "20px!important",
  },
  avatar: {
    marginRight: "10px",
    float: "left",
    textTransform: "uppercase",
    height: "40px",
    width: "40px",
    fontWeight: "600",
    fontSize: "15px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  TableCell: {
    padding: "1rem",
  },
  toolbarIcons: {
    display: "flex",
    justifyContent: "end",
  },
  flex: {
    display: "flex",
  },
  categoryName: {
    maxWidth: "200px",
    lineHeight: 3,
    display: "-webkit-box",
    WebkitLineClamp: 1,
    color: eLearningColor.main,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  Download: {
    padding: "7px 0px",
    marginLeft: "3px",
    color: "white",
    width: "37px!important",
    minWidth: "37px",
    height: "34px",
    border: "1px solid #DEDEDE",
    "&:hover": {
      backgroundColor: "rgb(19, 80, 152)",
    },
  },
  headCell: {
    padding: "0.5rem 0rem",
    color: "#1B3F8F !important",
    fontWeight: 500,
    textTransform: "capitalize",
    "&:hover": {
      color: "#635BFF !important",
    },
    "&:focus": {
      color: "#635BFF !important",
    },
    "&:active": {
      color: "#635BFF !important",
    },
  },
  rootToolbar: {
    minHeight: "58px",
    padding: theme.spacing(0, 2),
    justifyContent: "right",
  },
  title: {
    flex: "1 1 100%",
  },
  icdiv: {
    cursor: "pointer",
    border: "1px solid #DEDEDE",
    height: "34px",
    paddingTop: "3px",
    width: "37px",
    borderRadius: "5px",
    textAlign: "center",
    marginRight: "8px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  gradeButton: {
    cursor: "pointer",
    textTransform: "capitalize",
    fontSize: "13px",
    height: "24px",
    backgroundImage: `linear-gradient(#1B3F8F, #635BFF)`,
    color: "white",
    borderRadius: "29px",
  },
});

const GradingRowsSkeletonLoader = ({ state }) => {
  return (
    <TableRow>
      <SkeletonTheme
        baseColor="#ebebeb"
        // highlightColor="#f5f5f5"
        // borderRadius="0.5rem"
        duration={4}
      >
        <TableCell
          style={{
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <Skeleton
            style={{
              width: "100px",
              borderRadius: "7px",
            }}
          />
        </TableCell>
        <TableCell
          style={{
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <Skeleton
            style={{
              width: "100px",
              borderRadius: "7px",
            }}
          />
        </TableCell>
        <TableCell
          style={{
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <Skeleton
            style={{
              width: "100px",
              borderRadius: "7px",
            }}
          />
        </TableCell>
        {state.lesson_quiz ? (
          <TableCell
            style={{
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            <Skeleton
              style={{
                width: "100px",
                borderRadius: "7px",
              }}
            />
          </TableCell>
        ) : (
          ""
        )}
        {state.manager_quiz ? (
          <TableCell
            style={{
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            <Skeleton
              style={{
                width: "100px",
                borderRadius: "7px",
              }}
            />
          </TableCell>
        ) : (
          ""
        )}
        {state.video_quiz ? (
          <TableCell
            style={{
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            <Skeleton
              style={{
                width: "100px",
                borderRadius: "7px",
              }}
            />
          </TableCell>
        ) : (
          ""
        )}
        <TableCell
          style={{
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <Skeleton
            style={{
              width: "80px",
              height: "24px",
              borderRadius: "15px",
            }}
          />
        </TableCell>
        <TableCell
          style={{
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <Skeleton
            style={{
              width: "80px",
              height: "24px",
              borderRadius: "15px",
            }}
          />
        </TableCell>
      </SkeletonTheme>
    </TableRow>
  );
};

class GradingNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headCells: [
        [
          {
            id: "trainee",
            label: "Trainee",
            align: "left",
            numeric: false,
          },
          {
            id: "lesson_quiz",
            label: "Role",
            numeric: false,
            align: "left",
          },
          {
            id: "video_quiz",
            label: "Lesson",
            numeric: false,
            align: "left",
          },
          {
            id: "manager_quiz",
            label: "OverAll %",
            numeric: false,
            align: "left",
          },
          {
            id: "personal_quiz",
            label: "Lesson Quiz",
            numeric: false,
            align: "left",
          },
          {
            id: "status",
            label: "Video Quiz",
            numeric: false,
            align: "left",
          },
          {
            id: "action",
            label: "Manager Quiz",
            numeric: false,
            align: "left",
          },
          {
            id: "action",
            label: "Personal Quiz",
            numeric: false,
            align: "left",
          },
        ],
      ],
      mainData: [
        {
          trainee: "Employee 1",
          role: "Employee",
          lessons: "15",
          overall: "23%",
          pending: "10/12",
          manager: "10/12",
          video: "10/12",
          practical: "10/12",
        },
        {
          trainee: "Employee 2",
          role: "Employee",
          lessons: "15",
          overall: "23%",
          pending: "10/12",
          manager: "",
          video: "10/12",
          practical: "10/12",
        },
        {
          trainee: "Employee 3",
          role: "Employee",
          lessons: "15",
          overall: "23%",
          pending: "10/12",
          manager: "",
          video: "10/12",
          practical: "10/12",
        },
      ],
      listData: [
        {
          lesson: "Lesson 1",
          completion: false,
          mananger: false,
          video: false,
          inperson: false,
          status: false,
        },
        {
          lesson: "Lesson 1",
          completion: true,
          mananger: true,
          video: true,
          inperson: true,
          status: true,
        },
      ],
      showHideSettings: {
        lesson_quiz: 1,
        manager_quiz: 1,
        video_quiz: 1,
        practical_quiz: 1,
      },
      teamGradingData: [],
      teamGradingCurrentPage: 1,
      teamGradingTotalPages: 0,
      count: 0,
      hasMoreData: true,
      teamGradingLoading: false,
      showList: true,
      viewList: false,
      uploadToggel: false,
      order: "asc",
      orderBy: "_id",
      currentTrainee: "",
      user_id: "",
      locaions_assigned: "",
      role_assigned: "",
      lc_assigned: "",
      video_assigned: "",
      person_assigned: "",
      locaions_assigned1: "",
      role_assigned1: "",
      lc_assigned1: "",
      video_assigned1: "",
      person_assigned1: "",
      page: 1,
      page1: 1,
      lesson_id: "",
      uid: "",
      ecid: "",
      totalLessonPage: 1,
      lessonPageNumber: 1,
      lesson_result_id: "",
      lesson_s: "",
      lesson_quiz_s: "",
      manager_quiz_s: "",
      video_quiz_s: "",
      tab: "",
      selectedCategory: "",
      selectedLocationFilter: "",
      selectedUserFilter: "",
    };
  }

  createDataAndFetch = (newData, key) => {
    let data = {
      page: 1,
      limit: 50,
    };

    if (this.state.selectedLocationFilter) {
      data.lid = this.state.selectedLocationFilter;
    }
    if (this.state.selectedUserFilter) {
      data.uid = this.state.selectedUserFilter;
    }
    if (this.state.selectedCategory) {
      data.ecid = this.state.selectedCategory;
    }
    if (this.state.lesson_s) {
      data.lesson_id = this.state.lesson_s;
    }
    if (this.state.video_quiz_s) {
      data.video_quiz_status = this.state.video_quiz_s;
    }
    if (this.state.lesson_quiz_s) {
      data.lesson_quiz_status = this.state.lesson_quiz_s;
    }
    if (this.state.manager_quiz_s) {
      data.manager_quiz_status = this.state.manager_quiz_s;
    }
    if (this.state.role_assigned) {
      data.role_id = this.state.role_assigned;
    }

    if (newData) {
      data[key] = newData;
    } else {
      delete data[key];
    }

    this.setState({ teamGradingLoading: true });
    gradingServices
      .fetchTeamGradingData(data)
      .then((response) => {
        if (response.data.success === 1) {
          this.setState({ teamGradingLoading: false });

          this.setState((prevState) => ({
            teamGradingData: [
              ...prevState.teamGradingData,
              ...response.data.result,
            ],
          }));

          this.setState({
            teamGradingCurrentPage: response.data.currentPage,
            teamGradingTotalPages: response.data.totalPages,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching team grading data:", error);
        this.setState({ teamGradingLoading: false, hasMoreData: false });
      });
  };

  loadMoreData = () => {
    const nextPage = this.state.teamGradingCurrentPage + 1;
    if (nextPage <= this.state.teamGradingTotalPages) {
      this.setState(
        { teamGradingCurrentPage: nextPage, teamGradingLoading: true },
        () => {
          this.createDataAndFetch(nextPage, "page");
        }
      );
    }
  };

  handleChangeCategory = (event) => {
    let newData = event.value;
    this.setState({
      selectedCategory: newData,
      teamGradingData: [],
      teamGradingCurrentPage: 1,
    });
    this.createDataAndFetch(newData, "ecid");
  };

  handleChangeLocation = (event) => {
    let newData = event.value;
    this.setState({
      selectedLocationFilter: newData,
      teamGradingData: [],
      teamGradingCurrentPage: 1,
    });
    this.createDataAndFetch(newData, "lid");
  };

  handleChangeUser = (event) => {
    let newData = event.value;
    this.setState({
      selectedUserFilter: newData,
      teamGradingData: [],
      teamGradingCurrentPage: 1,
    });
    this.createDataAndFetch(newData, "uid");
  };

  handleChangeDropDownRole = (event) => {
    let newData = event.value;
    this.setState({ lessonPageNumber: 1, count: 0 });
    this.setState({
      role_assigned: newData,
      teamGradingData: [],
      teamGradingCurrentPage: 1,
    });
    this.createDataAndFetch(newData, "role_id");
  };

  handleChangeDropDownManagerStatus = (event) => {
    let newData = event.value;
    this.setState({ lessonPageNumber: 1, count: 0 });
    this.setState({
      manager_quiz_s: newData,
      teamGradingData: [],
      teamGradingCurrentPage: 1,
    });
    this.createDataAndFetch(newData, "manager_quiz_status");
  };

  handleChangeDropDownLesson = (event) => {
    let newData = event.value;
    this.setState({ lessonPageNumber: 1, count: 0 });
    this.setState({
      lesson_s: newData,
      teamGradingData: [],
      teamGradingCurrentPage: 1,
    });
    this.createDataAndFetch(newData, "lesson_id");
  };

  handleChangeDropDownLessonQuizStatus = (event) => {
    let newData = event.value;
    this.setState({ lessonPageNumber: 1, count: 0 });
    this.setState({
      lesson_quiz_s: newData,
      teamGradingData: [],
      teamGradingCurrentPage: 1,
    });
    this.createDataAndFetch(newData, "lesson_quiz_status");
  };

  handleChangeDropDownVideo1 = (event) => {
    let newData = event.value;
    this.setState({ lessonPageNumber: 1, count: 0 });
    this.setState({
      video_quiz_s: newData,
      teamGradingData: [],
      teamGradingCurrentPage: 1,
    });
    this.createDataAndFetch(newData, "video_quiz_status");
  };

  handleChangePage1 = () => {
    this.setState({ count: this.state.count + 1 });
    this.createDataAndFetch(this.state.count + 1, "page");
  };

  componentDidMount = () => {
    if (
      !!this.props.permissionsData.elearning_grading_team_grading &&
      !this.props.permissionsData.elearning_grading_my_grading
    ) {
      this.setState({ tab: "team" });
      localStorage.setItem("gradingToggelTabState", JSON.stringify("team"));
    } else if (
      !this.props.permissionsData.elearning_grading_team_grading &&
      !!this.props.permissionsData.elearning_grading_my_grading
    ) {
      this.setState({ tab: "my" });
      localStorage.setItem("gradingToggelTabState", JSON.stringify("my"));
    } else if (
      !!this.props.permissionsData.elearning_grading_team_grading &&
      !!this.props.permissionsData.elearning_grading_my_grading
    ) {
      const storedState = localStorage.getItem("gradingToggelTabState");
      if (storedState) {
        const parsedState = JSON.parse(storedState);
        // Use parsedState to set the state
        this.setState({ tab: parsedState });
      } else {
        this.setState({ tab: "my" });
        localStorage.setItem("gradingToggelTabState", JSON.stringify("my"));
      }
    }

    if (!!this.props.permissionsData.elearning_grading_team_grading) {
      this.props.fetchRoleData();
      this.props.getLessonNames();
      this.props.allCategoriesWithLessons();
      this.props.fetchlocationData();
      this.props.getUsers();
      this.props.getQuizCount();
    }

    const storedState = localStorage.getItem("gradingToggelTabState");
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      // Use parsedState to set the state
      this.setState({ tab: parsedState });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tab !== this.state.tab) {
      if (this.state.tab === "team") {
        this.createDataAndFetch();
        this.setState({
          lesson_s: "",
          lesson_quiz_s: "",
          manager_quiz_s: "",
          video_quiz_s: "",
          role_assigned: "",
          selectedCategory: "",
          selectedLocationFilter: "",
          selectedUserFilter: "",
          teamGradingData: [],
        });
      }
    }

    if (this.props.permissionsData !== prevProps.permissionsData) {
      console.log(this.props.permissionsData, "Permissions Data Payloaad");
      let newTab = this.state.tab;

      if (
        !!this.props.permissionsData.elearning_grading_team_grading &&
        !this.props.permissionsData.elearning_grading_my_grading
      ) {
        newTab = "team";
      } else if (
        !this.props.permissionsData.elearning_grading_team_grading &&
        !!this.props.permissionsData.elearning_grading_my_grading
      ) {
        newTab = "my";
      }

      if (newTab !== this.state.tab) {
        this.setState({ tab: newTab });
        localStorage.setItem("gradingToggelTabState", JSON.stringify(newTab));
      }
    }
  }

  // apiFunction = () => {
  //   let data = {
  //     page: this.state.page,
  //     limit: 50,
  //     // lid: this.state.locaions_assigned,
  //     // role_id: this.state.role_assigned,
  //   };
  //   this.props.fetchGrading(data);
  // };

  // handlePop = () => {};
  // handleVideos = () => {
  //   console.log("hello its clicked");
  // };

  // createSortHandler = (property) => (event) => {
  //   const isAsc = this.state.orderBy === property && this.state.order === "asc";
  //   this.setState({
  //     order: isAsc ? "desc" : "asc",
  //     orderBy: property,
  //   });
  // };

  // handleChangeDropDownLocation = (event) => {
  //   let newData = event.target.value;
  //   let data = {
  //     page: this.state.page,
  //     limit: 10,
  //   };

  //   // Check if `lid` and `role_id` are not empty before adding them to the data object
  //   if (newData) {
  //     data.lid = newData;
  //   }

  //   if (this.state.role_assigned) {
  //     data.role_id = this.state.role_assigned;
  //   }
  //   this.props.fetchGrading(data);
  //   this.setState({
  //     locaions_assigned: newData,
  //   });
  // };

  // handleChangeDropDownLc = (event) => {
  //   var newData = event.target.value;
  // };

  // handleChangeDropDownVideo = (event) => {
  //   var newData = event.target.value;
  // };

  // handleChangeDropDownPerson = (event) => {
  //   var newData = event.target.value;
  // };

  // handleChangeDropDown1 = (event) => {
  //   var newData = event.target.value;
  //   let data = {
  //     // isLessonquiz: newData,
  //     // isvideo_quiz: 1,
  //     // practical_quiz: 1,
  //     // manager_quiz: 1,
  //     page: this.state.page,
  //     limit: 50,
  //   };
  //   this.props.fetchGrading(data);
  //   this.setState({
  //     locaions_assigned1: newData,
  //   });
  // };

  // handleChangeDropDownRole1 = (event) => {
  //   let newData = event.target.value;
  // };

  // handleChangeDropDownPerson1 = (event) => {
  //   var newData = event.target.value;
  //   var newOne = parseInt(newData);
  //   let data = {
  //     page: this.state.page1,
  //     limit: 50,
  //     // isvideo_quiz: 2,
  //     // practical_quiz: newOne,
  //   };
  //   this.props.fetchAssignLesson(this.state.user_id, data);
  // };

  handleChangeCheckbox = (event) => {
    let checked = event.target.checked;
    let name = event.target.name;
    this.setState(
      {
        showHideSettings: {
          ...this.state.showHideSettings,
          [name]: checked === true ? 1 : 0,
        },
      },
      () => {
        let data = {
          page: this.state.lessonPageNumber,
          limit: 50,
        };
        this.props.fetchTeamGradingData(data);
      }
    );
  };

  // handleChangeCheckbox1 = (event) => {
  //   let checked = event.target.checked;
  //   let name = event.target.name;
  //   this.setState({
  //     showHideSettings: {
  //       ...this.state.showHideSettings,
  //       [name]: checked === true ? 1 : 0,
  //     },
  //   });
  // };

  // printPayrollSummary = () => {
  //   const tableColumn = [
  //     "Trainee",
  //     "Role",
  //     "Lesson",
  //     "Overall",
  //     "Lesson Quiz",
  //     "Manager Quiz",
  //     "Video",
  //     // "Practical",
  //   ];

  //   const tableRows = [];
  //   this.props.UserData.map((rows, index) => {
  //     var weekArray = [];
  //     weekArray.push(rows.user_name.trim());
  //     weekArray.push(rows.user_role);
  //     weekArray.push(rows.lesson_count);
  //     weekArray.push(rows.overall);
  //     weekArray.push(rows.question_results_count + "/" + rows.question_count);
  //     weekArray.push(rows.manager_quiz_passed + "/" + rows.question_count);
  //     weekArray.push(rows.video_quiz_passed + "/" + rows.question_count);
  //     // weekArray.push(
  //     //   rows.Practical_comppleted_quiz + "/" + rows.Practical_quiz
  //     // );
  //     tableRows.push(weekArray);
  //   });
  //   const doc = new jsPDF("l", "mm", "a4");
  //   // doc.text("Skylinx East Tue Feb 9 - Tue Feb 16, 2021", 14, 15);
  //   doc.autoTable({
  //     theme: "plain",
  //     headStyles: {
  //       minCellHeight: 20,
  //       fontSize: 12,
  //       cellPadding: 3,
  //       fontStyle: "bold",
  //     },
  //     styles: {
  //       minCellHeight: 15,
  //       fontSize: 9,
  //       halign: "left",
  //       valign: "middle",
  //       fillColor: "#fefefe",
  //       textColor: "black",
  //       fontStyle: "normal",
  //     },
  //     margin: { top: 10 },
  //     head: [tableColumn],
  //     body: tableRows,
  //   });
  //   const date = Date().split(" ");
  //   const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
  //   doc.save(`userDetails_${dateStr}.pdf`);
  // };

  // handleClick = (e, _id, user_name) => {
  //   this.setState({
  //     showList: !this.state.showList,
  //     currentTrainee: user_name,
  //     user_id: _id,
  //   });
  //   var data = {
  //     page: this.state.page1,
  //     limit: 50,
  //   };
  //   this.props.fetchAssignLesson(_id, data);
  // };

  handleView = (e, ecid, _id) => {
    this.setState({
      // viewList: true,
      // uid: uid,
      lesson_id: _id,
      ecid: ecid,
    });
    window.open(`/elearning/knowledgebase/slider/${ecid}/${_id}`, "_blank");
  };

  handlePush = () => {
    this.setState({
      showList: !this.state.showList,
    });
    history.push("/elearning/grading");
  };

  // handleChangePage = (event, value) => {
  //   this.setState({
  //     page: value,
  //   });
  //   let data = {
  //     page: value,
  //     limit: 10,
  //   };
  //   this.props.fetchGrading(data);
  // };

  closeDialog = () => {
    this.setState({
      viewList: false,
    });
  };

  // handleSubmitVideo = (e, _id, ecid, lesson_result_id) => {
  //   this.setState(
  //     {
  //       viewList: true,
  //       // uid: uid,
  //       lesson_id: _id,
  //       ecid: ecid,
  //       lesson_result_id: lesson_result_id,
  //     },
  //     () => {
  //       history.push(
  //         `/elearning/grading/video/${_id}/${lesson_result_id}`
  //       );
  //     }
  //   );
  // };

  handleManagerQuiz = (e, _id, ecid, lesson_result_id) => {
    this.setState(
      {
        viewList: true,
        // uid: uid,
        lesson_id: _id,
        ecid: ecid?.[0],
        lesson_result_id: lesson_result_id,
      },

      () => {
        history.push(
          `/elearning/grading/managerquiz/${ecid?.[0]}/${_id}/${lesson_result_id}`
        );
      }
    );
  };

  toggelTab = (value) => {
    this.setState({ tab: value });
    localStorage.setItem("gradingToggelTabState", JSON.stringify(value));
  };

  handleVideoQuiz = (e, user_id, _id, ecid, lesson_result_id, video_url) => {
    this.setState(
      {
        viewList: true,
        // uid: uid,
        lesson_id: _id,
        ecid: ecid?.[0],
        lesson_result_id: lesson_result_id,
      },

      () => {
        history.push(
          `/elearning/grading/videoquiz/${user_id}/${ecid?.[0]}/${_id}/${lesson_result_id}`,
          { video_url: video_url }
        );
      }
    );
  };

  handleUpload = () => {
    if (this.state.uploadToggel === true) {
      this.setState({ uploadToggel: false });
    } else {
      this.setState({ uploadToggel: true });
    }
  };

  render() {
    var { classes } = this.props;
    const tableColumn = [
      "Trainee",
      "Role",
      "Lesson",
      "Overall",
      "Lesson Quiz",
      "Manager Quiz",
      "Video Quiz",
    ];

    var tableRows1 = [];
    tableRows1.push(tableColumn);
    // this.props.UserData.map((rows, index) => {
    //   var weekArray = [];
    //   weekArray.push(rows.user_name.trim());
    //   weekArray.push(rows.user_role.role_name);
    //   weekArray.push(rows.lesson_count);
    //   weekArray.push(rows.overall);
    //   weekArray.push(rows.question_results_count + "/" + rows.question_count);
    //   weekArray.push(rows.manager_quiz_passed + "/" + rows.question_count);
    //   weekArray.push(rows.video_quiz_passed + "/" + rows.question_count);
    //   tableRows1.push(weekArray);
    // });

    const numberOfSkeletons = 10;

    const GradingSkeletonArray = Array.from(
      { length: numberOfSkeletons },
      (_, index) => (
        <GradingRowsSkeletonLoader
          state={this.state.showHideSettings}
          key={index}
        />
      )
    );

    const { width } = this.props.windowSize;
    const isDesktop = width > 768;

    return (
      <React.Fragment>
        {this.state.viewList === true ? (
          <>
            <MyPlainFullModal
              bgColor={eLearningColor.main}
              title={this.state.location_name}
              open={this.state.viewList}
              onClose={this.closeDialog}
            ></MyPlainFullModal>
          </>
        ) : (
          this.state.showList &&
          (isDesktop ? (
            <div style={{ backgroundColor: "#F7F8FC", height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 9999,
                  paddingBottom: `${this.state.tab === "team" ? "2%" : "0px"}`,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "4%",
                    border: "1px solid #ccc",
                    // boxShadow: "0px 2px 2px 2px rgba(0,0,0,0.2)",
                    borderRadius: "5px",
                    width:
                      !!this.props.permissionsData
                        .elearning_grading_my_grading &&
                      !!this.props.permissionsData
                        .elearning_grading_team_grading
                        ? "200px"
                        : "100px",
                    height: "50px",
                    padding: "2px",
                    // gap: "20px",
                  }}
                >
                  {!!this.props.permissionsData
                    .elearning_grading_my_grading && (
                    <button
                      style={{
                        width: "98px",
                        height: "46px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: `${
                          this.state.tab === "my" ? "#ffffff" : "black"
                        }`,
                        // borderTopLeftRadius: "10px",
                        // borderBottomLeftRadius: "10px",
                        borderRadius: "5px",
                        background: `${
                          this.state.tab === "my"
                            ? "linear-gradient(to right, #1b3f8f, #635bff)"
                            : "transparent"
                        }`,
                        fontSize: "16px",
                        // borderRight: "1px solid #ccc",
                        // boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.3)",
                        transition: "background 0.3s ease, color 0.3s ease",
                        zIndex: 20,
                      }}
                      onClick={() => this.toggelTab("my")}
                    >
                      My
                    </button>
                  )}
                  {!!this.props.permissionsData
                    .elearning_grading_team_grading && (
                    <button
                      style={{
                        width: "98px",
                        height: "46px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: `${
                          this.state.tab === "team" ? "#ffffff" : "black"
                        }`,
                        // borderTopRightRadius: "10px",
                        // borderBottomRightRadius: "10px",
                        borderRadius: "5px",
                        background: `${
                          this.state.tab === "team"
                            ? "linear-gradient(to right, #1b3f8f, #635bff)"
                            : "transparent"
                        }`,
                        fontSize: "16px",
                        transition: "background 0.3s ease, color 0.3s ease",
                        zIndex: 20,
                      }}
                      onClick={() => this.toggelTab("team")}
                    >
                      Team
                    </button>
                  )}
                </div>
              </div>
              {this.state.tab === "my" ? (
                this.props.permissionsData.elearning_grading_my_grading ? (
                  <Progress headingFlag={false} />
                ) : (
                  <Grid container spacing={2} className={classes.root}>
                    <Grid item md={12} className={classes.buttonMain}>
                      <NoPermission />
                    </Grid>
                  </Grid>
                )
              ) : (
                this.state.tab === "team" &&
                (this.props.permissionsData.elearning_grading_team_grading ? (
                  <Box>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* <Box
                        display={"flex"}
                        justifyContent={"space-evenly"}
                        // mt={"3rem"}
                        mx={"1rem"}
                        mb={"1.5rem"}
                      >
                        <Box display={"flex"}>
                          <Box
                            style={{
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px",
                            }}
                            display={"flex"}
                            border={"1px solid #1b3f8f33"}
                            padding={"5px"}
                            backgroundColor={"white"}
                          >
                            <Box>
                              <FormControlLabel
                                alignItems={"center"}
                                style={{
                                  marginLeft: "0.5rem",
                                  marginBottom: "0rem",
                                }}
                                control={
                                  <Checkbox
                                    name={"lesson_quiz"}
                                    onClick={this.handleChangeCheckbox}
                                    checked={
                                      this.state.showHideSettings
                                        .lesson_quiz === 1
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    style={{
                                      fontSize:
                                        window.innerWidth < 500
                                          ? "14px"
                                          : "18px",
                                      color: "#1B3F8F",
                                    }}
                                  >
                                    Lesson Quiz
                                  </Typography>
                                }
                              />{" "}
                            </Box>
                          </Box>
                          <Box
                            border={"1px solid #1b3f8f33"}
                            padding={"5px"}
                            borderLeft={"none"}
                            backgroundColor={"white"}
                          >
                            <FormControlLabel
                              alignItems={"center"}
                              style={{
                                marginLeft: "0.5rem",
                                marginBottom: "0rem",
                              }}
                              control={
                                <Checkbox
                                  name={"video_quiz"}
                                  onClick={this.handleChangeCheckbox}
                                  checked={
                                    this.state.showHideSettings.video_quiz === 1
                                  }
                                />
                              }
                              label={
                                <Typography
                                  style={{
                                    fontSize:
                                      window.innerWidth < 500 ? "14px" : "18px",
                                    color: "#1B3F8F",
                                  }}
                                >
                                  Video Quiz
                                </Typography>
                              }
                            />{" "}
                          </Box>
                          <Box
                            style={{
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px",
                            }}
                            display={"flex"}
                            border={"1px solid #1b3f8f33"}
                            padding={"5px"}
                            borderLeft={"none"}
                            backgroundColor={"white"}
                          >
                            <FormControlLabel
                              alignItems={"center"}
                              style={{
                                marginLeft: "0.5rem",
                                marginBottom: "0rem",
                              }}
                              control={
                                <Checkbox
                                  name={"manager_quiz"}
                                  onClick={this.handleChangeCheckbox}
                                  checked={
                                    this.state.showHideSettings.manager_quiz ===
                                    1
                                  }
                                />
                              }
                              label={
                                <Typography
                                  style={{
                                    fontSize:
                                      window.innerWidth < 500 ? "14px" : "18px",
                                    color: "#1B3F8F",
                                  }}
                                >
                                  Manager Quiz
                                </Typography>
                              }
                            />{" "}
                          </Box>
                        </Box>
                      </Box> */}
                      {/* <Typography
                        variant="h5"
                        style={{
                          fontSize: "34px",
                          fontWeight: "700",
                          color: "#1b3f8f",
                          marginLeft: "1.5rem",
                          marginRight: "1.5rem",
                        }}
                      >
                        Team Grading List
                      </Typography> */}
                      <Box
                        mt={"1.5rem"}
                        mx={"1.5rem"}
                        mb={"2.5rem"}
                        display={"flex"}
                        gap={"20px"}
                        flexWrap={"wrap"}
                        style={{
                          justifyContent:
                            window.innerWidth < 700 ? "center" : "start",
                        }}
                      >
                        {/* export asad */}
                        {/* <FormControl
                  sx={{
                    float: "right",
                    borderRadius: "5px!important",
                    bgcolor: "white",
                  }}
                  size="small"
                >
                  <Select
                    sx={{
                      borderRadius: "5px!important",
                      bgcolor: "white",
                    }}
                    value=""
                    displayEmpty
                    id="label1"
                  >
                    <MenuItem value="">Export</MenuItem>
                    <MenuItem key={5} value={"5"}>
                      <ListItemText
                        onClick={this.printPayrollSummary}
                        primary={"PDF"}
                      />
                    </MenuItem>
                    <MenuItem key={6} value={"6"}>
                      <CSVLink
                        filename={"Grading.csv"}
                        data={tableRows1}
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText primary={"Excel"} />
                      </CSVLink>
                    </MenuItem>{" "}
                  </Select>
                </FormControl> */}
                        {/* grading list heading bottom dropdowns */}
                        <CustomSearchDropdown
                          options={[
                            { value: "", label: "All Categories" },
                            ...this.props.allCategoryWithLessonsData?.map(
                              (item) => ({
                                value: item._id,
                                label: item.category_name,
                              })
                            ),
                          ]}
                          selectedValue={this.state.selectedCategory}
                          handleChange={this.handleChangeCategory}
                          placeholder={
                            this.props.allCategoryWithLessonsData.find(
                              (item) => item._id === this.state.selectedCategory
                            )
                              ? this.props.allCategoryWithLessonsData.find(
                                  (item) =>
                                    item._id === this.state.selectedCategory
                                ).category_name
                              : "Category"
                          }
                          width={170}
                          borderColor="transparent"
                          borderRadius="10px"
                          textSize="15px"
                          textColor="#1B3F8F"
                          dropdownTextSize="12px"
                          dropdownTextColor="#333"
                          dropdownTextAlign="left"
                          boxShadowFlag={true}
                        />

                        <CustomSearchDropdown
                          options={[
                            { value: "", label: "All Locations" },
                            ...this.props.locationDetails?.map((item) => ({
                              value: item._id,
                              label: item?.name,
                            })),
                          ]}
                          selectedValue={this.state.selectedLocationFilter}
                          handleChange={this.handleChangeLocation}
                          placeholder={
                            this.props.locationDetails.find(
                              (item) =>
                                item._id === this.state.selectedLocationFilter
                            )
                              ? this.props.locationDetails.find(
                                  (item) =>
                                    item._id ===
                                    this.state.selectedLocationFilter
                                ).name
                              : "Locations"
                          }
                          width={170}
                          borderColor="transparent"
                          borderRadius="10px"
                          textSize="15px"
                          textColor="#1B3F8F"
                          dropdownTextSize="12px"
                          dropdownTextColor="#333"
                          dropdownTextAlign="left"
                          boxShadowFlag={true}
                        />
                      </Box>

                      <Box
                        mr={"30px"}
                        sx={{
                          display: "flex",
                          gap: "20px",
                          alignItems: "center",
                          flexWrap: "wrap",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography>
                          Pending Manager Quiz:{" "}
                          {this.props?.getQuizCountData?.result
                            ?.pendingManagerCount || 0}
                        </Typography>
                        <Typography>
                          Pending Video Quiz:{" "}
                          {this.props?.getQuizCountData?.result
                            ?.pendingVideoCount || 0}
                        </Typography>
                      </Box>
                    </Stack>

                    <div className={classes.tableinline}>
                      <Paper
                        style={{
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          overflowX: "auto",
                        }}
                      >
                        <TableContainer
                          className={{ root: classes.customTableContainer }}
                          style={{
                            background: "#ffffff",
                            borderRadius: "12px",
                            minHeight: "500px",
                          }}
                        >
                          <Table
                            // stickyHeader
                            className={classes.table}
                            size="medium"
                            // style={{ tableLayout: "fixed" }}
                          >
                            <TableHead
                              style={{ backgroundColor: eLearningColor.main }}
                            >
                              <TableRow>
                                {[
                                  {
                                    id: 1,
                                    heading: "Lesson Name",
                                  },
                                  {
                                    id: 2,
                                    heading: "Trainee",
                                  },
                                  {
                                    id: 3,
                                    heading: "Role",
                                  },
                                  {
                                    id: 4,
                                    heading: "Lesson quiz",
                                  },
                                  {
                                    id: 6,
                                    heading: "Video Quiz",
                                  },
                                  {
                                    id: 5,
                                    heading: "Manager Quiz",
                                  },
                                  {
                                    id: 7,
                                    heading: "Status",
                                  },
                                  {
                                    id: 8,
                                    heading: "Action",
                                  },
                                ].map((item, index) => (
                                  <TableCell
                                    style={{
                                      color: "#1B3F8F",
                                      backgroundColor: "#1B3F8F40",
                                      fontWeight: "400",
                                      fontSize: "20px",
                                      textAlign: "center",
                                    }}
                                    key={item.id}
                                  >
                                    {item.heading}
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow>
                                {[
                                  {
                                    id: 1,
                                    heading: "Lesson Name",
                                    filter: (
                                      <CustomSearchDropdown
                                        options={[
                                          { value: "", label: "All Lessons" },
                                          ...this.props.lessonNamesData?.map(
                                            (item) => ({
                                              value: item._id,
                                              label: item?.lesson_name,
                                            })
                                          ),
                                        ]}
                                        selectedValue={this.state.lesson_s}
                                        handleChange={
                                          this.handleChangeDropDownLesson
                                        }
                                        placeholder={
                                          this.props.lessonNamesData.find(
                                            (item) =>
                                              item._id === this.state.lesson_s
                                          )
                                            ? this.props.lessonNamesData.find(
                                                (item) =>
                                                  item._id ===
                                                  this.state.lesson_s
                                              ).lesson_name
                                            : "Lesson"
                                        }
                                        width={170}
                                        borderColor="transparent"
                                        borderRadius="10px"
                                        textSize="15px"
                                        textColor="#1B3F8F"
                                        dropdownTextSize="12px"
                                        dropdownTextColor="#333"
                                        dropdownTextAlign="left"
                                        boxShadowFlag={true}
                                      />
                                    ),
                                  },
                                  {
                                    id: 2,
                                    heading: "Trainee",
                                    filter: (
                                      <CustomSearchDropdown
                                        options={[
                                          { value: "", label: "All Users" },
                                          ...this.props.getAllUsersData.map(
                                            (item) => ({
                                              value: item._id,
                                              label: item?.user_name,
                                            })
                                          ),
                                        ]}
                                        selectedValue={
                                          this.state.selectedUserFilter
                                        }
                                        handleChange={this.handleChangeUser}
                                        placeholder={
                                          this.props.getAllUsersData.find(
                                            (item) =>
                                              item._id ===
                                              this.state.selectedUserFilter
                                          )
                                            ? this.props.getAllUsersData.find(
                                                (item) =>
                                                  item._id ===
                                                  this.state.selectedUserFilter
                                              ).user_name
                                            : "Users"
                                        }
                                        width={170}
                                        borderColor="transparent"
                                        borderRadius="10px"
                                        textSize="15px"
                                        textColor="#1B3F8F"
                                        dropdownTextSize="12px"
                                        dropdownTextColor="#333"
                                        dropdownTextAlign="left"
                                        boxShadowFlag={true}
                                      />
                                    ),
                                  },
                                  {
                                    id: 3,
                                    heading: "Role",
                                    filter: (
                                      <CustomSearchDropdown
                                        options={[
                                          { value: "", label: "All Role" },
                                          ...this.props.roleDetails.map(
                                            (item) => ({
                                              value: item._id,
                                              label: item?.role_name,
                                            })
                                          ),
                                        ]}
                                        selectedValue={this.state.role_assigned}
                                        handleChange={
                                          this.handleChangeDropDownRole
                                        }
                                        placeholder={
                                          this.props.roleDetails.find(
                                            (item) =>
                                              item._id ===
                                              this.state.role_assigned
                                          )
                                            ? this.props.roleDetails.find(
                                                (item) =>
                                                  item._id ===
                                                  this.state.role_assigned
                                              ).role_name
                                            : "Role"
                                        }
                                        width={170}
                                        borderColor="transparent"
                                        borderRadius="10px"
                                        textSize="15px"
                                        textColor="#1B3F8F"
                                        dropdownTextSize="12px"
                                        dropdownTextColor="#333"
                                        dropdownTextAlign="left"
                                        boxShadowFlag={true}
                                      />
                                    ),
                                  },
                                  {
                                    id: 4,
                                    heading: "Lesson quiz",
                                    filter: (
                                      <CustomSearchDropdown
                                        options={[
                                          { value: "", label: "Lesson Quiz" },
                                          { value: "Passed", label: "Passed" },
                                          {
                                            value: "Pending",
                                            label: "Pending",
                                          },
                                        ]}
                                        selectedValue={this.state.lesson_quiz_s}
                                        handleChange={
                                          this
                                            .handleChangeDropDownLessonQuizStatus
                                        }
                                        placeholder={
                                          this.state.lesson_quiz_s ||
                                          "Lesson Quiz"
                                        }
                                        width={170}
                                        borderColor="transparent"
                                        borderRadius="10px"
                                        textSize="15px"
                                        textColor="#1B3F8F"
                                        dropdownTextSize="12px"
                                        dropdownTextColor="#333"
                                        dropdownTextAlign="left"
                                        boxShadowFlag={true}
                                      />
                                    ),
                                  },
                                  {
                                    id: 6,
                                    heading: "Video Quiz",
                                    filter: (
                                      <CustomSearchDropdown
                                        options={[
                                          { value: "", label: "Video Quiz" },
                                          {
                                            value: "Pending",
                                            label: "Pending",
                                          },
                                          {
                                            value: "Pass",
                                            label: "Pass",
                                          },
                                          {
                                            value: "Reassigned",
                                            label: "Reassigned",
                                          },
                                          {
                                            value: "Enabled",
                                            label: "Enabled",
                                          },
                                          {
                                            value: "Disabled",
                                            label: "Disabled",
                                          },
                                        ]}
                                        selectedValue={this.state.video_quiz_s}
                                        handleChange={
                                          this.handleChangeDropDownVideo1
                                        }
                                        placeholder={
                                          this.state.video_quiz_s ||
                                          "Video Quiz"
                                        }
                                        width={170}
                                        borderColor="transparent"
                                        borderRadius="10px"
                                        textSize="15px"
                                        textColor="#1B3F8F"
                                        dropdownTextSize="12px"
                                        dropdownTextColor="#333"
                                        dropdownTextAlign="left"
                                        boxShadowFlag={true}
                                      />
                                    ),
                                  },
                                  {
                                    id: 5,
                                    heading: "Manager Quiz",
                                    filter: (
                                      <CustomSearchDropdown
                                        options={[
                                          { value: "", label: "Manager Quiz" },
                                          {
                                            value: "Pending",
                                            label: "Pending",
                                          },
                                          {
                                            value: "Reassigned",
                                            label: "Reassigned",
                                          },
                                          {
                                            value: "Pass",
                                            label: "Pass",
                                          },
                                          {
                                            value: "Enabled",
                                            label: "Enabled",
                                          },
                                          {
                                            value: "Disabled",
                                            label: "Disabled",
                                          },
                                        ]}
                                        selectedValue={
                                          this.state.manager_quiz_s
                                        }
                                        handleChange={
                                          this.handleChangeDropDownManagerStatus
                                        }
                                        placeholder={
                                          this.state.manager_quiz_s ||
                                          "Manager Quiz"
                                        }
                                        width={170}
                                        borderColor="transparent"
                                        borderRadius="10px"
                                        textSize="15px"
                                        textColor="#1B3F8F"
                                        dropdownTextSize="12px"
                                        dropdownTextColor="#333"
                                        dropdownTextAlign="left"
                                        boxShadowFlag={true}
                                      />
                                    ),
                                  },
                                  {
                                    id: 7,
                                    heading: "Status",
                                  },
                                  {
                                    id: 8,
                                    heading: "Action",
                                  },
                                ].map((item, index) => (
                                  <TableCell
                                    key={item.id}
                                    style={{
                                      // color: "#1B3F8F",
                                      backgroundColor: "#1B3F8F40",
                                      // fontWeight: "400",
                                      // fontSize: "20px",
                                      // textAlign: "center",
                                    }}
                                    align="center"
                                  >
                                    <Stack direction="column" spacing={2}>
                                      {item.filter && (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "start",
                                          }}
                                        >
                                          {item.filter}
                                        </div>
                                      )}
                                    </Stack>
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {this.state.teamGradingData?.map((row, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      {
                                        <Box>
                                          <Tooltip title={row.lesson_name}>
                                            <Typography
                                              style={{
                                                color: "#848484",
                                                fontWeight: "400",
                                                fontSize: "16px",
                                              }}
                                            >
                                              {row.lesson_name.length > 20
                                                ? `${row.lesson_name.slice(
                                                    0,
                                                    20
                                                  )}...`
                                                : row.lesson_name}
                                            </Typography>
                                          </Tooltip>
                                        </Box>
                                      }
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      {
                                        <Box>
                                          <Typography
                                            style={{
                                              color: "#848484",
                                              fontWeight: "400",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {row.user.user_name}
                                          </Typography>
                                        </Box>
                                      }
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      {
                                        <Box>
                                          <Typography
                                            style={{
                                              color: "#848484",
                                              fontWeight: "400",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {row?.user_role?.role_name}
                                          </Typography>
                                        </Box>
                                      }
                                    </TableCell>
                                    {this.state.showHideSettings.lesson_quiz ? (
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        {
                                          <Box>
                                            <Typography
                                              style={{
                                                color: "#848484",
                                                fontWeight: "400",
                                                fontSize: "16px",
                                              }}
                                            >
                                              {row.lesson_quiz_status}
                                            </Typography>
                                          </Box>
                                        }
                                      </TableCell>
                                    ) : (
                                      ""
                                    )}

                                    {this.state.showHideSettings.video_quiz && (
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        {row.video_quiz_status === "Pending" ||
                                        row.video_quiz_status ===
                                          "Reassigned" ? (
                                          row.lesson_result &&
                                          row.lesson_result.video_url ? (
                                            <>
                                              {row.video_quiz_status ===
                                                "Reassigned" && (
                                                <Box>
                                                  <Typography
                                                    style={{
                                                      color: "#848484",
                                                      fontWeight: "400",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    {row.video_quiz_status}
                                                  </Typography>
                                                </Box>
                                              )}
                                              <GradientButton
                                                variant="contained"
                                                value="Grade"
                                                onClick={(e) =>
                                                  this.handleVideoQuiz(
                                                    e,
                                                    row.user._id,
                                                    row._id,
                                                    row.ecid,
                                                    row.lesson_result._id,
                                                    row.lesson_result.video_url
                                                  )
                                                }
                                              >
                                                Grade
                                              </GradientButton>
                                            </>
                                          ) : (
                                            <Box>
                                              <Typography
                                                style={{
                                                  color: "#848484",
                                                  fontWeight: "400",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                {row.video_quiz_status}
                                              </Typography>
                                            </Box>
                                          )
                                        ) : (
                                          <Box>
                                            <Typography
                                              style={{
                                                color: "#848484",
                                                fontWeight: "400",
                                                fontSize: "16px",
                                              }}
                                            >
                                              {row.video_quiz_status}
                                            </Typography>
                                          </Box>
                                        )}
                                      </TableCell>
                                    )}

                                    {this.state.showHideSettings
                                      .manager_quiz && (
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        {row.manager_quiz_status ===
                                          "Pending" ||
                                        row.manager_quiz_status ===
                                          "Reassigned" ? (
                                          row.lesson_result &&
                                          this.state.user_id !== ovUid ? (
                                            <GradientButton
                                              variant="contained"
                                              value="Manager"
                                              onClick={(e) =>
                                                this.handleManagerQuiz(
                                                  e,
                                                  row._id,
                                                  row.ecid,
                                                  row.lesson_result._id
                                                )
                                              }
                                            >
                                              Grade
                                            </GradientButton>
                                          ) : (
                                            <Box>
                                              <Typography
                                                style={{
                                                  color: "#848484",
                                                  fontWeight: "400",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                {row.manager_quiz_status}
                                              </Typography>
                                            </Box>
                                          )
                                        ) : (
                                          <Box>
                                            <Typography
                                              style={{
                                                color: "#848484",
                                                fontWeight: "400",
                                                fontSize: "16px",
                                              }}
                                            >
                                              {row.manager_quiz_status}
                                            </Typography>
                                          </Box>
                                        )}
                                      </TableCell>
                                    )}

                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      {row.overall_status === "Pending" ? (
                                        <PendingButton
                                          variant="contained"
                                          disableElevation
                                        >
                                          Pending
                                        </PendingButton>
                                      ) : row.overall_status === "Pass" ? (
                                        <PassButton
                                          variant="contained"
                                          disableElevation
                                        >
                                          Pass
                                        </PassButton>
                                      ) : (
                                        <FailButton
                                          variant="contained"
                                          disableElevation
                                        >
                                          Fail
                                        </FailButton>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <GradientButton
                                        variant="contained"
                                        onClick={(e) =>
                                          this.handleView(e, row.ecid, row._id)
                                        }
                                      >
                                        View
                                      </GradientButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                              {this.state.teamGradingLoading &&
                                GradingSkeletonArray}
                            </TableBody>
                          </Table>
                          {/* {this.props.isTeamGradingLoading && (
                            <Stack spacing={2} m={"1rem"}>
                              <GradingLoading />
                            </Stack>
                          )} */}

                          {!this.state.teamGradingLoading &&
                            !this.state.teamGradingData.length && (
                              <Box textAlign="center">
                                <Typography
                                  style={{
                                    color: "#848484",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                  }}
                                  spacing={2}
                                  m={"1rem"}
                                >
                                  No Record Found
                                </Typography>
                              </Box>
                            )}

                          {/* <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            <Stack spacing={2} m={"1rem"}>
                              <Pagination
                                color="primary"
                                count={
                                  !this.props.isTeamGradingLoading
                                    ? this.props.teamGradingPayload.totalPages
                                    : 1
                                }
                                variant="outlined"
                                page={
                                  !this.props.isTeamGradingLoading
                                    ? this.props.teamGradingPayload?.currentPage
                                    : 1
                                }
                                onChange={this.handleChangePage1}
                              />
                            </Stack>
                          </div> */}
                        </TableContainer>
                      </Paper>

                      {this.state.teamGradingCurrentPage <
                        this.state.teamGradingTotalPages && (
                        <LoadMore handlePageChange={this.loadMoreData} />
                      )}
                    </div>
                  </Box>
                ) : (
                  <Grid container spacing={2} className={classes.root}>
                    <Grid item md={12} className={classes.buttonMain}>
                      <NoPermission />
                    </Grid>
                  </Grid>
                ))
              )}
            </div>
          ) : (
            <MobileGrading
              handleManagerQuiz={this.handleManagerQuiz}
              handleVideoQuiz={this.handleVideoQuiz}
              userID={this.state.user_id}
              handleTeamPageChange={this.handleChangePage1}
              loadMoreData={this.loadMoreData}
              showHideSettings={this.state.showHideSettings}
              // isTeamGradingLoading={this.props.isTeamGradingLoading}
              handleView={this.handleView}
              // Filters
              handleChangeCheckbox={this.handleChangeCheckbox}
              // role
              handleChangeDropDownRole={this.handleChangeDropDownRole}
              selectedRole={this.state.role_assigned}
              roleDetails={this.props.roleDetails}
              // lesson
              handleChangeDropDownLesson={this.handleChangeDropDownLesson}
              lesson_s={this.state.lesson_s}
              lessonNamesData={this.props.lessonNamesData}
              isLessonNamesDataLoading={this.props.isLessonNamesDataLoading}
              // lesson quiz
              handleChangeDropDownLessonQuizStatus={
                this.handleChangeDropDownLessonQuizStatus
              }
              lesson_quiz_s={this.state.lesson_quiz_s}
              // manager quiz
              handleChangeDropDownManagerStatus={
                this.handleChangeDropDownManagerStatus
              }
              manager_quiz_s={this.state.manager_quiz_s}
              // video quiz
              handleChangeDropDownVideo1={this.handleChangeDropDownVideo1}
              video_quiz_s={this.state.video_quiz_s}
              // category filter
              handleChangeCategory={this.handleChangeCategory}
              selectedCategory={this.state.selectedCategory}
              categoryData={this.props.allCategoryWithLessonsData}
              // location filter
              handleChangeLocation={this.handleChangeLocation}
              selectedLocationFilter={this.state.selectedLocationFilter}
              locationsData={this.props.locationDetails}
              // user filter
              handleChangeUser={this.handleChangeUser}
              selectedUserFilter={this.state.selectedUserFilter}
              usersData={this.props.getAllUsersData}
              getQuizCountData={this.props?.getQuizCountData?.result}
            />
          ))
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  UserData: state.eLearningGrading.UserData,
  isLoading: state.eLearningGrading.isLoading,
  isUserGradingLoading: state.eLearningGrading.isUserGradingLoading,
  isLessonPayloadLoading: state.eLearningGrading.isLessonPayloadLoading,
  isLoadingLesson: state.eLearningGrading.isLoadingLesson,
  LessonPayload: state.eLearningGrading.LessonPayload,
  teamGradingPayload: state.eLearningGrading.teamGradingPayload,
  isTeamGradingLoading: state.eLearningGrading.isTeamGradingLoading,
  pageNumber: state.eLearningGrading.pageNumber,
  totalPage: state.eLearningGrading.totalPage,
  nPerPage: state.eLearningGrading.nPerPage,
  isLessonquiz: state.eLearningGrading.isLessonquiz,
  isvideo_quiz: state.eLearningGrading.isvideo_quiz,
  practical_quiz: state.eLearningGrading.practical_quiz,
  manager_quiz: state.eLearningGrading.manager_quiz,
  locationLength: state.eLearningGrading.locationLength,
  assignUserData: state.newsUpdateNews.assignUserData,
  isLocationLoading: state.eLearningGrading.isLocationLoading,
  locationDetails: state.eLearningGrading.locationDetails,
  isRolesLoading: state.eLearningGrading.isRolesLoading,
  roleDetails: state.eLearningGrading.roleDetails,
  lessonNamesData: state.eLearningGrading.lessonNamesData,
  isLessonNamesDataLoading: state.eLearningGrading.isLessonNamesDataLoading,
  totalLessonPage: state.eLearningGrading.totalLessonPage,
  lessonPageNumber: state.eLearningGrading.lessonPageNumber,
  permissionsData: state.settingsRoles.permissionsData,
  permissionsLoading: state.settingsRoles.permissionsLoading,
  // category filter data
  allCategoryWithLessonsData: state.eLearningLessons.allCategoryWithLessonsData,
  getAllCategoriesWithLessonsLoading:
    state.eLearningLessons.getAllCategoriesWithLessonsLoading,
  // users filter data
  getAllUsersData: state.eLearningGrading.getAllUsersData,
  isUserLoading: state.eLearningGrading.isUserLoading,
  // locations filter data
  // getAllLocationsData: state.eLearningGrading.getAllLocationsData,
  // quiz count data
  getQuizCountLoading: state.eLearningGrading.getQuizCountLoading,
  getQuizCountData: state.eLearningGrading.getQuizCountData,
});

const ConnectWith = connect(mapStateToProps, {
  getGradingPayload,
  fetchGrading,
  fetchAssignLesson,
  fetchlocationData,
  fetchRoleData,
  getLessonNames,
  handleGradingRole,
  fetchTeamGradingData,
  allCategoriesWithLessons,
  getUsers,
  getQuizCount,
})(GradingNew);

const ConnectedWithWindowSize = withWindowSize(ConnectWith);

export default withStyles(styles)(React.memo(ConnectedWithWindowSize));
