import React, { useState, useEffect, useRef } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { styles } from "../../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../../components/analytics/elearning/ReportTableWrapper";
import { newsUpdateServices } from "../../../../../../News/NewsUpdate/modules/services";
import axios from "axios";
import { analyticsNewsReportsServices } from "../../../../modules/services";
import { downloadCsvData } from "../../../../../../../utils/csvDownloadUtils";

const RoleUserBasedNewsReport = ({ classes }) => {
  const { rid, uid } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get("startDate");
  const endDate = queryParams.get("endDate");

  const role_name = queryParams.get("role_name");
  const user_name = queryParams.get("user_name");

  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");

  // const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  useEffect(() => {
    setNewsData([]);
    fetchNewsData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchNewsData = (sortBy = orderBy, sortOrder = order) => {
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      rid,
      uid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    analyticsNewsReportsServices
      .fetchNewsReports(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success) {
          setNewsData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleDownloadClick = () => {
    const requestData = {
      page,
      limit: 10,
      rid,
      uid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
      sort: order === "asc" ? orderBy : `-${orderBy}`,
    };

    const headers = [
      // { label: "ID", key: "_id" },
      { label: "News", key: "title" },
      {
        label: "Seen",
        key: "news_seen_status",
      },
      { label: "Acknowledged", key: "news_ack_status" },
    ];

    downloadCsvData({
      apiService: analyticsNewsReportsServices.newsReportDownload,
      requestData,
      headers,
      filename: "News_Report.csv",
      setLoading,
    });
  };

  const handleSortRequest = (property) => {
    setNewsData([]);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchNewsData(property, isAsc ? "desc" : "asc");
  };

  // const handleCountPercentage = () => {
  //   setChecked((prevChecked) => !prevChecked);
  // };

  const headers = [
    {
      id: "title",
      label: "News",
    },
    {
      id: "news_seen_status",
      label: "Seen",
    },
    {
      id: "news_ack_status",
      label: "Acknowledged",
    },
  ];

  const tableData = newsData?.map((row) => ({
    News:
      row?.title?.length > 35
        ? row?.title?.substring(0, 35) + "..."
        : row?.title,
    Seen:
      row.news_seen_status === "seen" ? (
        <div className={classes.textSuccess}>{row.news_seen_status}</div>
      ) : (
        <div className={classes.textFail}>{row.news_seen_status}</div>
      ),
    Acknowledged:
      row.news_ack_status === "acknowledged" ? (
        <div className={classes.textSuccess}>{row.news_ack_status}</div>
      ) : (
        <div className={classes.textFail}>{row.news_ack_status}</div>
      ),
  }));

  const breadcrumb_items = [
    {
      name: role_name,
      link: `/analytics/news/role/${rid}/users?role_name=${role_name}${
        startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ""
      }`,
    },
    {
      name: user_name,
      link: "",
    },
  ];

  return (
    <>
      <Grid className={classes.main}>
        <ReportTableWrapper
          classes={classes}
          data={tableData}
          headers={headers}
          loading={loading}
          page={page}
          totalPages={totalPages}
          onPageChange={setPage}
          onSortRequest={handleSortRequest}
          order={order}
          orderBy={orderBy}
          // handleCountPercentage={handleCountPercentage}
          // checked={checked}
          breadcrumbItems={breadcrumb_items}
          handleDownloadClick={handleDownloadClick}
        />
      </Grid>
    </>
  );
};

const ConnectWith = connect(null, null)(RoleUserBasedNewsReport);

export default withStyles(styles)(ConnectWith);
