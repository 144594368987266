import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  TablePagination,
  Grid,
} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import { rolesServices } from "../modules/services";
import moment from "moment";
import { alert, utcToLocal } from "../../../../utilities";
import {
  headCells,
  menuListData,
  permissionsObjectForAddRole,
} from "./constants";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { OutlinedButton } from "../../../../utilities/v5/common";
import { ovHrV5Color } from "../../../../config";
import { MdOutlineAddCircle } from "react-icons/md";
import {
  DeleteModal,
  MoreMenu,
  MyLeft50Modal,
  MyPlain25Modal,
} from "../../../../components";
import { MdRemoveRedEye } from "react-icons/md";
import { Link } from "react-router-dom";
import RoleUsers from "../RoleUsers";
import RoleFormContent from "../AddOrEditRoles/AddOrEditRoles";
import { makeStyles } from "@material-ui/core/styles";
import { loginAsUserAction } from "../../UsersList/modules/actions";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const loggedInUserRoleLevel = cookies.get("ovLevel");

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    height: 48,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const useStyles = makeStyles((theme) => ({
  hrRolesContainer: {
    display: "flex !important",
    flexDirection: "column !important",
    height: "100% !important",
    width: "100% !important",
    padding: "2rem !important",
    gap: "2rem !important",
  },

  hrRoleHeading: {
    fontFamily: "Roboto, sans-serif !important",
    fontSize: "36px !important",
    fontWeight: "700 !important",
    color: ovHrV5Color.main,
    lineHeight: "46.25px !important",
  },

  hrFieldRow: {
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    gap: "1rem !important",
  },

  hrTextFieldRole: {
    width: "30% !important",
    backgroundColor: "white !important",
    border: "none !important",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: ovHrV5Color.main,
      },
    },
  },

  hrViewLogIcon: {
    color: ovHrV5Color.main,
    cursor: "pointer",
    width: "25px",
    height: "25px",
  },

  hrTableContainer: {
    height: "100% !important",
    width: "100% !important",
  },

  hrTableHead: {
    backgroundColor: ovHrV5Color.main,
  },

  tableRow: {
    marginLeft: "2rem !important",
  },

  hrTableHeadText: {
    color: "white !important",
    fontSize: "16px !important",
  },

  hrButtonRow: {
    width: "100% !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    flexDirection: "row !important",
    height: "5rem !important",
    alignItems: "center !important",
    padding: "0 2rem 0 2rem !important",
  },

  hrTableHeadHeadingt: {
    fontSize: "26px !important",
    fontWeight: "700 !important",
    fontFamily: "Roboto, sans-serif !important",
    colro: "black !important",
  },
  hrTableHeadLeftHeading: {
    width: "100% !important",
  },
  hrTableHeadRightHeading: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    gap: "1rem !important ",
    alignItems: "center !important",
    width: "100%",
  },
}));

const Roles = ({ permissionsData }) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [viewAssignUsersLoading, setViewAssignUsersLoading] = useState(false);
  const [viewAssignedUsers, setViewAssignedUsers] = useState([]);
  const [roleDeleteId, setRoleDeleteId] = useState("");
  const [deleteRoleModalLoading, setDeleteRoleModalLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [singleRoleData, setSingleRoleData] = useState({});
  const [totalRolesCount, setTotalRolesCount] = useState(0);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(""); // Debounced state
  const roleLevel = cookies.get("ovLevel");

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery); // Update debounced query
    }, 500);

    return () => clearTimeout(timer); // Cleanup on query change
  }, [searchQuery]);
  console.log(searchQuery, debouncedSearchQuery, "debouncedSearchQuery");
  useEffect(() => {
    if (debouncedSearchQuery.trim() === "") {
      // Reset table data when search query is empty
      fetchRoles(); // Fetch default data
      console.log("Role Level: ", roleLevel);
    } else {
      // Fetch filtered data
      fetchRoles(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery]);
  // useEffect(() => {
  //   if (searchQuery.trim() !== "") {
  //     const timer = setTimeout(() => {
  //       setDebouncedSearchQuery(searchQuery); // Update debounced query
  //     }, 500);

  //     return () => clearTimeout(timer); // Cleanup on query change
  //   }
  // }, [searchQuery]);

  // useEffect(() => {
  //   if (debouncedSearchQuery !== null) {
  //     fetchRoles(debouncedSearchQuery); // Trigger the API call with debounced query
  //   }
  // }, [debouncedSearchQuery]);

  // useEffect(() => {
  //   console.log("Search Query:", searchQuery);
  //   console.log("Debounced Query:", debouncedSearchQuery);
  // }, [searchQuery, debouncedSearchQuery]);

  useEffect(() => {
    if (permissionsData.hr_view_roles) {
      fetchRoles();
    }
  }, [page, rowsPerPage, orderBy, orderDirection]);

  const fetchRoles = async (searchQuery = "") => {
    setLoading(true);
    // const sanitizedQuery = searchQuery.trim().toLowerCase();

    try {
      const data = {
        // pageNumber: page + 1,
        name: searchQuery.trim(),
        sortBy: orderBy,
        sortDirection: orderDirection,
      };
      if (searchQuery.trim() === "") {
        data.pageNumber = page + 1; // Add pageNumber when there is no search query
      }
      const response = await rolesServices.fetchData(data);
      setRoles(response.data.result || []);
      console.log("Roles Data:", response);
      setTotalRolesCount(response.data.totalCount || 0);
    } catch (error) {
      console.error("Error fetching roles:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRolesSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setPage(0);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTimeout = setTimeout(() => {
      if (value.trim() === "") {
        fetchRoles();
      } else {
        fetchRoles();
      }
    }, 1500);

    setTypingTimeout(newTimeout);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleViewLogsClicked = () => {};
  const handleAddRoleClick = () => {
    setIsEdit(false);
    console.log("hamzakhan");
    setSingleRoleData(permissionsObjectForAddRole);
    setShowAddEditModal(true);
  };
  // const handleAddRoleClick = () => {
  //   setIsEdit(false);
  //   setSingleRoleData(permissionsObjectForAddRole);
  //   // setShowAddEditModal(true);
  // };

  const modifyMenuItemData = async (action, id) => {
    switch (action) {
      case "assign":
        handleViewAssignUsers(id);
        break;
      case "delete":
        handleRoleDeleteModal(id);
        setRoleDeleteId(id);
        break;
      case "clone":
        handleCloneRole(id);
        break;
      case "edit":
        setIsEdit(true);
        // setLoading(true);
        try {
          const response = await rolesServices.fetchRolePermissions(id);
          console.log("Roles Permissions: ", response);
          if (response.status === 200 && response.data) {
            setSingleRoleData(response.data.result);
            setShowAddEditModal(true);
          } else {
            alert.error("Failed to fetch role details.");
          }
        } catch (error) {
          console.error("Error fetching role details:", error);
          alert.error("An error occurred while fetching role details.");
        } finally {
          setLoading(false);
        }
        break;
      default:
        break;
    }
  };

  const handleCloneRole = async (id) => {
    try {
      const response = await rolesServices.cloneRoles({ id });
      if (response.data.success !== 2) {
        alert.success(response.data.message);
        fetchRoles();
      }
    } catch (error) {
      console.error("Error cloning role:", error);
    }
  };

  const handleViewAssignUsers = async (id) => {
    setViewAssignUsersLoading(true);
    try {
      const response = await rolesServices.fetchUsersByRole(id);
      setViewAssignedUsers(response.data.assignUsers || []);
      setShowAssignModal(true);
    } catch (error) {
      console.error("Error fetching assigned users:", error);
    } finally {
      setViewAssignUsersLoading(false);
    }
  };

  const handleRoleDeleteModal = (id) => {
    setRoleDeleteId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteRole = async () => {
    setDeleteRoleModalLoading(true);
    try {
      const res = await rolesServices.deleteData(roleDeleteId);
      console.log("Deleted role:", res);
      if (res.data.success === 0) {
        alert.error(res.data.message);
        setShowDeleteModal(false);
      } else if (res.data.success !== 2) {
        alert.success(res.data.message);
        setShowDeleteModal(false);
        fetchRoles();
      }
    } catch (error) {
      console.error("Error deleting role:", error);
    } finally {
      setDeleteRoleModalLoading(false);
    }
  };

  const handleModalClose = () => {
    setShowAssignModal(false);
    setShowDeleteModal(false);
  };

  // const handleAddOrEditModalSubmission = async () => {
  //   try {
  //     if (isEdit) {
  //       const response = await rolesServices.editData(singleRoleData);
  //       if (response.data.success !== 2) {
  //         setShowAddEditModal(false);
  //         alert.success(response.data.message);
  //         fetchRoles();
  //         setRoles([]);

  //       }
  //     } else {
  //       const response = await rolesServices.addData(singleRoleData);
  //       if (response.data.success !== 2) {
  //         alert.success(response.data.message);
  //         setShowAddEditModal(false);
  //         fetchRoles();
  //         setRoles([]);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error submitting role data:", error);
  //   }
  // };

  const handleAddOrEditModalSubmission = async () => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    try {
      let response;
      if (isEdit) {
        console.log("singleRoleData: ", singleRoleData);
        const payload = { ...singleRoleData };
        if (loggedInUserRoleLevel == singleRoleData.level) {
          delete payload.role_parentId;
        }
        response = await rolesServices.editData(payload);

        if (response?.data?.success === 1) {
          setShowAddEditModal(false);
          alert.success(response.data.message);
          fetchRoles();
          setRoles([]);
        } else {
          alert.error(response?.data?.message || "Failed to update the role.");
        }
      } else {
        response = await rolesServices.addData(singleRoleData);

        if (response?.data?.success === 1) {
          alert.success(response.data.message);
          setShowAddEditModal(false);
          fetchRoles();
          setRoles([]);
        } else {
          alert.error(response?.data?.message || "Failed to add the role.");
        }
      }
    } catch (error) {
      console.error("Error submitting role data:", error);

      if (error.response) {
        alert.error(
          error.response.data?.message || "An error occurred on the server."
        );
      } else if (error.request) {
        alert.error("No response from the server. Please try again later.");
      } else {
        alert.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.hrRolesContainer}>
      <div className={classes.hrFieldRow}>
        <Typography className={classes.hrRoleHeading}>Roles</Typography>
        {permissionsData.hr_add_role ? (
          <MdOutlineAddCircle
            className={classes.hrViewLogIcon}
            onClick={handleAddRoleClick}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <></>
        )}
        {/*         
        <OutlinedButton
              disabled={!permissionsData.hr_add_role}
              text={"Add New"}
              onClick={handleAddRoleClick}
              borderColor={ovHrV5Color.main}
              textColor={ovHrV5Color.main}
              icon={<MdOutlineAddCircle className={classes.hrViewLogIcon} />}
            />

        <Link to="/hr/roles/logs">
          <OutlinedButton
            text={"View Logs"}
            onClick={handleViewLogsClicked}
            borderColor={ovHrV5Color.main}
            textColor={ovHrV5Color.main}
            icon={<MdRemoveRedEye className={classes.hrViewLogIcon} />}
          />
        </Link> */}

        {/* <div>
    {permissionsData.hr_add_role && (
      <MdOutlineAddCircle
        className={classes.hrViewLogIcon}
        onClick={handleAddRoleClick}
        style={{ cursor: "pointer" }}
      />
    )}
  </div>

  <div>
    <Link to="/hr/roles/logs">
      <MdRemoveRedEye
        className={classes.hrViewLogIcon}
        onClick={handleViewLogsClicked}
        style={{ cursor: "pointer" }}
      />
    </Link>
  </div>
  */}
      </div>

      <Paper>
        <TableContainer>
          <div className={classes.hrButtonRow}>
            <div className={classes.hrTableHeadLeftHeading}>
              <Typography className={classes.hrTableHeadHeadingt}>
                {Math.min((page + 1) * rowsPerPage, totalRolesCount)} /{" "}
                {totalRolesCount} Roles
              </Typography>
            </div>
            <div className={classes.hrTableHeadRightHeading}>
              <TextField
                fullWidth
                className={classes.hrTextFieldRole}
                variant="outlined"
                placeholder="Search Role Name"
                // onChange={handleRolesSearch}
                // value={searchTerm}
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                inputProps={{
                  style: {
                    height: "40px",
                    padding: "0 14px",
                    border: "none",
                  },
                }}
              />
              <Link to="/hr/roles/logs">
                <MdRemoveRedEye
                  className={classes.hrViewLogIcon}
                  onClick={handleViewLogsClicked}
                  style={{ cursor: "pointer" }}
                />
              </Link>
            </div>
          </div>
          <Table className={classes.hrTableContainer} size="medium">
            <TableHead className={classes.hrTableHead}>
              <TableRow>
                {headCells.map((headCell) => (
                  <StyledTableCell
                    align={headCell.align}
                    key={headCell.id}
                    sortDirection={
                      orderBy === headCell.id ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={
                        orderBy === headCell.id ? orderDirection : "asc"
                      }
                      onClick={() => handleSort(headCell.id)}
                    >
                      <Typography className={classes.hrTableHeadText}>
                        {headCell.label}
                      </Typography>
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array.from({ length: rowsPerPage }).map((_, index) => (
                    <TableRow key={index}>
                      {headCells.map((cell) => (
                        <TableCell key={cell.id}>
                          <Skeleton height={12} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : roles.map((role, index) => (
                    <TableRow key={index}>
                      <TableCell>{role.role_name}</TableCell>
                      <TableCell>{role.role_description}</TableCell>
                      <TableCell>{role.user_role_count}</TableCell>
                      <TableCell>
                        {moment(
                          utcToLocal(role.createdAt).locale("en-US")
                        ).format("DD MMM YYYY,hh:mm A")}
                      </TableCell>
                      <TableCell>
                        {role.updatedAt
                          ? moment(
                              utcToLocal(role.updatedAt).locale("en-US")
                            ).format("DD MMM YYYY, hh:mm A")
                          : ""}
                      </TableCell>
                      <TableCell>
                        <MoreMenu
                          product={"HR"}
                          moreMenuClick={() => {}}
                          moreData={menuListData(
                            permissionsData,
                            role._id,
                            role.level
                          )
                            .filter((item) => item.permission)
                            .map((item) => ({
                              ...item,
                              onClick: () =>
                                modifyMenuItemData(
                                  item.sortName,
                                  role._id,
                                  role
                                ),
                            }))}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {!loading && (
          <TablePagination
            component="div"
            count={-1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

        )} */}
        {!loading && roles.length > 0 && (
          <TablePagination
            component="div"
            count={totalRolesCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>

      <MyLeft50Modal
        bgColor={ovHrV5Color.main}
        title={isEdit ? "Edit Roles" : "Add Roles"}
        open={showAddEditModal}
        onClose={() => setShowAddEditModal(false)}
        handleSubmit={handleAddOrEditModalSubmission}
        isSubmitting={isSubmitting}
      >
        <RoleFormContent
          roleData={singleRoleData}
          isEdit={isEdit}
          onRoleDataChange={(updatedData) => setSingleRoleData(updatedData)}
        />
      </MyLeft50Modal>

      <MyPlain25Modal
        bgColor={ovHrV5Color.main}
        title="View Assigned User"
        open={showAssignModal}
        onClose={handleModalClose}
      >
        <Grid container spacing={0}>
          <RoleUsers
            isAssignUsersLoading={viewAssignUsersLoading}
            // toggleAssignUser={props.toggleAssignUser}
            assignUsers={viewAssignedUsers}
            loginAsUser={loginAsUserAction}
          />
        </Grid>
      </MyPlain25Modal>

      <DeleteModal
        product={"HR"}
        bgColor={ovHrV5Color.main}
        desc="Are you sure you want to delete?"
        open={showDeleteModal}
        onClose={handleModalClose}
        handleDelete={handleDeleteRole}
      />
    </div>
  );
};

export default Roles;
