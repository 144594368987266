import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { history } from "../history";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar
} from '@material-ui/core';

import Cookies from 'universal-cookie';
import { alert } from '../utilities';
const cookies = new Cookies();

const useStyles = makeStyles(theme => ({
  media: {
    padding: '10px',
    height: 80,
    width: 80,
  },
  CardContent: {
    padding: '14px 0px',
    "&:last-child": {
      // padding: '0'
    },
  },
  card: {
    boxShadow: "0px 2px 5px rgba(83, 97, 255, 0.0534986)",
    borderRadius: "8px",
    '&:hover': {
      boxShadow: "4px 8px 20px 0 rgba(0, 0, 0, 0.5), 4px 8px 20px 0 rgba(0, 0, 0, 0.5)"
    }
  },
  topDiv: {
    padding: '1rem 0',
    paddingBottom:'0px'
  },
  productTitle: {
    textDecoration: 'none',
    padding: '1rem',
    paddingTop:'0px',
    textAlign: 'center',
    color: '#1e293b',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing:'0.5px',
    fontFamily:'century_gothic',
  },
  subTitle: {
    color: '#ffffff',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '4rem',
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
    },
  }
}));

const ProductCard = props => {
  const { product } = props;
  const classes = useStyles();
  var ovCid = cookies.get('ovCid')

  const productModalToggle = (href) => {
    if (ovCid.toString() !== "624e80fff8ee75131034af66") {
      history.push(href)
    }
  }
  const alertModal = (href) => {
      alert.comingSoon("Coming Soon")
  }

  return (
    // <Link
    //   style={{
    //     pointerEvents: ovCid.toString() === "624e80fff8ee75131034af66" ? "none" : "initial",
    //     textDecoration: 'none'
    //   }}
    //   to={product.href}>
      <Card
        style={{
          opacity: ovCid.toString() === "624e80fff8ee75131034af66" ? 0.5 : 1,
          backgroundColor: product.backgroundColor ? product.backgroundColor : "red",
          cursor:"pointer",
          textDecoration: 'none'
        }}
        className={classes.card}
        onClick={() => { product.href === "/chat/"  ? alertModal() : productModalToggle(product.href) }}
      >
        <CardContent className={classes.CardContent} >
          <Grid
            className={classes.topDiv}
            container
            spacing={0}
          >
            <Grid
              item
              sm={12}
              xs={12}
              style={{
                padding: "1px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Avatar
                style={{ backgroundColor: product.iconColor }}
                className={classes.media}
                src={product.imageUrl}
              />
            </Grid>
            {/* <Grid
              item
              sm={8}
              xs={12}
            >
              <Typography
                className={classes.subTitle}
              >
                Started sending atss
              </Typography>
            </Grid> */}

          </Grid>
          {/* <Divider
            style={{ color: "#ffffff" }}
          /> */}

          <Typography
            className={classes.productTitle}
          >
            {product.title === "Scheduler" ?
              "Team Scheduler"
              : product.title
            }
          </Typography>
        </CardContent>
      </Card>
    // </Link >
  );
};

export default ProductCard;
