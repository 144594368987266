import { HEADER_TOKEN, MoNGO_DB_API_URL } from "../../../../../../config";
import axios from "axios";

export const holidayServices = {
  fetchData,
  addData,
  editData,
  deleteData,
  fetchlocationData,
  fetchPublicHolidayDatabyId,
  fetchAllEvents,
  fetchAllEventSubCategories,
  fetchAllEventBasedOnCategories,
  fetchAllEventBasedOnBirthdays,
  fetchAllEventBasedOnAnniversary,
};

function fetchData(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/public-holidays?page=${data.pageNumber}&limit=${process.env.REACT_APP_LIMIT}`;
  if (data.name) {
    url = `${url}&keyword=${data.name}`;
  }
  if (data.date) {
    url = `${url}&date=${data.date}`;
  }
  return axios.get(url, HEADER_TOKEN).then((response) => response);

}

function fetchlocationData() {
  return axios
    .get(`${MoNGO_DB_API_URL}/publicholidays/locations`, HEADER_TOKEN)
    .then((response) => response);
}

function fetchPublicHolidayDatabyId(id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/hr/publicholiday/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function addData(data) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/${process.env.REACT_APP_PLATFORM}/hr/publicholiday`,
      {
        holiday_date: data.holiday_date,
        holiday_name: data.holiday_name,
        isrepeate: data.isrepeate,
        selectedloc: data.selectedloc,
      },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function editData(data, id) {
//   return axios
//     .put(`${MoNGO_DB_API_URL}/publicholidays/${id}`, { data }, HEADER_TOKEN)
//     .then((response) => response);
// }
function editData(data, id) {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/${process.env.REACT_APP_PLATFORM}/hr/publicholiday/${id}`,
      { data },
      HEADER_TOKEN
    )
    .then((response) => response);
}

// function deleteData(uid) {
//   return axios
//     .delete(`${MoNGO_DB_API_URL}/publicholidays/${uid}`, HEADER_TOKEN)
//     .then((response) => response);
// }
function deleteData(uid) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/${process.env.REACT_APP_PLATFORM}/hr/publicholiday/${uid}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchAllEvents(uid) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/event-category`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchAllEventSubCategories(id) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_HR_API_VERSION}/hr/event-category/sub/${id}`,
      HEADER_TOKEN
    )
    .then((response) => response);
}

function fetchAllEventBasedOnCategories(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/hr/events?page=${data.page}&limit=${process.env.REACT_APP_LIMIT}`;
  if (data.parentCategory) {
    url = `${url}&parentCategory=${data.parentCategory}`;
  }
  if (data.eventCategory) {
    url = `${url}&eventCategory=${data.eventCategory}`;
  }
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}

function fetchAllEventBasedOnBirthdays(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/hr/events/birthdays?page=${data.page}&limit=${process.env.REACT_APP_LIMIT}`;
  if (data.keyword) {
    url = `${url}&keyword=${data.keyword}`;
  }
  if (data.date) {
    url = `${url}&date=${data.date}`;
  }
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}

function fetchAllEventBasedOnAnniversary(data) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NEWS_API_VERSION}/hr/events/work-anniversaries?page=${data.page}&limit=${process.env.REACT_APP_LIMIT}`;
  if (data.keyword) {
    url = `${url}&keyword=${data.keyword}`;
  }
  if (data.date) {
    url = `${url}&date=${data.date}`;
  }
  return axios.get(url, HEADER_TOKEN).then((response) => response);
}
