import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { news_my_news_Data, news_news_builder_Data } from "../constants";
import { makeStyles } from "@material-ui/core/styles";
import { ovHrV5Color } from "../../../../../config";

const useStyles = makeStyles((theme) => ({
  hrCheckboxRoot: {
    padding: "30px 20px",
    color: `${ovHrV5Color.main} !important`,
    "&.Mui-checked": {
      color: `${ovHrV5Color.main} !important`,
    },
    "&:hover": {
      color: `${ovHrV5Color.main} !important`,
    },
  },
  hrTextField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: `${ovHrV5Color.main} !important`,
      },
    },
  },
  select: {
    "MuiSelect-outlined.MuiSelect-outlined": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    "& .MuiInputBase-input": {
      "&:focus": {
        borderRadius: 6,
        borderColor: `${ovHrV5Color.main} !important`,
      },
    },
    "&:before": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    "&:after": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: `${ovHrV5Color.main} !important`,
    },
  },
  icon: {
    fill: ovHrV5Color.main,
  },
  headCell: {
    color: "#ffffff !important",
    fontWeight: "500 !important",
    textTransform: "capitalize",
  },
  rootToolbar: {
    minHeight: "58px !important",
    padding: "2px !important",
  },
  title: {
    flex: "1 1 100% !important",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  hrExpansionPanelMain: {
    "&:last-child": {
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    "&:first-child": {
      borderTopLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
    },
  },
  hrExpansionPanelSummary: {
    backgroundColor: `${ovHrV5Color.main} !important`,
  },
  hrHeading: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    color: "#ffffff !important",
  },
  headingReports: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    color: "black !important",
  },
  hrHeadingIcon: {
    color: "#ffffff !important",
  },
  hrExpansionPanelDetailsSecond: {
    borderLeft: `5px solid  ${ovHrV5Color.main} !important`,
    borderRight: `5px solid  ${ovHrV5Color.main} !important`,
    borderBottom: `5px solid ${ovHrV5Color.main} !important`,
    padding: "20px !important !important",
    display: "flex !important",
    flexDirection: "column !important",
  },
  hrExpansionPanel: {
    "&:last-child": {
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    "&:first-child": {
      borderTopLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
    },
  },
  hrExpansionPanelSummaryThird: {
    backgroundColor: "#f6f7f754 !important",
    padding: "0px 10px !important",
    height: "30px !important",
  },
  hrExpansionPanelDetailsThird: {
    padding: "10px 20px !important",
  },
  ExpansionPanelDetailsThirdCategory: {
    padding: "10px 20px !important",
    height: "20px !important",
  },
  ExpansionPanelDetailsThirdRole: {
    padding: "10px 20px !important",
    marginLeft: "32px !important",
    display: "flex !important",
    flexDirection: "column !important",
  },
  ExpansionPanelDetailsThirdBase: {
    padding: "10px 20px !important",
    marginLeft: "32px !important",
  },
  hrFormControlLabelSecond: {
    padding: "6px !important",
  },
  hrPageTitle: {
    lineHeight: "40px !important",
    color: "black !important",
  },
  pageHeading: {
    fontSize: "40px !important",
    color: ovHrV5Color.main,
    fontWeight: "700 !important",
  },
  viewLogsBtn: {
    border: `1px solid ${ovHrV5Color.main} !important`,
    borderRadius: "5px !important",
    color: `${ovHrV5Color.main} !important`,
    display: "inline-block !important",
    fontSize: "13px !important",
    height: "100% !important",
    width: "auto !important",
    padding: "8px 15px !important",
    "&:hover": {
      color: `${ovHrV5Color.main} !important`,
      textDecoration: "none !important",
    },
  },
}));

const NewsContent = ({ onPermissionsUpdate, checkboxesData }) => {
  const styles = useStyles();

  const initializeAccessRights = () => ({
    view_news_report: checkboxesData.view_news_report === 1,
    news_dashboard_refresh_settings:
      checkboxesData.news_dashboard_refresh_settings === 1,
    news_my_news_Data: news_my_news_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
    news_news_builder_Data: news_news_builder_Data.map((item) => ({
      ...item,
      checked: checkboxesData[item.Name] === 1,
    })),
  });

  const [accessRights, setAccessRights] = useState(initializeAccessRights());

  useEffect(() => {
    setAccessRights(initializeAccessRights());
  }, [checkboxesData]);

  const handleCheckboxChange = (group, index = null) => {
    setAccessRights((prevState) => {
      if (Array.isArray(prevState[group])) {
        let updatedGroup;
        if (index !== null) {
          updatedGroup = [...prevState[group]];
          updatedGroup[index].checked = !updatedGroup[index].checked;
        } else {
          const allChecked = prevState[group].every((item) => item.checked);
          updatedGroup = prevState[group].map((item) => ({
            ...item,
            checked: !allChecked,
          }));
        }

        const newState = { ...prevState, [group]: updatedGroup };
        onPermissionsUpdate(getPermissionsValues(newState));
        return newState;
      } else {
        const newState = { ...prevState, [group]: !prevState[group] };
        onPermissionsUpdate(getPermissionsValues(newState));
        return newState;
      }
    });
  };

  const getPermissionsValues = (state) => {
    const permissions = {};
    Object.entries(state).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          permissions[item.Name] = item.checked ? 1 : 0;
        });
      } else {
        permissions[key] = value ? 1 : 0;
      }
    });
    return permissions;
  };

  const renderAccessRightPanel = (title, groupKey, data = []) => (
    <ExpansionPanel className={styles.hrExpansionPanel}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        className={styles.hrExpansionPanelSummaryThird}
      >
        <Checkbox
          color="primary"
          checked={data.every((item) => item.checked)}
          indeterminate={
            data.some((item) => item.checked) &&
            !data.every((item) => item.checked)
          }
          onChange={() => handleCheckboxChange(groupKey)}
          className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
        />
        <Typography className={styles.hrPageTitle} variant="h6" color="primary">
          {title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={styles.hrExpansionPanelDetailsThird}>
        <Grid container spacing={2}>
          {data.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={item.Name}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={item.checked}
                    className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                    onChange={() => handleCheckboxChange(groupKey, index)}
                  />
                }
                label={item.Label}
              />
            </Grid>
          ))}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );

  return (
    <Grid item md={12} xs={12}>
      <ExpansionPanel className={styles.hrExpansionPanelMain}>
        <ExpansionPanelSummary
          className={styles.hrExpansionPanelSummary}
          expandIcon={<ExpandMoreIcon className={styles.hrHeadingIcon} />}
          aria-controls="news-content"
          id="news-header"
        >
          <Typography className={styles.hrHeading}>News</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={styles.hrExpansionPanelDetailsSecond}>
          <Grid>
            {renderAccessRightPanel(
              "My News",
              "news_my_news_Data",
              accessRights.news_my_news_Data
            )}
          </Grid>
          <Grid>
            {renderAccessRightPanel(
              "News Builder",
              "news_news_builder_Data",
              accessRights.news_news_builder_Data
            )}
          </Grid>
          <Grid item md={12} xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "3px",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Checkbox
                color="primary"
                checked={accessRights.view_news_report}
                onChange={() => handleCheckboxChange("view_news_report")}
                className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                style={{ marginLeft: "0.5rem" }}
              />
              <Typography
                className={styles.hrPageTitle}
                variant="h6"
                color="primary"
              >
                News Report
              </Typography>
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "3px",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Checkbox
                color="primary"
                checked={accessRights.news_dashboard_refresh_settings}
                onChange={() =>
                  handleCheckboxChange("news_dashboard_refresh_settings")
                }
                className={`${styles.hrCheckboxRoot} ${styles.hrFormControlLabelSecond}`}
                style={{ marginLeft: "0.5rem" }}
              />
              <Typography
                className={styles.hrPageTitle}
                variant="h6"
                color="primary"
              >
                News Settings
              </Typography>
            </div>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  );
};

export default NewsContent;
