import axios from "axios";
import { MY_TOKEN } from "../../../../config";

function fetchNewsUserReports(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/news/users`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function fetchNewsReports(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/news/news`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function fetchNewsRoleReports(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/news/roles`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function fetchNewsLocationReports(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/news/locations`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function newsUsersReportDownload(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/news/download/users`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function newsReportDownload(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/news/download/news`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function newsRolesReportDownload(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/news/download/roles`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function newsLocationsReportDownload(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/news/download/locations`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

export const analyticsNewsReportsServices = {
  fetchNewsReports,
  fetchNewsLocationReports,
  fetchNewsRoleReports,
  fetchNewsUserReports,
  newsReportDownload,
  newsLocationsReportDownload,
  newsRolesReportDownload,
  newsUsersReportDownload,
};
