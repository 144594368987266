import { Edit as EditIcon } from "@material-ui/icons";
import { Dropdown } from "../../../../utilities/v5/common";
import { ovHrV5Color } from "../../../../config";
export const themeColor = ovHrV5Color.main;

export const headCells = [
  {
    id: "user_name",
    label: "Name",
    numeric: false,
    align: "left",
    dropdown: "",
  },
  {
    id: "role_name",
    label: "Role Name",
    numeric: false,
    align: "left",
    dropdown: (
      <Dropdown
        size={{ width: "110px", height: "40px" }}
        bgColor="white"
        arrowColor={themeColor}
        textColor={"white"}
        borderColor={""}
        buttonText={""}
      />
    ),
  },
  {
    id: "user_location",
    label: "Locations",
    numeric: false,
    align: "left",
    align: "left",
    dropdown: (
      <Dropdown
        size={{ width: "110px", height: "40px" }}
        bgColor="white"
        arrowColor={themeColor}
        textColor={"white"}
        borderColor={""}
        buttonText={""}
      />
    ),
  },
  {
    id: "app_version",
    label: "App Version",
    numeric: false,
    align: "left",
    dropdown: "",
  },
  {
    id: "last_login",
    label: "Last Login",
    numeric: false,
    align: "left",
    dropdown: "",
  },
  {
    id: "user_status",
    label: "Status",
    numeric: false,
    align: "left",
    align: "left",
    dropdown: (
      <Dropdown
        size={{ width: "110px", height: "40px" }}
        bgColor="white"
        arrowColor={themeColor}
        textColor={"white"}
        borderColor={""}
        buttonText={""}
      />
    ),
  },
  {
    id: "actions",
    label: "Actions",
    numeric: false,
    align: "left",
    dropdown: "",
  },
];

export const hrUsersMoreMenuList = (permissionsData) => [
  {
    icon: EditIcon,
    title: "Edit User",
    sortName: "edit",
    permission: permissionsData.hr_edit_user,
  },
];

export const hrNewUserObjectValues = {
  user_name: "",
  role_name: "",
  user_email: "",
  dropdown: "",
  user_address1: "",
  user_mobile_no: "",
  user_role: 0,
  show_location_schedule: 1,
  exclude_location_alert: 1,
  exclude_timeclock_errors: 1,
  eligible_open_shifts: 1,
};
