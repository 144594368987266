import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { history } from "../../../../../../history";
import { useLocation } from "react-router-dom";
import { styles } from "../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../components/analytics/elearning/ReportTableWrapper";
import { newsUpdateServices } from "../../../../../News/NewsUpdate/modules/services";
import axios from "axios";
import { analyticsNewsReportsServices } from "../../../modules/services";
import { downloadCsvData } from "../../../../../../utils/csvDownloadUtils";

const NewsBasedLocationsReport = ({ classes, news_id, startDate, endDate }) => {
  const [locationsData, setLocationsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  const location = useLocation();

  useEffect(() => {
    setLocationsData([]);
    fetchLocationsData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchLocationsData = (sortBy = orderBy, sortOrder = order) => {
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      news: news_id,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    analyticsNewsReportsServices
      .fetchNewsLocationReports(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success) {
          setLocationsData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleDownloadClick = () => {
    const requestData = {
      page,
      limit: 10,
      news: news_id,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
      sort: order === "asc" ? orderBy : `-${orderBy}`,
    };

    const headers = [
      // { label: "Location ID", key: "_id" },
      { label: "Location Name", key: "name" },
      { label: "Users", key: "total_news" },
      {
        label: "Seen",
        key: checked ? "news_seen_percentage" : "news_seen/assign",
      },
      {
        label: "Acknowledged",
        key: checked ? "news_ack_percentage" : "news_ack/assign",
      },
    ];

    downloadCsvData({
      apiService: analyticsNewsReportsServices.newsLocationsReportDownload,
      requestData,
      headers,
      filename: "Location_Report.csv",
      setLoading,
    });
  };

  const handleSortRequest = (property) => {
    setLocationsData([]);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchLocationsData(property, isAsc ? "desc" : "asc");
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const handleRowClick = (row) => {
    const newQueryParams = new URLSearchParams(location.search);
    const news_name = newQueryParams.get("news_name");

    if (startDate) {
      newQueryParams.set("startDate", startDate);
    }
    if (endDate) {
      newQueryParams.set("endDate", endDate);
    }

    newQueryParams.set("news_name", news_name);
    newQueryParams.set("location_name", row.location_name);

    console.log("Analytics location id:", row);

    history.push({
      pathname: `/analytics/news/news/${news_id}/locations/${row._id}`,
      search: newQueryParams.toString(),
    });
  };

  const headCells = [
    { id: "name", label: "Location Name" },
    { id: "total_news", label: "Users" },
    {
      id: checked ? "news_seen_percentage" : "news_seen",
      label: "Seen",
    },
    { id: checked ? "news_ack_percentage" : "news_ack", label: "Acknowledged" },
  ];

  const data = locationsData?.map((row) => ({
    _id: row._id,
    location_name: row?.name,
    "Location Name":
      row?.name?.length > 30 ? row?.name.substring(0, 30) + "..." : row?.name,
    Users: row?.assign,
    Seen: checked
      ? `${row.news_seen_percentage}%`
      : `${row.news_seen} / ${row.assign}`,
    Acknowledged: checked
      ? `${row.news_ack_percentage}%`
      : `${row.news_ack} / ${row.assign}`,
    ...row,
  }));

  const arrowColumnCalculations = (row, columnId) => {
    let difference;

    switch (columnId) {
      case "Users":
        difference = row.assign_difference;
        break;
      case "Seen":
        difference = checked
          ? row.news_seen_percentage_difference
          : row.news_seen_difference;
        break;
      case "Acknowledged":
        difference = checked
          ? row.news_ack_percentage_difference
          : row.news_ack_difference;
        break;
      default:
        return null;
    }

    return difference !== 0 ? difference > 0 : null;
  };

  return (
    <>
      <ReportTableWrapper
        classes={classes}
        data={data}
        headers={headCells}
        loading={loading}
        page={page}
        totalPages={totalPages}
        onPageChange={setPage}
        onRowClick={handleRowClick}
        onSortRequest={handleSortRequest}
        order={order}
        orderBy={orderBy}
        handleCountPercentage={handleCountPercentage}
        checked={checked}
        arrowColumnCalculations={arrowColumnCalculations}
        handleDownloadClick={handleDownloadClick}
      />
    </>
  );
};

const ConnectWith = connect(null, null)(NewsBasedLocationsReport);

export default withStyles(styles)(ConnectWith);
