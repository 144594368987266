import { withStyles } from "@material-ui/core";
import { styles } from "../../pages/analytics/ReportStyles";
import { HiOutlineDownload } from "react-icons/hi";

const DownloadButton = ({ classes, onclick, disabled, loading }) => {
  return (
    <>
      <button
        className={classes.refreshBtn}
        onClick={onclick}
        disabled={disabled}
        loading={loading}
      >
        <HiOutlineDownload className={classes.downloadIcon} />
      </button>
    </>
  );
};

export default withStyles(styles)(DownloadButton);
