import axios from "axios";
import { MY_TOKEN } from "../../../../config";

function fetchAnalyticsAllUserReportsData(data, cancelToken) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/dashboard/all/users`;

  return axios
    .get(url, {
      headers: {
        Authorization: MY_TOKEN,
      },
      params: data,
      cancelToken,
    })
    .then((response) => response);
}

function fetchAnalyticsAllRoleReportsData(data, cancelToken) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/dashboard/all/roles`;

  return axios
    .get(url, {
      headers: {
        Authorization: MY_TOKEN,
      },
      params: data,
      cancelToken,
    })
    .then((response) => response);
}

function fetchAnalyticsAllLocationReportsData(data, cancelToken) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/dashboard/all/locations`;

  return axios
    .get(url, {
      headers: {
        Authorization: MY_TOKEN,
      },
      params: data,
      cancelToken,
    })
    .then((response) => response);
}

function downloadAnalyticsAllUserReportsData(data, cancelToken) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/dashboard/all/users/download`;

  return axios
    .get(url, {
      headers: {
        Authorization: MY_TOKEN,
      },
      params: data,
      cancelToken,
    })
    .then((response) => response);
}

function downloadAnalyticsAllRoleReportsData(data, cancelToken) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/dashboard/all/roles/download`;

  return axios
    .get(url, {
      headers: {
        Authorization: MY_TOKEN,
      },
      params: data,
      cancelToken,
    })
    .then((response) => response);
}

function downloadAnalyticsAllLocationReportsData(data, cancelToken) {
  let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/dashboard/all/locations/download`;

  return axios
    .get(url, {
      headers: {
        Authorization: MY_TOKEN,
      },
      params: data,
      cancelToken,
    })
    .then((response) => response);
}

export const analyticsReportsServices = {
  fetchAnalyticsAllUserReportsData,
  fetchAnalyticsAllLocationReportsData,
  fetchAnalyticsAllRoleReportsData,
  downloadAnalyticsAllUserReportsData,
  downloadAnalyticsAllRoleReportsData,
  downloadAnalyticsAllLocationReportsData,
};
