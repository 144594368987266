import axios from "axios";
import { MY_TOKEN } from "../../../../config";

function fetchElearningUserReports(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/elearning/users`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function fetchElearningLessonReports(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/elearning/lessons`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function fetchElearningRoleReports(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/elearning/roles`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function fetchElearningLocationReports(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/elearning/locations`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function elearningUsersReportDownload(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/elearning/download/users`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function elearningLessonsReportDownload(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/elearning/download/lessons`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function elearningRolesReportDownload(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/elearning/download/roles`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

function elearningLocationsReportDownload(data, cancelToken) {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ANALYTICS_API_VERSION}/${process.env.REACT_APP_PLATFORM}/analytics/elearning/download/locations`,
      {
        headers: { Authorization: MY_TOKEN },
        params: data,
        cancelToken,
      }
    )
    .then((response) => response);
}

export const analyticsElearningReportsServices = {
  fetchElearningLessonReports,
  fetchElearningLocationReports,
  fetchElearningRoleReports,
  fetchElearningUserReports,
  elearningLessonsReportDownload,
  elearningLocationsReportDownload,
  elearningRolesReportDownload,
  elearningUsersReportDownload,
};
