import React, { useState, useEffect, useRef } from "react";
import { withStyles, Tooltip, Typography } from "@material-ui/core";
import { dashboardServices } from "../../../../../eLearning/Dashboard/modules/services";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { history } from "../../../../../../history";
import { styles } from "../../../../ReportStyles";
import ReportTableWrapper from "../../../../../../components/analytics/elearning/ReportTableWrapper";
import axios from "axios";
import { downloadCsvData } from "../../../../../../utils/csvDownloadUtils";
import { analyticsElearningReportsServices } from "../../../modules/services";

const LocationBasedUsersReport = ({ classes, lid, startDate, endDate }) => {
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("user_name");

  const [checked, setChecked] = useState(true);

  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const cancelPreviousRequest = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }
    cancelTokenSourceRef.current = axios.CancelToken.source();
  };

  const location = useLocation();

  useEffect(() => {
    fetchUsersData(orderBy, order);
  }, [page, startDate, endDate]);

  const fetchUsersData = (sortBy = orderBy, sortOrder = order) => {
    setUsersData([]);
    setLoading(true);
    cancelPreviousRequest();
    const data = {
      page,
      limit: 10,
      lid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
    };

    const sortParam = sortOrder === "asc" ? sortBy : `-${sortBy}`;
    data["sort"] = sortParam;

    analyticsElearningReportsServices
      .fetchElearningUserReports(data, cancelTokenSourceRef.current.token)
      .then((response) => {
        if (response.data.success) {
          setUsersData(response.data.result);
          setPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        }
        setLoading(false);
      });
  };

  const handleDownloadClick = () => {
    const requestData = {
      page,
      limit: 10,
      lid,
      ...(startDate && endDate
        ? { startDate: startDate, endDate: endDate }
        : {}),
      sort: order === "asc" ? orderBy : `-${orderBy}`,
    };

    const headers = [
      // { label: "User ID", key: "_id" },
      { label: "User Name", key: "user_name" },
      { label: "Role", key: "user_role" },
      {
        label: "In Lesson Quiz",
        key: checked
          ? "inLessonQuizPer"
          : "completed_question_count/question_count",
      },
      {
        label: "Manager Quiz",
        key: checked
          ? "managerQuizPer"
          : "completed_manager_quiz_count/total_manager_quiz_count",
      },
      {
        label: "Video Quiz",
        key: checked
          ? "videoQuizPer"
          : "completed_video_quiz_count/total_video_quiz_count",
      },
      {
        label: "Lessons",
        key: checked
          ? "lessonPer"
          : "user_lessons_completed/user_lessons_assigned",
      },
    ];

    downloadCsvData({
      apiService:
        analyticsElearningReportsServices.elearningUsersReportDownload,
      requestData,
      headers,
      filename: "User_Report.csv",
      setLoading,
    });
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchUsersData(property, isAsc ? "desc" : "asc");
  };

  const handleCountPercentage = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const handleRowClick = (row) => {
    const newQueryParams = new URLSearchParams(location.search);
    const location_name = newQueryParams.get("location_name");

    if (startDate) {
      newQueryParams.set("startDate", startDate);
    }
    if (endDate) {
      newQueryParams.set("endDate", endDate);
    }

    newQueryParams.set("location_name", location_name);
    newQueryParams.set("user_name", row.user_name);

    console.log("Analytics location id:", row);

    history.push({
      pathname: `/analytics/elearning/location/${lid}/users/${row._id}`,
      search: newQueryParams.toString(),
    });
  };

  const headers = [
    { id: "user_name", label: "User Name" },
    { id: "user_role", label: "Role name" },
    {
      id: checked ? "inLessonQuizPer" : "completed_question_count",
      label: "In Lesson Quiz",
    },
    {
      id: checked ? "managerQuizPer" : "completed_manager_quiz_count",
      label: "Manager Quiz",
    },
    {
      id: checked ? "videoQuizPer" : "completed_video_quiz_count",
      label: "Video Quiz",
    },
    { id: checked ? "lessonPer" : "user_lessons_completed", label: "Lessons" },
  ];

  const rows = usersData?.length
    ? usersData.map((row) => ({
        _id: row._id,
        user_name: row.user_name,
        "User Name": (
          <Tooltip
            arrow
            title={
              <div className={classes.tooltipContainer}>
                <Typography className={classes.tooltipTextStyle}>
                  {row?.contact}
                </Typography>
                <Typography className={classes.tooltipTextStyle}>
                  {row?.email}
                </Typography>
              </div>
            }
          >
            <Typography>{row.user_name}</Typography>
          </Tooltip>
        ),
        "Role name": row.user_role,
        "In Lesson Quiz": checked
          ? `${row?.inLessonQuizPer}%`
          : `${row.completed_question_count} / ${row.question_count}`,
        "Manager Quiz": checked
          ? `${row?.managerQuizPer}%`
          : `${row.completed_manager_quiz_count} / ${row.total_manager_quiz_count}`,
        "Video Quiz": checked
          ? `${row?.videoQuizPer}%`
          : `${row.completed_video_quiz_count} / ${row.total_video_quiz_count}`,
        Lessons: checked
          ? `${row?.lessonPer}%`
          : `${row.user_lessons_completed} / ${row.user_lessons_assigned}`,
        ...row,
      }))
    : [];

  const arrowColumnCalculations = (row, columnId) => {
    let difference;

    switch (columnId) {
      case "In Lesson Quiz":
        difference = checked
          ? row.inLessonQuizPer_difference
          : row.completed_question_count_difference;
        break;
      case "Manager Quiz":
        difference = checked
          ? row.managerQuizPer_difference
          : row.completed_manager_quiz_count_difference;
        break;
      case "Video Quiz":
        difference = checked
          ? row.videoQuizPer_difference
          : row.completed_video_quiz_count_difference;
        break;
      case "Lessons":
        difference = checked
          ? row.lessonPer_difference
          : row.user_lessons_completed_difference;
        break;
      default:
        return null;
    }

    return difference !== 0 ? difference > 0 : null;
  };

  return (
    <>
      <ReportTableWrapper
        classes={classes}
        data={rows}
        headers={headers}
        loading={loading}
        page={page}
        totalPages={totalPages}
        onPageChange={setPage}
        onRowClick={handleRowClick}
        onSortRequest={handleSortRequest}
        order={order}
        orderBy={orderBy}
        handleCountPercentage={handleCountPercentage}
        checked={checked}
        arrowColumnCalculations={arrowColumnCalculations}
        handleDownloadClick={handleDownloadClick}
      />
    </>
  );
};

const ConnectWith = connect(null, null)(LocationBasedUsersReport);

export default withStyles(styles)(ConnectWith);
